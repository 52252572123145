import React from 'react';

import { Store } from './store/Store';
import { ThemeProvider } from './ThemeProvider';

export {
  setDirection,
  setFixedNavbar,
  setMiniSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setTransparentSidenav,
} from './store/themeSlice';

export function ThemeRootComponent({ children }: React.PropsWithChildren<any>) {
  return <Store>{() => <ThemeProvider defaultTheme="dark">{children}</ThemeProvider>}</Store>;
}
