import styled from '@emotion/styled';

type Props = {
  fixed: boolean;
  absolute: boolean;
  height: number | string;
  fixedCss?: string;
  loaderCss?: string;
};

export const Wrapper = styled.div<Props>`
  height: ${(p) => p.height};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: ${(p) => (p.fixed ? 'fixed' : undefined)};
  position: ${(p) => (p.absolute ? 'absolute' : undefined)};
  ${({ fixedCss }) => fixedCss};
  ${({ loaderCss }) => loaderCss};
`;
