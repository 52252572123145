import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';

import { NODE_VARS } from '@mdca/config';

export function AuthzProviderWithConfig({ children }: React.PropsWithChildren<any>) {
  const navigate = useNavigate();
  const domain = NODE_VARS.AUTH0_DOMAIN;
  const clientId = NODE_VARS.AUTH0_CLIENT_ID;
  const redirectUri = NODE_VARS.AUTH0_CALLBACK_URL;
  const audience = NODE_VARS.AUTH0_AUDIENCE;

  if (!domain || !clientId) {
    throw new Error('Please set AUTH0_DOMAIN and CLIENT_ID env. variables');
  }

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
    navigate(appState?.returnTo || redirectUri);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
