import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function LoadingBackdrop({ loading }: { loading: boolean }) {
  return (
    <Backdrop
      unmountOnExit
      open={loading}
      transitionDuration={800}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
