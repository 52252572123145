import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { Maybe, PromptInfoExtendsFragment } from '@app/graphql/generated';

import { Dialog, MdScrollbar, MdTypography } from '../../look';

import { MdTableRow } from './MdTableRow';
import { RenderPromptField } from './RenderPromptField';

type Props = { prompt: PromptInfoExtendsFragment };

export function PromptRow({ prompt }: Props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('prompt');
  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const toggleOpen = React.useCallback(() => setOpen((prev) => !prev), []);

  const handleCopy = React.useCallback(
    (text: Maybe<string>) => () => {
      clipboard.copy(text || '');
      enqueueSnackbar('Copying to clipboard was successful!', { variant: 'success' });
    },
    [clipboard, enqueueSnackbar],
  );

  return (
    <MdTableRow hover isZebra>
      <TableCell onClick={toggleOpen}>
        <MdTypography>
          {prompt.prompt.slice(0, 50)}
          {prompt.prompt.length > 50 && '...'}
        </MdTypography>
      </TableCell>
      <TableCell onClick={toggleOpen}>
        {prompt.promptResponse && (
          <MdTypography>
            {prompt.promptResponse.slice(0, 50)}
            {prompt.promptResponse.length > 50 && '...'}
          </MdTypography>
        )}
        {prompt.hasError && <MdTypography sx={{ color: '#f53c2b' }}>{prompt.error}</MdTypography>}
      </TableCell>
      <RenderPromptField value={prompt.stop.split(',').join(', ')} />
      <RenderPromptField value={prompt.temperature} />
      <RenderPromptField value={prompt.maxTokens} />
      <RenderPromptField value={prompt.topP} />
      <RenderPromptField value={prompt.frequencyPenalty} />
      <RenderPromptField value={prompt.presencePenalty} />
      <RenderPromptField value={prompt.bestOf} />
      <RenderPromptField value={prompt.tokens || 0} />
      <RenderPromptField value={prompt.createdAt ? format(new Date(prompt.createdAt), 'Pp') : ''} />
      <MdScrollbar>
        <Dialog
          open={open}
          title="Prompt and Response"
          onClose={toggleOpen}
          actions={
            <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%', padding: 2 }}>
              <Button onClick={handleCopy(prompt.prompt)}>Copy Prompt</Button>
              <Button onClick={handleCopy(prompt.promptResponse)}>Copy Response</Button>
            </Stack>
          }
        >
          <Stack direction="row" spacing={3}>
            <TextField fullWidth disabled multiline label={t('playground.title')} value={prompt.prompt} />
            <TextField
              fullWidth
              disabled
              multiline
              label={t('playground.promptResponse')}
              value={prompt.promptResponse}
            />
          </Stack>
        </Dialog>
      </MdScrollbar>
    </MdTableRow>
  );
}
