import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { CreateOnePromptPresetMutation, Maybe, PromptPreset } from '@app/graphql/generated';

import { Dialog } from '../../look';
import { FormPreset } from '../types';

type Props = {
  presets: Array<PromptPreset>;
  handlePresetSave: (preset: FormPreset) => Promise<Maybe<CreateOnePromptPresetMutation>>;
  handlePresetUpdate: (name: string, description: string) => Promise<boolean>;
  loading: boolean;
  currentPreset: PromptPreset | null;
};

const initPreset: FormPreset = { name: '', description: '' };

export function SavePresetDialog(props: Props) {
  const { presets, handlePresetSave, loading, currentPreset, handlePresetUpdate } = props;
  const { t } = useTranslation('prompt');
  const [open, setOpen] = React.useState(false);
  const [newPreset, setNewPreset] = React.useState<FormPreset>({
    name: currentPreset?.name ?? '',
    description: currentPreset?.description ?? '',
  });
  const [nameExist, setNameExist] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (currentPreset) {
      setNewPreset({
        name: currentPreset.name,
        description: currentPreset.description ?? '',
      });
    } else {
      setNewPreset(initPreset);
    }
  }, [currentPreset]);

  const handleClickOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setNameExist(null);
  }, []);

  const handleChange = React.useCallback(
    (field: keyof FormPreset) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPreset((prev) => ({ ...prev, [field]: event.target.value }));
    },
    [],
  );

  const handleSubmit = () => {
    if (newPreset.name !== '') {
      const presetExist = presets.find((preset) => preset.name === newPreset.name);
      if (presetExist) {
        setNameExist(t('playground.saveDialog.nameExist'));
      } else {
        handlePresetSave({ name: newPreset.name, description: newPreset.description })
          .then(() => handleClose())
          .catch(() => handleClose());
      }
    } else {
      setNameExist(t('playground.saveDialog.nameEmpty'));
    }
  };
  const handleUpdate = () => {
    handlePresetUpdate(newPreset.name, newPreset?.description ?? '')
      .then(() => handleClose())
      .catch(() => handleClose());
  };

  return (
    <Box>
      <Button onClick={handleClickOpen}>{t('playground.save')}</Button>
      <Dialog
        loading={loading}
        title={currentPreset ? t('playground.saveDialog.saveOrUpdate') : t('playground.saveDialog.title')}
        actions={
          <>
            <Button disabled={loading || newPreset.name === ''} onClick={handleSubmit}>
              {currentPreset ? t('playground.saveAsNew') : t('playground.save')}
            </Button>
            {currentPreset && (
              <Button disabled={loading} onClick={handleUpdate}>
                {t('playground.update')}
              </Button>
            )}
          </>
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Stack spacing={3}>
          <TextField
            id="name"
            name="name"
            fullWidth
            required
            label={t('playground.saveDialog.name')}
            value={newPreset.name}
            onChange={handleChange('name')}
            helperText={nameExist}
            error={!!nameExist}
            inputProps={{ maxLength: '50' }}
            onMouseEnter={() => setNameExist(null)}
          />
          <TextField
            id="description"
            name="description"
            fullWidth
            label={t('playground.saveDialog.description')}
            value={newPreset.description}
            onChange={handleChange('description')}
            multiline
            inputProps={{ maxLength: '500', sx: { minHeight: '70px' } }}
          />
        </Stack>
      </Dialog>
    </Box>
  );
}
