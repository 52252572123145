import { initReactI18next } from 'react-i18next';
import i18next, { InitOptions } from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';

import { i18n } from '@mdca/config';

export default i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: i18n.fallbackLng,
    resources: {},
    debug: process.env.IS_DEV,
    whitelist: i18n.langList,
    preload: i18n.langList,
    detection: {
      lookupCookie: i18n.cookie,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      // bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  } as InitOptions & { whitelist: string[]; detection: DetectorOptions })
  .catch(console.error);
