import React from 'react';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { MdTypography } from '../../look';

export function Label({ label, description }: { label: string; description: string }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <MdTypography id="input-slider" component="span">
        {label}
      </MdTypography>
      <Tooltip
        title={
          <MdTypography variant="h6" component="span">
            {description}
          </MdTypography>
        }
        arrow
        placement="top"
      >
        <HelpOutlineRoundedIcon color="info" />
      </Tooltip>
    </Stack>
  );
}
