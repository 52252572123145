// import url from 'url';

import { NODE_VARS } from '@mdca/config';

import { PLATFORM } from './utils';

let clientURL = '';
let serverURL = '';

if (PLATFORM === 'web') {
  const port = process.env.IS_DEV ? `:${NODE_VARS.SERVER_PORT}` : '';
  clientURL = `${window.location.protocol}//${window.location.hostname}${port}${process.env.API_URL}`;
}

if (PLATFORM === 'server') {
  serverURL = `http://localhost:${NODE_VARS.SERVER_PORT}${process.env.API_URL}`;
}
export const apiUrl = PLATFORM === 'server' ? serverURL : clientURL;
