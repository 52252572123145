import { useCallback, useEffect, useState } from 'react';
import { Accept, DropEvent, FileRejection } from 'react-dropzone';
import accepts from 'attr-accept';
import { isEmpty } from 'lodash';

import { FileReaderMethods } from '../file-reader-method.enum';

const fileAccepted = (file: File, accept: string | string[]) =>
  file.type === 'application/x-moz-file' || accepts(file, accept);
const matchMaxSizeSize = (file: File, maxSize: number) => file.size <= maxSize;
const matchMinSizeSize = (file: File, minSize: number) => file.size >= minSize;
const noop = () => {};

export type FileRenderError = {
  name: string;
  message: string;
  file: File | File[] | null;
};

export type FileReaderResult = {
  result: string | ArrayBuffer | null;
  file: File | null;
  error: Error | FileRenderError | null;
  loading: boolean;
};

export type FileReaderTuple = [
  FileReaderResult,
  (acceptedFiles: File | File[]) => void,
  (fileRejections: FileRejection[], event: DropEvent) => void,
  () => void,
];

type Props = {
  method: FileReaderMethods;
  accept: Accept;
  minSize: number;
  maxSize: number;
  errors: { defaultError: string; fileFormat: string; maxSize: string; minSize: string };
  onload?: (args?: any) => Record<string, any>;
};

export const useFileReader = (options: Props): FileReaderTuple => {
  const {
    method = FileReaderMethods.readAsText,
    accept,
    minSize,
    maxSize,
    errors,
    onload: onloadHook = noop,
  } = options;

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<Error | FileRenderError | null>(null);
  const [result, setResult] = useState<string | ArrayBuffer | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('loadstart', () => {
        setLoading(true);
      });
      reader.onloadend = () => {
        setLoading(false);
      };
      reader.addEventListener('load', (e) => {
        if (e.target) {
          setResult(e.target.result);
          onloadHook(e.target.result);
        }
      });
      reader.onerror = () => {
        setError({
          name: 'defaultError',
          message: errors.defaultError,
          file: null,
        });
      };
      try {
        switch (method) {
          case FileReaderMethods.readAsText:
          case FileReaderMethods.readAsArrayBuffer:
          case FileReaderMethods.readAsBinaryString:
          case FileReaderMethods.readAsDataURL:
            await reader[method](file);

            break;
          default:
            throw new Error('Unknown method');
        }
      } catch (error_) {
        setError(error_ as Error);
      }
    })();
  }, [errors.defaultError, file, method, onloadHook]);

  const fileSetter = useCallback(
    (acceptedFiles: File | File[]) => {
      if (isEmpty(acceptedFiles)) {
        setError({
          name: 'defaultError',
          message: errors.defaultError,
          file: null,
        });
      }

      const uploadFile = Array.isArray(acceptedFiles) ? acceptedFiles[0] : acceptedFiles;

      if (!matchMaxSizeSize(uploadFile, maxSize)) {
        setError({ name: 'maxSize', message: errors.maxSize, file: uploadFile });
      } else if (!matchMinSizeSize(uploadFile, minSize)) {
        setError({ name: 'minSize', message: errors.minSize, file: uploadFile });
      } else if (!fileAccepted(uploadFile, Object.keys(accept))) {
        setError({ name: 'fileFormat', message: errors.fileFormat, file: uploadFile });
      } else {
        setFile(uploadFile);
      }
    },
    [accept, errors.defaultError, errors.fileFormat, errors.maxSize, errors.minSize, maxSize, minSize],
  );

  const fileReject = useCallback(
    (rejectedFiles: any) => {
      if (!isEmpty(rejectedFiles)) {
        setError({
          name: 'defaultError',
          message: errors.defaultError,
          file: Array.isArray(rejectedFiles) ? rejectedFiles[0] : rejectedFiles,
        });
      }
    },
    [errors.defaultError],
  );

  const reset = useCallback(() => {
    setFile(null);
    setResult(null);
    setError(null);
    setLoading(false);
  }, []);

  return [{ result, error, file, loading }, fileSetter, fileReject, reset];
};
