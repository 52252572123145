import { PromptCreateInput } from '@app/graphql/generated';

import { FormPrompt } from '../types';

export function promptToCreateMapper(prompt: FormPrompt): Omit<PromptCreateInput, 'project'> {
  return {
    bestOf: prompt.bestOf,
    frequencyPenalty: prompt.frequencyPenalty,
    maxTokens: prompt.maxTokens,
    presencePenalty: prompt.presencePenalty,
    // Removes /n from the end of prompt
    prompt: prompt.prompt.slice(0, -1),
    // TODO temp fix
    stop: prompt.stop.join(','),
    temperature: prompt.temperature,
    topP: prompt.topP,
    source: prompt.source,
    variables: prompt.variables,
  };
}
