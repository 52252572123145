import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateProfile = {
  __typename?: 'AggregateProfile';
  _count?: Maybe<ProfileCountAggregate>;
  _max?: Maybe<ProfileMaxAggregate>;
  _min?: Maybe<ProfileMinAggregate>;
};

export type AggregateProject = {
  __typename?: 'AggregateProject';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
};

export type AggregatePrompt = {
  __typename?: 'AggregatePrompt';
  _avg?: Maybe<PromptAvgAggregate>;
  _count?: Maybe<PromptCountAggregate>;
  _max?: Maybe<PromptMaxAggregate>;
  _min?: Maybe<PromptMinAggregate>;
  _sum?: Maybe<PromptSumAggregate>;
};

export type AggregatePromptPreset = {
  __typename?: 'AggregatePromptPreset';
  _count?: Maybe<PromptPresetCountAggregate>;
  _max?: Maybe<PromptPresetMaxAggregate>;
  _min?: Maybe<PromptPresetMinAggregate>;
};

export type AggregateTokens = {
  __typename?: 'AggregateTokens';
  _avg?: Maybe<TokensAvgAggregate>;
  _count?: Maybe<TokensCountAggregate>;
  _max?: Maybe<TokensMaxAggregate>;
  _min?: Maybe<TokensMinAggregate>;
  _sum?: Maybe<TokensSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumRequestSourceNullableFilter = {
  equals?: InputMaybe<RequestSource>;
  in?: InputMaybe<Array<RequestSource>>;
  not?: InputMaybe<NestedEnumRequestSourceNullableFilter>;
  notIn?: InputMaybe<Array<RequestSource>>;
};

export type EnumRequestSourceNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumRequestSourceNullableFilter>;
  _min?: InputMaybe<NestedEnumRequestSourceNullableFilter>;
  equals?: InputMaybe<RequestSource>;
  in?: InputMaybe<Array<RequestSource>>;
  not?: InputMaybe<NestedEnumRequestSourceNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RequestSource>>;
};

export type EnumUserRoleNullableListFilter = {
  equals?: InputMaybe<Array<UserRole>>;
  has?: InputMaybe<UserRole>;
  hasEvery?: InputMaybe<Array<UserRole>>;
  hasSome?: InputMaybe<Array<UserRole>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createManyProfile: AffectedRowsOutput;
  createManyProject: AffectedRowsOutput;
  createManyPrompt: AffectedRowsOutput;
  createManyPromptPreset: AffectedRowsOutput;
  createManyTokens: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createOneProfile: Profile;
  createOneProject: Project;
  createOnePrompt: Prompt;
  createOnePromptPreset: PromptPreset;
  createOneTokens: Tokens;
  createOneUser: User;
  deleteManyProfile: AffectedRowsOutput;
  deleteManyProject: AffectedRowsOutput;
  deleteManyPrompt: AffectedRowsOutput;
  deleteManyPromptPreset: AffectedRowsOutput;
  deleteManyTokens: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteOneProfile?: Maybe<Profile>;
  deleteOneProject?: Maybe<Project>;
  deleteOnePrompt?: Maybe<Prompt>;
  deleteOnePromptPreset?: Maybe<PromptPreset>;
  deleteOneTokens?: Maybe<Tokens>;
  deleteOneUser?: Maybe<User>;
  predict: Array<Scalars['String']>;
  predictChat: Scalars['String'];
  predictCode: Array<Scalars['String']>;
  updateManyProfile: AffectedRowsOutput;
  updateManyProject: AffectedRowsOutput;
  updateManyPrompt: AffectedRowsOutput;
  updateManyPromptPreset: AffectedRowsOutput;
  updateManyTokens: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateOneProfile?: Maybe<Profile>;
  updateOneProject?: Maybe<Project>;
  updateOnePrompt?: Maybe<Prompt>;
  updateOnePromptPreset?: Maybe<PromptPreset>;
  updateOneTokens?: Maybe<Tokens>;
  updateOneUser?: Maybe<User>;
  upsertOneProfile: Profile;
  upsertOneProject: Project;
  upsertOnePrompt: Prompt;
  upsertOnePromptPreset: PromptPreset;
  upsertOneTokens: Tokens;
  upsertOneUser: User;
};


export type MutationCreateManyProfileArgs = {
  data: Array<ProfileCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProjectArgs = {
  data: Array<ProjectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPromptArgs = {
  data: Array<PromptCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPromptPresetArgs = {
  data: Array<PromptPresetCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTokensArgs = {
  data: Array<TokensCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOneProfileArgs = {
  data: ProfileCreateInput;
};


export type MutationCreateOneProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateOnePromptArgs = {
  data: PromptCreateInput;
};


export type MutationCreateOnePromptPresetArgs = {
  data: PromptPresetCreateInput;
};


export type MutationCreateOneTokensArgs = {
  data: TokensCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteManyProfileArgs = {
  where?: InputMaybe<ProfileWhereInput>;
};


export type MutationDeleteManyProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationDeleteManyPromptArgs = {
  where?: InputMaybe<PromptWhereInput>;
};


export type MutationDeleteManyPromptPresetArgs = {
  where?: InputMaybe<PromptPresetWhereInput>;
};


export type MutationDeleteManyTokensArgs = {
  where?: InputMaybe<TokensWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteOneProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type MutationDeleteOneProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationDeleteOnePromptArgs = {
  where: PromptWhereUniqueInput;
};


export type MutationDeleteOnePromptPresetArgs = {
  where: PromptPresetWhereUniqueInput;
};


export type MutationDeleteOneTokensArgs = {
  where: TokensWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationPredictArgs = {
  data: PromptCreateInput;
};


export type MutationPredictChatArgs = {
  question: Scalars['String'];
};


export type MutationPredictCodeArgs = {
  data: PromptCreateInput;
};


export type MutationUpdateManyProfileArgs = {
  data: ProfileUpdateManyMutationInput;
  where?: InputMaybe<ProfileWhereInput>;
};


export type MutationUpdateManyProjectArgs = {
  data: ProjectUpdateManyMutationInput;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationUpdateManyPromptArgs = {
  data: PromptUpdateManyMutationInput;
  where?: InputMaybe<PromptWhereInput>;
};


export type MutationUpdateManyPromptPresetArgs = {
  data: PromptPresetUpdateManyMutationInput;
  where?: InputMaybe<PromptPresetWhereInput>;
};


export type MutationUpdateManyTokensArgs = {
  data: TokensUpdateManyMutationInput;
  where?: InputMaybe<TokensWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateOneProfileArgs = {
  data: ProfileUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpdateOneProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOnePromptArgs = {
  data: PromptUpdateInput;
  where: PromptWhereUniqueInput;
};


export type MutationUpdateOnePromptPresetArgs = {
  data: PromptPresetUpdateInput;
  where: PromptPresetWhereUniqueInput;
};


export type MutationUpdateOneTokensArgs = {
  data: TokensUpdateInput;
  where: TokensWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneProfileArgs = {
  create: ProfileCreateInput;
  update: ProfileUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpsertOneProjectArgs = {
  create: ProjectCreateInput;
  update: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpsertOnePromptArgs = {
  create: PromptCreateInput;
  update: PromptUpdateInput;
  where: PromptWhereUniqueInput;
};


export type MutationUpsertOnePromptPresetArgs = {
  create: PromptPresetCreateInput;
  update: PromptPresetUpdateInput;
  where: PromptPresetWhereUniqueInput;
};


export type MutationUpsertOneTokensArgs = {
  create: TokensCreateInput;
  update: TokensUpdateInput;
  where: TokensWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumRequestSourceNullableFilter = {
  equals?: InputMaybe<RequestSource>;
  in?: InputMaybe<Array<RequestSource>>;
  not?: InputMaybe<NestedEnumRequestSourceNullableFilter>;
  notIn?: InputMaybe<Array<RequestSource>>;
};

export type NestedEnumRequestSourceNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumRequestSourceNullableFilter>;
  _min?: InputMaybe<NestedEnumRequestSourceNullableFilter>;
  equals?: InputMaybe<RequestSource>;
  in?: InputMaybe<Array<RequestSource>>;
  not?: InputMaybe<NestedEnumRequestSourceNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RequestSource>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableEnumRequestSourceFieldUpdateOperationsInput = {
  set?: InputMaybe<RequestSource>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type ProfileCountAggregate = {
  __typename?: 'ProfileCountAggregate';
  _all: Scalars['Int'];
  companyName: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  lastName: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProfileCountOrderByAggregateInput = {
  companyName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileCreateInput = {
  companyName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateManyInput = {
  companyName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProfileCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ProfileCreateWithoutUserInput>;
};

export type ProfileCreateOrConnectWithoutUserInput = {
  create: ProfileCreateWithoutUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateWithoutUserInput = {
  companyName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProfileGroupBy = {
  __typename?: 'ProfileGroupBy';
  _count?: Maybe<ProfileCountAggregate>;
  _max?: Maybe<ProfileMaxAggregate>;
  _min?: Maybe<ProfileMinAggregate>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ProfileMaxAggregate = {
  __typename?: 'ProfileMaxAggregate';
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfileMaxOrderByAggregateInput = {
  companyName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileMinAggregate = {
  __typename?: 'ProfileMinAggregate';
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfileMinOrderByAggregateInput = {
  companyName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileOrderByWithAggregationInput = {
  _count?: InputMaybe<ProfileCountOrderByAggregateInput>;
  _max?: InputMaybe<ProfileMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProfileMinOrderByAggregateInput>;
  companyName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileOrderByWithRelationInput = {
  companyName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
};

export type ProfileRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export enum ProfileScalarFieldEnum {
  CompanyName = 'companyName',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  UpdatedAt = 'updatedAt'
}

export type ProfileScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProfileScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProfileScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProfileScalarWhereWithAggregatesInput>>;
  companyName?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  firstName?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastName?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ProfileUpdateInput = {
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutProfileNestedInput>;
};

export type ProfileUpdateManyMutationInput = {
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProfileUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ProfileCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProfileUpdateWithoutUserInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutUserInput>;
};

export type ProfileUpdateWithoutUserInput = {
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProfileUpsertWithoutUserInput = {
  create: ProfileCreateWithoutUserInput;
  update: ProfileUpdateWithoutUserInput;
};

export type ProfileWhereInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  companyName?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
};

export type ProfileWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  Tokens: Array<Tokens>;
  _count?: Maybe<ProjectCount>;
  apiKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  prompts: Array<Prompt>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type ProjectTokensArgs = {
  cursor?: InputMaybe<TokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<TokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TokensOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TokensWhereInput>;
};


export type ProjectPromptsArgs = {
  cursor?: InputMaybe<PromptWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptWhereInput>;
};

export type ProjectCount = {
  __typename?: 'ProjectCount';
  Tokens: Scalars['Int'];
  prompts: Scalars['Int'];
};

export type ProjectCountAggregate = {
  __typename?: 'ProjectCountAggregate';
  _all: Scalars['Int'];
  apiKey: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ProjectCountOrderByAggregateInput = {
  apiKey?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProjectCreateInput = {
  Tokens?: InputMaybe<TokensCreateNestedManyWithoutProjectInput>;
  apiKey: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutProjectInput>;
};

export type ProjectCreateManyInput = {
  apiKey: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ProjectCreateManyUserInput = {
  apiKey: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateManyUserInputEnvelope = {
  data: Array<ProjectCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutUserInput>>;
  createMany?: InputMaybe<ProjectCreateManyUserInputEnvelope>;
};

export type ProjectCreateNestedOneWithoutPromptsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutPromptsInput>;
  create?: InputMaybe<ProjectCreateWithoutPromptsInput>;
};

export type ProjectCreateNestedOneWithoutTokensInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutTokensInput>;
  create?: InputMaybe<ProjectCreateWithoutTokensInput>;
};

export type ProjectCreateOrConnectWithoutPromptsInput = {
  create: ProjectCreateWithoutPromptsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutTokensInput = {
  create: ProjectCreateWithoutTokensInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutUserInput = {
  create: ProjectCreateWithoutUserInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateWithoutPromptsInput = {
  Tokens?: InputMaybe<TokensCreateNestedManyWithoutProjectInput>;
  apiKey: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutProjectInput>;
};

export type ProjectCreateWithoutTokensInput = {
  apiKey: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutProjectInput>;
};

export type ProjectCreateWithoutUserInput = {
  Tokens?: InputMaybe<TokensCreateNestedManyWithoutProjectInput>;
  apiKey: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectGroupBy = {
  __typename?: 'ProjectGroupBy';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
  apiKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type ProjectListRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectMaxAggregate = {
  __typename?: 'ProjectMaxAggregate';
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ProjectMaxOrderByAggregateInput = {
  apiKey?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProjectMinAggregate = {
  __typename?: 'ProjectMinAggregate';
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ProjectMinOrderByAggregateInput = {
  apiKey?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithAggregationInput = {
  _count?: InputMaybe<ProjectCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectMinOrderByAggregateInput>;
  apiKey?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithRelationInput = {
  Tokens?: InputMaybe<TokensOrderByRelationAggregateInput>;
  apiKey?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  prompts?: InputMaybe<PromptOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ProjectRelationFilter = {
  is?: InputMaybe<ProjectWhereInput>;
  isNot?: InputMaybe<ProjectWhereInput>;
};

export enum ProjectScalarFieldEnum {
  ApiKey = 'apiKey',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  IsActive = 'isActive',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ProjectScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereInput>>;
  apiKey?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ProjectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  apiKey?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isActive?: InputMaybe<BoolWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ProjectUpdateInput = {
  Tokens?: InputMaybe<TokensUpdateManyWithoutProjectNestedInput>;
  apiKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prompts?: InputMaybe<PromptUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutProjectNestedInput>;
};

export type ProjectUpdateManyMutationInput = {
  apiKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyWithWhereWithoutUserInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutUserInput>>;
  createMany?: InputMaybe<ProjectCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ProjectUpdateOneRequiredWithoutPromptsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutPromptsInput>;
  create?: InputMaybe<ProjectCreateWithoutPromptsInput>;
  update?: InputMaybe<ProjectUpdateWithoutPromptsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutPromptsInput>;
};

export type ProjectUpdateOneRequiredWithoutTokensNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutTokensInput>;
  create?: InputMaybe<ProjectCreateWithoutTokensInput>;
  update?: InputMaybe<ProjectUpdateWithoutTokensInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutTokensInput>;
};

export type ProjectUpdateWithWhereUniqueWithoutUserInput = {
  data: ProjectUpdateWithoutUserInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithoutPromptsInput = {
  Tokens?: InputMaybe<TokensUpdateManyWithoutProjectNestedInput>;
  apiKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutProjectNestedInput>;
};

export type ProjectUpdateWithoutTokensInput = {
  apiKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prompts?: InputMaybe<PromptUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutProjectNestedInput>;
};

export type ProjectUpdateWithoutUserInput = {
  Tokens?: InputMaybe<TokensUpdateManyWithoutProjectNestedInput>;
  apiKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prompts?: InputMaybe<PromptUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpsertWithWhereUniqueWithoutUserInput = {
  create: ProjectCreateWithoutUserInput;
  update: ProjectUpdateWithoutUserInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithoutPromptsInput = {
  create: ProjectCreateWithoutPromptsInput;
  update: ProjectUpdateWithoutPromptsInput;
};

export type ProjectUpsertWithoutTokensInput = {
  create: ProjectCreateWithoutTokensInput;
  update: ProjectUpdateWithoutTokensInput;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  Tokens?: InputMaybe<TokensListRelationFilter>;
  apiKey?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringNullableFilter>;
  prompts?: InputMaybe<PromptListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ProjectWhereUniqueInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Prompt = {
  __typename?: 'Prompt';
  bestOf: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: Maybe<Scalars['String']>;
  project: Project;
  projectId: Scalars['String'];
  prompt: Scalars['String'];
  promptPreset?: Maybe<PromptPreset>;
  promptResponse?: Maybe<Scalars['String']>;
  source?: Maybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: Maybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  variables?: Maybe<Scalars['JSON']>;
};

export type PromptAvgAggregate = {
  __typename?: 'PromptAvgAggregate';
  bestOf?: Maybe<Scalars['Float']>;
  frequencyPenalty?: Maybe<Scalars['Float']>;
  maxTokens?: Maybe<Scalars['Float']>;
  presencePenalty?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
  topP?: Maybe<Scalars['Float']>;
};

export type PromptAvgOrderByAggregateInput = {
  bestOf?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
};

export type PromptCountAggregate = {
  __typename?: 'PromptCountAggregate';
  _all: Scalars['Int'];
  bestOf: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  error: Scalars['Int'];
  frequencyPenalty: Scalars['Int'];
  hasError: Scalars['Int'];
  id: Scalars['Int'];
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Int'];
  presetId: Scalars['Int'];
  projectId: Scalars['Int'];
  prompt: Scalars['Int'];
  promptResponse: Scalars['Int'];
  source: Scalars['Int'];
  stop: Scalars['Int'];
  temperature: Scalars['Int'];
  tokens: Scalars['Int'];
  topP: Scalars['Int'];
  updatedAt: Scalars['Int'];
  variables: Scalars['Int'];
};

export type PromptCountOrderByAggregateInput = {
  bestOf?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  hasError?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  presetId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  prompt?: InputMaybe<SortOrder>;
  promptResponse?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  stop?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  variables?: InputMaybe<SortOrder>;
};

export type PromptCreateInput = {
  bestOf: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutPromptsInput;
  prompt: Scalars['String'];
  promptPreset?: InputMaybe<PromptPresetCreateNestedOneWithoutPromptInput>;
  promptResponse?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: InputMaybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptCreateManyInput = {
  bestOf: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  prompt: Scalars['String'];
  promptResponse?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: InputMaybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptCreateManyProjectInput = {
  bestOf: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  promptResponse?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: InputMaybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptCreateManyProjectInputEnvelope = {
  data: Array<PromptCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PromptCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromptCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<PromptCreateWithoutProjectInput>>;
  createMany?: InputMaybe<PromptCreateManyProjectInputEnvelope>;
};

export type PromptCreateNestedOneWithoutPromptPresetInput = {
  connect?: InputMaybe<PromptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromptCreateOrConnectWithoutPromptPresetInput>;
  create?: InputMaybe<PromptCreateWithoutPromptPresetInput>;
};

export type PromptCreateOrConnectWithoutProjectInput = {
  create: PromptCreateWithoutProjectInput;
  where: PromptWhereUniqueInput;
};

export type PromptCreateOrConnectWithoutPromptPresetInput = {
  create: PromptCreateWithoutPromptPresetInput;
  where: PromptWhereUniqueInput;
};

export type PromptCreateWithoutProjectInput = {
  bestOf: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  promptPreset?: InputMaybe<PromptPresetCreateNestedOneWithoutPromptInput>;
  promptResponse?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: InputMaybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptCreateWithoutPromptPresetInput = {
  bestOf: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutPromptsInput;
  prompt: Scalars['String'];
  promptResponse?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: InputMaybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptGroupBy = {
  __typename?: 'PromptGroupBy';
  _avg?: Maybe<PromptAvgAggregate>;
  _count?: Maybe<PromptCountAggregate>;
  _max?: Maybe<PromptMaxAggregate>;
  _min?: Maybe<PromptMinAggregate>;
  _sum?: Maybe<PromptSumAggregate>;
  bestOf: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  frequencyPenalty: Scalars['Float'];
  hasError?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  maxTokens: Scalars['Int'];
  presencePenalty: Scalars['Float'];
  presetId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  prompt: Scalars['String'];
  promptResponse?: Maybe<Scalars['String']>;
  source?: Maybe<RequestSource>;
  stop: Scalars['String'];
  temperature: Scalars['Float'];
  tokens?: Maybe<Scalars['Int']>;
  topP: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  variables?: Maybe<Scalars['JSON']>;
};

export type PromptListRelationFilter = {
  every?: InputMaybe<PromptWhereInput>;
  none?: InputMaybe<PromptWhereInput>;
  some?: InputMaybe<PromptWhereInput>;
};

export type PromptMaxAggregate = {
  __typename?: 'PromptMaxAggregate';
  bestOf?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  frequencyPenalty?: Maybe<Scalars['Float']>;
  hasError?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  maxTokens?: Maybe<Scalars['Int']>;
  presencePenalty?: Maybe<Scalars['Float']>;
  presetId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  promptResponse?: Maybe<Scalars['String']>;
  source?: Maybe<RequestSource>;
  stop?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Int']>;
  topP?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromptMaxOrderByAggregateInput = {
  bestOf?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  hasError?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  presetId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  prompt?: InputMaybe<SortOrder>;
  promptResponse?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  stop?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptMinAggregate = {
  __typename?: 'PromptMinAggregate';
  bestOf?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  frequencyPenalty?: Maybe<Scalars['Float']>;
  hasError?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  maxTokens?: Maybe<Scalars['Int']>;
  presencePenalty?: Maybe<Scalars['Float']>;
  presetId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  promptResponse?: Maybe<Scalars['String']>;
  source?: Maybe<RequestSource>;
  stop?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Int']>;
  topP?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromptMinOrderByAggregateInput = {
  bestOf?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  hasError?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  presetId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  prompt?: InputMaybe<SortOrder>;
  promptResponse?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  stop?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromptOrderByWithAggregationInput = {
  _avg?: InputMaybe<PromptAvgOrderByAggregateInput>;
  _count?: InputMaybe<PromptCountOrderByAggregateInput>;
  _max?: InputMaybe<PromptMaxOrderByAggregateInput>;
  _min?: InputMaybe<PromptMinOrderByAggregateInput>;
  _sum?: InputMaybe<PromptSumOrderByAggregateInput>;
  bestOf?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  hasError?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  presetId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  prompt?: InputMaybe<SortOrder>;
  promptResponse?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  stop?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  variables?: InputMaybe<SortOrder>;
};

export type PromptOrderByWithRelationInput = {
  bestOf?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  hasError?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  presetId?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  prompt?: InputMaybe<SortOrder>;
  promptPreset?: InputMaybe<PromptPresetOrderByWithRelationInput>;
  promptResponse?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  stop?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  variables?: InputMaybe<SortOrder>;
};

export type PromptPreset = {
  __typename?: 'PromptPreset';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  prompt: Prompt;
  promptId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromptPresetCountAggregate = {
  __typename?: 'PromptPresetCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  isPublic: Scalars['Int'];
  name: Scalars['Int'];
  promptId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PromptPresetCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promptId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptPresetCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  prompt: PromptCreateNestedOneWithoutPromptPresetInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromptPresetCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  promptId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromptPresetCreateNestedOneWithoutPromptInput = {
  connect?: InputMaybe<PromptPresetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromptPresetCreateOrConnectWithoutPromptInput>;
  create?: InputMaybe<PromptPresetCreateWithoutPromptInput>;
};

export type PromptPresetCreateOrConnectWithoutPromptInput = {
  create: PromptPresetCreateWithoutPromptInput;
  where: PromptPresetWhereUniqueInput;
};

export type PromptPresetCreateWithoutPromptInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromptPresetGroupBy = {
  __typename?: 'PromptPresetGroupBy';
  _count?: Maybe<PromptPresetCountAggregate>;
  _max?: Maybe<PromptPresetMaxAggregate>;
  _min?: Maybe<PromptPresetMinAggregate>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  promptId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromptPresetMaxAggregate = {
  __typename?: 'PromptPresetMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  promptId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromptPresetMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promptId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptPresetMinAggregate = {
  __typename?: 'PromptPresetMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  promptId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromptPresetMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promptId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptPresetOrderByWithAggregationInput = {
  _count?: InputMaybe<PromptPresetCountOrderByAggregateInput>;
  _max?: InputMaybe<PromptPresetMaxOrderByAggregateInput>;
  _min?: InputMaybe<PromptPresetMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promptId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptPresetOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  prompt?: InputMaybe<PromptOrderByWithRelationInput>;
  promptId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromptPresetRelationFilter = {
  is?: InputMaybe<PromptPresetWhereInput>;
  isNot?: InputMaybe<PromptPresetWhereInput>;
};

export enum PromptPresetScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  IsPublic = 'isPublic',
  Name = 'name',
  PromptId = 'promptId',
  UpdatedAt = 'updatedAt'
}

export type PromptPresetScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PromptPresetScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PromptPresetScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PromptPresetScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPublic?: InputMaybe<BoolWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  promptId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PromptPresetUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prompt?: InputMaybe<PromptUpdateOneRequiredWithoutPromptPresetNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromptPresetUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromptPresetUpdateOneWithoutPromptNestedInput = {
  connect?: InputMaybe<PromptPresetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromptPresetCreateOrConnectWithoutPromptInput>;
  create?: InputMaybe<PromptPresetCreateWithoutPromptInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PromptPresetUpdateWithoutPromptInput>;
  upsert?: InputMaybe<PromptPresetUpsertWithoutPromptInput>;
};

export type PromptPresetUpdateWithoutPromptInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromptPresetUpsertWithoutPromptInput = {
  create: PromptPresetCreateWithoutPromptInput;
  update: PromptPresetUpdateWithoutPromptInput;
};

export type PromptPresetWhereInput = {
  AND?: InputMaybe<Array<PromptPresetWhereInput>>;
  NOT?: InputMaybe<Array<PromptPresetWhereInput>>;
  OR?: InputMaybe<Array<PromptPresetWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  prompt?: InputMaybe<PromptRelationFilter>;
  promptId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromptPresetWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  promptId?: InputMaybe<Scalars['String']>;
};

export type PromptRelationFilter = {
  is?: InputMaybe<PromptWhereInput>;
  isNot?: InputMaybe<PromptWhereInput>;
};

export enum PromptScalarFieldEnum {
  BestOf = 'bestOf',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Error = 'error',
  FrequencyPenalty = 'frequencyPenalty',
  HasError = 'hasError',
  Id = 'id',
  MaxTokens = 'maxTokens',
  PresencePenalty = 'presencePenalty',
  PresetId = 'presetId',
  ProjectId = 'projectId',
  Prompt = 'prompt',
  PromptResponse = 'promptResponse',
  Source = 'source',
  Stop = 'stop',
  Temperature = 'temperature',
  Tokens = 'tokens',
  TopP = 'topP',
  UpdatedAt = 'updatedAt',
  Variables = 'variables'
}

export type PromptScalarWhereInput = {
  AND?: InputMaybe<Array<PromptScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromptScalarWhereInput>>;
  OR?: InputMaybe<Array<PromptScalarWhereInput>>;
  bestOf?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  error?: InputMaybe<StringNullableFilter>;
  frequencyPenalty?: InputMaybe<FloatFilter>;
  hasError?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  maxTokens?: InputMaybe<IntFilter>;
  presencePenalty?: InputMaybe<FloatFilter>;
  presetId?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<StringFilter>;
  prompt?: InputMaybe<StringFilter>;
  promptResponse?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<EnumRequestSourceNullableFilter>;
  stop?: InputMaybe<StringFilter>;
  temperature?: InputMaybe<FloatFilter>;
  tokens?: InputMaybe<IntNullableFilter>;
  topP?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  variables?: InputMaybe<JsonNullableFilter>;
};

export type PromptScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PromptScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PromptScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PromptScalarWhereWithAggregatesInput>>;
  bestOf?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  error?: InputMaybe<StringNullableWithAggregatesFilter>;
  frequencyPenalty?: InputMaybe<FloatWithAggregatesFilter>;
  hasError?: InputMaybe<BoolNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  maxTokens?: InputMaybe<IntWithAggregatesFilter>;
  presencePenalty?: InputMaybe<FloatWithAggregatesFilter>;
  presetId?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectId?: InputMaybe<StringWithAggregatesFilter>;
  prompt?: InputMaybe<StringWithAggregatesFilter>;
  promptResponse?: InputMaybe<StringNullableWithAggregatesFilter>;
  source?: InputMaybe<EnumRequestSourceNullableWithAggregatesFilter>;
  stop?: InputMaybe<StringWithAggregatesFilter>;
  temperature?: InputMaybe<FloatWithAggregatesFilter>;
  tokens?: InputMaybe<IntNullableWithAggregatesFilter>;
  topP?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  variables?: InputMaybe<JsonNullableWithAggregatesFilter>;
};

export type PromptSumAggregate = {
  __typename?: 'PromptSumAggregate';
  bestOf?: Maybe<Scalars['Int']>;
  frequencyPenalty?: Maybe<Scalars['Float']>;
  maxTokens?: Maybe<Scalars['Int']>;
  presencePenalty?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Int']>;
  topP?: Maybe<Scalars['Float']>;
};

export type PromptSumOrderByAggregateInput = {
  bestOf?: InputMaybe<SortOrder>;
  frequencyPenalty?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  presencePenalty?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<SortOrder>;
  topP?: InputMaybe<SortOrder>;
};

export type PromptUpdateInput = {
  bestOf?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  error?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frequencyPenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hasError?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxTokens?: InputMaybe<IntFieldUpdateOperationsInput>;
  presencePenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  presetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutPromptsNestedInput>;
  prompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  promptPreset?: InputMaybe<PromptPresetUpdateOneWithoutPromptNestedInput>;
  promptResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  source?: InputMaybe<NullableEnumRequestSourceFieldUpdateOperationsInput>;
  stop?: InputMaybe<StringFieldUpdateOperationsInput>;
  temperature?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tokens?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  topP?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptUpdateManyMutationInput = {
  bestOf?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  error?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frequencyPenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hasError?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxTokens?: InputMaybe<IntFieldUpdateOperationsInput>;
  presencePenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  presetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  promptResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  source?: InputMaybe<NullableEnumRequestSourceFieldUpdateOperationsInput>;
  stop?: InputMaybe<StringFieldUpdateOperationsInput>;
  temperature?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tokens?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  topP?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptUpdateManyWithWhereWithoutProjectInput = {
  data: PromptUpdateManyMutationInput;
  where: PromptScalarWhereInput;
};

export type PromptUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromptCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<PromptCreateWithoutProjectInput>>;
  createMany?: InputMaybe<PromptCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<PromptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  set?: InputMaybe<Array<PromptWhereUniqueInput>>;
  update?: InputMaybe<Array<PromptUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<PromptUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<PromptUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type PromptUpdateOneRequiredWithoutPromptPresetNestedInput = {
  connect?: InputMaybe<PromptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromptCreateOrConnectWithoutPromptPresetInput>;
  create?: InputMaybe<PromptCreateWithoutPromptPresetInput>;
  update?: InputMaybe<PromptUpdateWithoutPromptPresetInput>;
  upsert?: InputMaybe<PromptUpsertWithoutPromptPresetInput>;
};

export type PromptUpdateWithWhereUniqueWithoutProjectInput = {
  data: PromptUpdateWithoutProjectInput;
  where: PromptWhereUniqueInput;
};

export type PromptUpdateWithoutProjectInput = {
  bestOf?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  error?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frequencyPenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hasError?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxTokens?: InputMaybe<IntFieldUpdateOperationsInput>;
  presencePenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  presetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  promptPreset?: InputMaybe<PromptPresetUpdateOneWithoutPromptNestedInput>;
  promptResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  source?: InputMaybe<NullableEnumRequestSourceFieldUpdateOperationsInput>;
  stop?: InputMaybe<StringFieldUpdateOperationsInput>;
  temperature?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tokens?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  topP?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptUpdateWithoutPromptPresetInput = {
  bestOf?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  error?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frequencyPenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hasError?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxTokens?: InputMaybe<IntFieldUpdateOperationsInput>;
  presencePenalty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  presetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutPromptsNestedInput>;
  prompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  promptResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  source?: InputMaybe<NullableEnumRequestSourceFieldUpdateOperationsInput>;
  stop?: InputMaybe<StringFieldUpdateOperationsInput>;
  temperature?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tokens?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  topP?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type PromptUpsertWithWhereUniqueWithoutProjectInput = {
  create: PromptCreateWithoutProjectInput;
  update: PromptUpdateWithoutProjectInput;
  where: PromptWhereUniqueInput;
};

export type PromptUpsertWithoutPromptPresetInput = {
  create: PromptCreateWithoutPromptPresetInput;
  update: PromptUpdateWithoutPromptPresetInput;
};

export type PromptWhereInput = {
  AND?: InputMaybe<Array<PromptWhereInput>>;
  NOT?: InputMaybe<Array<PromptWhereInput>>;
  OR?: InputMaybe<Array<PromptWhereInput>>;
  bestOf?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  error?: InputMaybe<StringNullableFilter>;
  frequencyPenalty?: InputMaybe<FloatFilter>;
  hasError?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  maxTokens?: InputMaybe<IntFilter>;
  presencePenalty?: InputMaybe<FloatFilter>;
  presetId?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  prompt?: InputMaybe<StringFilter>;
  promptPreset?: InputMaybe<PromptPresetRelationFilter>;
  promptResponse?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<EnumRequestSourceNullableFilter>;
  stop?: InputMaybe<StringFilter>;
  temperature?: InputMaybe<FloatFilter>;
  tokens?: InputMaybe<IntNullableFilter>;
  topP?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  variables?: InputMaybe<JsonNullableFilter>;
};

export type PromptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateProfile: AggregateProfile;
  aggregateProject: AggregateProject;
  aggregatePrompt: AggregatePrompt;
  aggregatePromptPreset: AggregatePromptPreset;
  aggregateTokens: AggregateTokens;
  aggregateUser: AggregateUser;
  findFirstProfile?: Maybe<Profile>;
  findFirstProject?: Maybe<Project>;
  findFirstPrompt?: Maybe<Prompt>;
  findFirstPromptPreset?: Maybe<PromptPreset>;
  findFirstTokens?: Maybe<Tokens>;
  findFirstUser?: Maybe<User>;
  findManyTokens: Array<Tokens>;
  findUniqueTokens?: Maybe<Tokens>;
  getPromptPresetById: PromptPreset;
  groupByProfile: Array<ProfileGroupBy>;
  groupByProject: Array<ProjectGroupBy>;
  groupByPrompt: Array<PromptGroupBy>;
  groupByPromptPreset: Array<PromptPresetGroupBy>;
  groupByTokens: Array<TokensGroupBy>;
  groupByUser: Array<UserGroupBy>;
  profile?: Maybe<Profile>;
  profiles: Array<Profile>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  prompt?: Maybe<Prompt>;
  promptPreset?: Maybe<PromptPreset>;
  promptPresets: Array<PromptPreset>;
  prompts: Array<Prompt>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryAggregateProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProfileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryAggregateProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryAggregatePromptArgs = {
  cursor?: InputMaybe<PromptWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptWhereInput>;
};


export type QueryAggregatePromptPresetArgs = {
  cursor?: InputMaybe<PromptPresetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromptPresetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptPresetWhereInput>;
};


export type QueryAggregateTokensArgs = {
  cursor?: InputMaybe<TokensWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TokensOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TokensWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryFindFirstProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstPromptArgs = {
  cursor?: InputMaybe<PromptWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptWhereInput>;
};


export type QueryFindFirstPromptPresetArgs = {
  cursor?: InputMaybe<PromptPresetWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromptPresetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromptPresetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptPresetWhereInput>;
};


export type QueryFindFirstTokensArgs = {
  cursor?: InputMaybe<TokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<TokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TokensOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TokensWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindManyTokensArgs = {
  cursor?: InputMaybe<TokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<TokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TokensOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TokensWhereInput>;
};


export type QueryFindUniqueTokensArgs = {
  where: TokensWhereUniqueInput;
};


export type QueryGetPromptPresetByIdArgs = {
  id: Scalars['String'];
};


export type QueryGroupByProfileArgs = {
  by: Array<ProfileScalarFieldEnum>;
  having?: InputMaybe<ProfileScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProfileOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryGroupByProjectArgs = {
  by: Array<ProjectScalarFieldEnum>;
  having?: InputMaybe<ProjectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryGroupByPromptArgs = {
  by: Array<PromptScalarFieldEnum>;
  having?: InputMaybe<PromptScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PromptOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptWhereInput>;
};


export type QueryGroupByPromptPresetArgs = {
  by: Array<PromptPresetScalarFieldEnum>;
  having?: InputMaybe<PromptPresetScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PromptPresetOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptPresetWhereInput>;
};


export type QueryGroupByTokensArgs = {
  by: Array<TokensScalarFieldEnum>;
  having?: InputMaybe<TokensScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TokensOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TokensWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type QueryProfilesArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryPromptArgs = {
  where: PromptWhereUniqueInput;
};


export type QueryPromptPresetArgs = {
  where: PromptPresetWhereUniqueInput;
};


export type QueryPromptPresetsArgs = {
  cursor?: InputMaybe<PromptPresetWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromptPresetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromptPresetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptPresetWhereInput>;
};


export type QueryPromptsArgs = {
  cursor?: InputMaybe<PromptWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromptWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum RequestSource {
  App = 'APP',
  SdkNode = 'SDK_NODE',
  SdkPython = 'SDK_PYTHON',
  Web = 'WEB'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Tokens = {
  __typename?: 'Tokens';
  id: Scalars['String'];
  project: Project;
  projectId: Scalars['String'];
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensAvgAggregate = {
  __typename?: 'TokensAvgAggregate';
  tokensLimit?: Maybe<Scalars['Float']>;
  tokensUsed?: Maybe<Scalars['Float']>;
};

export type TokensAvgOrderByAggregateInput = {
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export type TokensCountAggregate = {
  __typename?: 'TokensCountAggregate';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  projectId: Scalars['Int'];
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export type TokensCreateInput = {
  id?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutTokensInput;
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensCreateManyInput = {
  id?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensCreateManyProjectInput = {
  id?: InputMaybe<Scalars['String']>;
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensCreateManyProjectInputEnvelope = {
  data: Array<TokensCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TokensCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<TokensWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TokensCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<TokensCreateWithoutProjectInput>>;
  createMany?: InputMaybe<TokensCreateManyProjectInputEnvelope>;
};

export type TokensCreateOrConnectWithoutProjectInput = {
  create: TokensCreateWithoutProjectInput;
  where: TokensWhereUniqueInput;
};

export type TokensCreateWithoutProjectInput = {
  id?: InputMaybe<Scalars['String']>;
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensGroupBy = {
  __typename?: 'TokensGroupBy';
  _avg?: Maybe<TokensAvgAggregate>;
  _count?: Maybe<TokensCountAggregate>;
  _max?: Maybe<TokensMaxAggregate>;
  _min?: Maybe<TokensMinAggregate>;
  _sum?: Maybe<TokensSumAggregate>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  tokensLimit: Scalars['Int'];
  tokensUsed: Scalars['Int'];
};

export type TokensListRelationFilter = {
  every?: InputMaybe<TokensWhereInput>;
  none?: InputMaybe<TokensWhereInput>;
  some?: InputMaybe<TokensWhereInput>;
};

export type TokensMaxAggregate = {
  __typename?: 'TokensMaxAggregate';
  id?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  tokensLimit?: Maybe<Scalars['Int']>;
  tokensUsed?: Maybe<Scalars['Int']>;
};

export type TokensMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export type TokensMinAggregate = {
  __typename?: 'TokensMinAggregate';
  id?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  tokensLimit?: Maybe<Scalars['Int']>;
  tokensUsed?: Maybe<Scalars['Int']>;
};

export type TokensMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export type TokensOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TokensOrderByWithAggregationInput = {
  _avg?: InputMaybe<TokensAvgOrderByAggregateInput>;
  _count?: InputMaybe<TokensCountOrderByAggregateInput>;
  _max?: InputMaybe<TokensMaxOrderByAggregateInput>;
  _min?: InputMaybe<TokensMinOrderByAggregateInput>;
  _sum?: InputMaybe<TokensSumOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export type TokensOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export enum TokensScalarFieldEnum {
  Id = 'id',
  ProjectId = 'projectId',
  TokensLimit = 'tokensLimit',
  TokensUsed = 'tokensUsed'
}

export type TokensScalarWhereInput = {
  AND?: InputMaybe<Array<TokensScalarWhereInput>>;
  NOT?: InputMaybe<Array<TokensScalarWhereInput>>;
  OR?: InputMaybe<Array<TokensScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  projectId?: InputMaybe<StringFilter>;
  tokensLimit?: InputMaybe<IntFilter>;
  tokensUsed?: InputMaybe<IntFilter>;
};

export type TokensScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TokensScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TokensScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TokensScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  projectId?: InputMaybe<StringWithAggregatesFilter>;
  tokensLimit?: InputMaybe<IntWithAggregatesFilter>;
  tokensUsed?: InputMaybe<IntWithAggregatesFilter>;
};

export type TokensSumAggregate = {
  __typename?: 'TokensSumAggregate';
  tokensLimit?: Maybe<Scalars['Int']>;
  tokensUsed?: Maybe<Scalars['Int']>;
};

export type TokensSumOrderByAggregateInput = {
  tokensLimit?: InputMaybe<SortOrder>;
  tokensUsed?: InputMaybe<SortOrder>;
};

export type TokensUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutTokensNestedInput>;
  tokensLimit?: InputMaybe<IntFieldUpdateOperationsInput>;
  tokensUsed?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TokensUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokensLimit?: InputMaybe<IntFieldUpdateOperationsInput>;
  tokensUsed?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TokensUpdateManyWithWhereWithoutProjectInput = {
  data: TokensUpdateManyMutationInput;
  where: TokensScalarWhereInput;
};

export type TokensUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<TokensWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TokensCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<TokensCreateWithoutProjectInput>>;
  createMany?: InputMaybe<TokensCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<TokensWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TokensScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TokensWhereUniqueInput>>;
  set?: InputMaybe<Array<TokensWhereUniqueInput>>;
  update?: InputMaybe<Array<TokensUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<TokensUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<TokensUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type TokensUpdateWithWhereUniqueWithoutProjectInput = {
  data: TokensUpdateWithoutProjectInput;
  where: TokensWhereUniqueInput;
};

export type TokensUpdateWithoutProjectInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokensLimit?: InputMaybe<IntFieldUpdateOperationsInput>;
  tokensUsed?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TokensUpsertWithWhereUniqueWithoutProjectInput = {
  create: TokensCreateWithoutProjectInput;
  update: TokensUpdateWithoutProjectInput;
  where: TokensWhereUniqueInput;
};

export type TokensWhereInput = {
  AND?: InputMaybe<Array<TokensWhereInput>>;
  NOT?: InputMaybe<Array<TokensWhereInput>>;
  OR?: InputMaybe<Array<TokensWhereInput>>;
  id?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  tokensLimit?: InputMaybe<IntFilter>;
  tokensUsed?: InputMaybe<IntFilter>;
};

export type TokensWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['String']>;
  project: Array<Project>;
  roles: Array<UserRole>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};


export type UserProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  project: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  profileId: Scalars['Int'];
  roles: Scalars['Int'];
  uid: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  roles?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileCreateNestedOneWithoutUserInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutUserInput>;
  roles?: InputMaybe<UserCreaterolesInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  profileId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<UserCreaterolesInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateNestedOneWithoutProfileInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<UserCreateWithoutProfileInput>;
};

export type UserCreateNestedOneWithoutProjectInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<UserCreateWithoutProjectInput>;
};

export type UserCreateOrConnectWithoutProfileInput = {
  create: UserCreateWithoutProfileInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProjectInput = {
  create: UserCreateWithoutProjectInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutUserInput>;
  roles?: InputMaybe<UserCreaterolesInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutProjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileCreateNestedOneWithoutUserInput>;
  roles?: InputMaybe<UserCreaterolesInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreaterolesInput = {
  set: Array<UserRole>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  profileId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profileId?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profileId?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  roles?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByWithRelationInput>;
  profileId?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  roles?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  PartnerLegacy = 'PARTNER_LEGACY',
  SuperAdmin = 'SUPER_ADMIN',
  User = 'USER'
}

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  Id = 'id',
  IsActive = 'isActive',
  ProfileId = 'profileId',
  Roles = 'roles',
  Uid = 'uid',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isActive?: InputMaybe<BoolWithAggregatesFilter>;
  profileId?: InputMaybe<StringNullableWithAggregatesFilter>;
  roles?: InputMaybe<EnumUserRoleNullableListFilter>;
  uid?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  username?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateOneWithoutUserNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutUserNestedInput>;
  roles?: InputMaybe<UserUpdaterolesInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  roles?: InputMaybe<UserUpdaterolesInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateOneWithoutProfileNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<UserCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutProfileInput>;
  upsert?: InputMaybe<UserUpsertWithoutProfileInput>;
};

export type UserUpdateOneWithoutProjectNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<UserCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutProjectInput>;
  upsert?: InputMaybe<UserUpsertWithoutProjectInput>;
};

export type UserUpdateWithoutProfileInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutUserNestedInput>;
  roles?: InputMaybe<UserUpdaterolesInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProjectInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateOneWithoutUserNestedInput>;
  roles?: InputMaybe<UserUpdaterolesInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdaterolesInput = {
  push?: InputMaybe<Array<UserRole>>;
  set?: InputMaybe<Array<UserRole>>;
};

export type UserUpsertWithoutProfileInput = {
  create: UserCreateWithoutProfileInput;
  update: UserUpdateWithoutProfileInput;
};

export type UserUpsertWithoutProjectInput = {
  create: UserCreateWithoutProjectInput;
  update: UserUpdateWithoutProjectInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  roles?: InputMaybe<EnumUserRoleNullableListFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type PromptInfoFragment = { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined };

export type PromptInfoExtendsFragment = { __typename?: 'Prompt', promptResponse?: string | null | undefined, createdAt: any, hasError?: boolean | null | undefined, error?: string | null | undefined, id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined };

export type PromptPresetInfoFragment = { __typename?: 'PromptPreset', id: string, name: string, description?: string | null | undefined, isPublic: boolean, prompt: { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined } };

export type PromptByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PromptByIdQuery = { __typename?: 'Query', prompt?: { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined } | null | undefined };

export type PromptsByUserIdWithPaginationQueryVariables = Exact<{
  userId: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  orderBy?: InputMaybe<Array<PromptOrderByWithRelationInput> | PromptOrderByWithRelationInput>;
}>;


export type PromptsByUserIdWithPaginationQuery = { __typename?: 'Query', prompts: Array<{ __typename?: 'Prompt', promptResponse?: string | null | undefined, createdAt: any, hasError?: boolean | null | undefined, error?: string | null | undefined, id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined }>, aggregatePrompt: { __typename?: 'AggregatePrompt', _count?: { __typename?: 'PromptCountAggregate', id: number } | null | undefined } };

export type GetPromptPresetByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPromptPresetByIdQuery = { __typename?: 'Query', getPromptPresetById: { __typename?: 'PromptPreset', id: string, name: string, description?: string | null | undefined, isPublic: boolean, prompt: { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined } } };

export type PromptPresetsByUserUidQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type PromptPresetsByUserUidQuery = { __typename?: 'Query', promptPresets: Array<{ __typename?: 'PromptPreset', id: string, name: string, description?: string | null | undefined, isPublic: boolean, prompt: { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined } }> };

export type CreateOnePromptPresetMutationVariables = Exact<{
  data: PromptPresetCreateInput;
}>;


export type CreateOnePromptPresetMutation = { __typename?: 'Mutation', createOnePromptPreset: { __typename?: 'PromptPreset', id: string, name: string, description?: string | null | undefined, isPublic: boolean, prompt: { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined } } };

export type DeleteOnePromptPresetMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOnePromptPresetMutation = { __typename?: 'Mutation', deleteOnePromptPreset?: { __typename?: 'PromptPreset', id: string } | null | undefined };

export type PredictMutationVariables = Exact<{
  data: PromptCreateInput;
}>;


export type PredictMutation = { __typename?: 'Mutation', predict: Array<string> };

export type PredictCodeMutationVariables = Exact<{
  data: PromptCreateInput;
}>;


export type PredictCodeMutation = { __typename?: 'Mutation', predictCode: Array<string> };

export type PredictChatMutationVariables = Exact<{
  question: Scalars['String'];
}>;


export type PredictChatMutation = { __typename?: 'Mutation', predictChat: string };

export type UpdateOnePromptPresetMutationVariables = Exact<{
  data: PromptPresetUpdateInput;
  id: Scalars['String'];
}>;


export type UpdateOnePromptPresetMutation = { __typename?: 'Mutation', updateOnePromptPreset?: { __typename?: 'PromptPreset', id: string, name: string, description?: string | null | undefined, isPublic: boolean, prompt: { __typename?: 'Prompt', id: string, bestOf: number, frequencyPenalty: number, maxTokens: number, presencePenalty: number, prompt: string, stop: string, temperature: number, topP: number, source?: RequestSource | null | undefined, tokens?: number | null | undefined, variables?: any | null | undefined } } | null | undefined };

export type UserProfileInfoFragment = { __typename?: 'Profile', firstName?: string | null | undefined, lastName?: string | null | undefined, companyName?: string | null | undefined };

export type UserInfoFragment = { __typename?: 'User', id: string, username: string, roles: Array<UserRole>, email: string, isActive: boolean, profile?: { __typename?: 'Profile', firstName?: string | null | undefined, lastName?: string | null | undefined, companyName?: string | null | undefined } | null | undefined, project: Array<{ __typename?: 'Project', id: string, name?: string | null | undefined, apiKey: string }> };

export type UserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, username: string, roles: Array<UserRole>, email: string, isActive: boolean, profile?: { __typename?: 'Profile', firstName?: string | null | undefined, lastName?: string | null | undefined, companyName?: string | null | undefined } | null | undefined, project: Array<{ __typename?: 'Project', id: string, name?: string | null | undefined, apiKey: string }> } | null | undefined };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, username: string, roles: Array<UserRole>, email: string, isActive: boolean, profile?: { __typename?: 'Profile', firstName?: string | null | undefined, lastName?: string | null | undefined, companyName?: string | null | undefined } | null | undefined, project: Array<{ __typename?: 'Project', id: string, name?: string | null | undefined, apiKey: string }> }> };

export type CreateOneUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateOneUserMutation = { __typename?: 'Mutation', createOneUser: { __typename?: 'User', id: string, username: string, roles: Array<UserRole>, email: string, isActive: boolean, profile?: { __typename?: 'Profile', firstName?: string | null | undefined, lastName?: string | null | undefined, companyName?: string | null | undefined } | null | undefined, project: Array<{ __typename?: 'Project', id: string, name?: string | null | undefined, apiKey: string }> } };

export type UpdateOneUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser?: { __typename?: 'User', id: string, username: string, roles: Array<UserRole>, email: string, isActive: boolean, profile?: { __typename?: 'Profile', firstName?: string | null | undefined, lastName?: string | null | undefined, companyName?: string | null | undefined } | null | undefined, project: Array<{ __typename?: 'Project', id: string, name?: string | null | undefined, apiKey: string }> } | null | undefined };

export type DeleteOneUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type DeleteOneUserMutation = { __typename?: 'Mutation', deleteOneUser?: { __typename?: 'User', id: string } | null | undefined };

export const PromptInfoFragmentDoc = gql`
    fragment PromptInfo on Prompt {
  id
  bestOf
  frequencyPenalty
  maxTokens
  presencePenalty
  prompt
  stop
  temperature
  topP
  source
  tokens
  variables
}
    `;
export const PromptInfoExtendsFragmentDoc = gql`
    fragment PromptInfoExtends on Prompt {
  ...PromptInfo
  promptResponse
  createdAt
  hasError
  error
}
    ${PromptInfoFragmentDoc}`;
export const PromptPresetInfoFragmentDoc = gql`
    fragment PromptPresetInfo on PromptPreset {
  id
  name
  description
  isPublic
  prompt {
    ...PromptInfo
  }
}
    ${PromptInfoFragmentDoc}`;
export const UserProfileInfoFragmentDoc = gql`
    fragment UserProfileInfo on Profile {
  firstName
  lastName
  companyName
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  username
  roles
  email
  isActive
  profile {
    ...UserProfileInfo
  }
  project {
    id
    name
    apiKey
  }
}
    ${UserProfileInfoFragmentDoc}`;
export const PromptByIdDocument = gql`
    query PromptById($id: String!) {
  prompt(where: {id: $id}) {
    ...PromptInfo
  }
}
    ${PromptInfoFragmentDoc}`;

/**
 * __usePromptByIdQuery__
 *
 * To run a query within a React component, call `usePromptByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromptByIdQuery(baseOptions: Apollo.QueryHookOptions<PromptByIdQuery, PromptByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromptByIdQuery, PromptByIdQueryVariables>(PromptByIdDocument, options);
      }
export function usePromptByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptByIdQuery, PromptByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromptByIdQuery, PromptByIdQueryVariables>(PromptByIdDocument, options);
        }
export type PromptByIdQueryHookResult = ReturnType<typeof usePromptByIdQuery>;
export type PromptByIdLazyQueryHookResult = ReturnType<typeof usePromptByIdLazyQuery>;
export type PromptByIdQueryResult = Apollo.QueryResult<PromptByIdQuery, PromptByIdQueryVariables>;
export const PromptsByUserIdWithPaginationDocument = gql`
    query PromptsByUserIdWithPagination($userId: String!, $from: DateTime!, $to: DateTime!, $skip: Int!, $take: Int!, $orderBy: [PromptOrderByWithRelationInput!]) {
  prompts(
    where: {AND: {createdAt: {gte: $from}, AND: {createdAt: {lte: $to}}}, project: {is: {user: {is: {id: {equals: $userId}}}}}}
    skip: $skip
    take: $take
    orderBy: $orderBy
  ) {
    ...PromptInfoExtends
  }
  aggregatePrompt(
    where: {AND: {createdAt: {gte: $from}, AND: {createdAt: {lte: $to}}}, project: {is: {user: {is: {id: {equals: $userId}}}}}}
  ) {
    _count {
      id
    }
  }
}
    ${PromptInfoExtendsFragmentDoc}`;

/**
 * __usePromptsByUserIdWithPaginationQuery__
 *
 * To run a query within a React component, call `usePromptsByUserIdWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptsByUserIdWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptsByUserIdWithPaginationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePromptsByUserIdWithPaginationQuery(baseOptions: Apollo.QueryHookOptions<PromptsByUserIdWithPaginationQuery, PromptsByUserIdWithPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromptsByUserIdWithPaginationQuery, PromptsByUserIdWithPaginationQueryVariables>(PromptsByUserIdWithPaginationDocument, options);
      }
export function usePromptsByUserIdWithPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptsByUserIdWithPaginationQuery, PromptsByUserIdWithPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromptsByUserIdWithPaginationQuery, PromptsByUserIdWithPaginationQueryVariables>(PromptsByUserIdWithPaginationDocument, options);
        }
export type PromptsByUserIdWithPaginationQueryHookResult = ReturnType<typeof usePromptsByUserIdWithPaginationQuery>;
export type PromptsByUserIdWithPaginationLazyQueryHookResult = ReturnType<typeof usePromptsByUserIdWithPaginationLazyQuery>;
export type PromptsByUserIdWithPaginationQueryResult = Apollo.QueryResult<PromptsByUserIdWithPaginationQuery, PromptsByUserIdWithPaginationQueryVariables>;
export const GetPromptPresetByIdDocument = gql`
    query GetPromptPresetById($id: String!) {
  getPromptPresetById(id: $id) {
    ...PromptPresetInfo
  }
}
    ${PromptPresetInfoFragmentDoc}`;

/**
 * __useGetPromptPresetByIdQuery__
 *
 * To run a query within a React component, call `useGetPromptPresetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromptPresetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromptPresetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPromptPresetByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPromptPresetByIdQuery, GetPromptPresetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromptPresetByIdQuery, GetPromptPresetByIdQueryVariables>(GetPromptPresetByIdDocument, options);
      }
export function useGetPromptPresetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromptPresetByIdQuery, GetPromptPresetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromptPresetByIdQuery, GetPromptPresetByIdQueryVariables>(GetPromptPresetByIdDocument, options);
        }
export type GetPromptPresetByIdQueryHookResult = ReturnType<typeof useGetPromptPresetByIdQuery>;
export type GetPromptPresetByIdLazyQueryHookResult = ReturnType<typeof useGetPromptPresetByIdLazyQuery>;
export type GetPromptPresetByIdQueryResult = Apollo.QueryResult<GetPromptPresetByIdQuery, GetPromptPresetByIdQueryVariables>;
export const PromptPresetsByUserUidDocument = gql`
    query PromptPresetsByUserUid($uid: String!) {
  promptPresets(
    where: {prompt: {is: {project: {is: {user: {is: {uid: {equals: $uid}}}}}}}}
    orderBy: {createdAt: asc}
  ) {
    ...PromptPresetInfo
  }
}
    ${PromptPresetInfoFragmentDoc}`;

/**
 * __usePromptPresetsByUserUidQuery__
 *
 * To run a query within a React component, call `usePromptPresetsByUserUidQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptPresetsByUserUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptPresetsByUserUidQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function usePromptPresetsByUserUidQuery(baseOptions: Apollo.QueryHookOptions<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>(PromptPresetsByUserUidDocument, options);
      }
export function usePromptPresetsByUserUidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>(PromptPresetsByUserUidDocument, options);
        }
export type PromptPresetsByUserUidQueryHookResult = ReturnType<typeof usePromptPresetsByUserUidQuery>;
export type PromptPresetsByUserUidLazyQueryHookResult = ReturnType<typeof usePromptPresetsByUserUidLazyQuery>;
export type PromptPresetsByUserUidQueryResult = Apollo.QueryResult<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>;
export const CreateOnePromptPresetDocument = gql`
    mutation CreateOnePromptPreset($data: PromptPresetCreateInput!) {
  createOnePromptPreset(data: $data) {
    ...PromptPresetInfo
  }
}
    ${PromptPresetInfoFragmentDoc}`;
export type CreateOnePromptPresetMutationFn = Apollo.MutationFunction<CreateOnePromptPresetMutation, CreateOnePromptPresetMutationVariables>;

/**
 * __useCreateOnePromptPresetMutation__
 *
 * To run a mutation, you first call `useCreateOnePromptPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnePromptPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnePromptPresetMutation, { data, loading, error }] = useCreateOnePromptPresetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOnePromptPresetMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnePromptPresetMutation, CreateOnePromptPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnePromptPresetMutation, CreateOnePromptPresetMutationVariables>(CreateOnePromptPresetDocument, options);
      }
export type CreateOnePromptPresetMutationHookResult = ReturnType<typeof useCreateOnePromptPresetMutation>;
export type CreateOnePromptPresetMutationResult = Apollo.MutationResult<CreateOnePromptPresetMutation>;
export type CreateOnePromptPresetMutationOptions = Apollo.BaseMutationOptions<CreateOnePromptPresetMutation, CreateOnePromptPresetMutationVariables>;
export const DeleteOnePromptPresetDocument = gql`
    mutation DeleteOnePromptPreset($id: String!) {
  deleteOnePromptPreset(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteOnePromptPresetMutationFn = Apollo.MutationFunction<DeleteOnePromptPresetMutation, DeleteOnePromptPresetMutationVariables>;

/**
 * __useDeleteOnePromptPresetMutation__
 *
 * To run a mutation, you first call `useDeleteOnePromptPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnePromptPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnePromptPresetMutation, { data, loading, error }] = useDeleteOnePromptPresetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOnePromptPresetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnePromptPresetMutation, DeleteOnePromptPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnePromptPresetMutation, DeleteOnePromptPresetMutationVariables>(DeleteOnePromptPresetDocument, options);
      }
export type DeleteOnePromptPresetMutationHookResult = ReturnType<typeof useDeleteOnePromptPresetMutation>;
export type DeleteOnePromptPresetMutationResult = Apollo.MutationResult<DeleteOnePromptPresetMutation>;
export type DeleteOnePromptPresetMutationOptions = Apollo.BaseMutationOptions<DeleteOnePromptPresetMutation, DeleteOnePromptPresetMutationVariables>;
export const PredictDocument = gql`
    mutation Predict($data: PromptCreateInput!) {
  predict(data: $data)
}
    `;
export type PredictMutationFn = Apollo.MutationFunction<PredictMutation, PredictMutationVariables>;

/**
 * __usePredictMutation__
 *
 * To run a mutation, you first call `usePredictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePredictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [predictMutation, { data, loading, error }] = usePredictMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePredictMutation(baseOptions?: Apollo.MutationHookOptions<PredictMutation, PredictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PredictMutation, PredictMutationVariables>(PredictDocument, options);
      }
export type PredictMutationHookResult = ReturnType<typeof usePredictMutation>;
export type PredictMutationResult = Apollo.MutationResult<PredictMutation>;
export type PredictMutationOptions = Apollo.BaseMutationOptions<PredictMutation, PredictMutationVariables>;
export const PredictCodeDocument = gql`
    mutation PredictCode($data: PromptCreateInput!) {
  predictCode(data: $data)
}
    `;
export type PredictCodeMutationFn = Apollo.MutationFunction<PredictCodeMutation, PredictCodeMutationVariables>;

/**
 * __usePredictCodeMutation__
 *
 * To run a mutation, you first call `usePredictCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePredictCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [predictCodeMutation, { data, loading, error }] = usePredictCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePredictCodeMutation(baseOptions?: Apollo.MutationHookOptions<PredictCodeMutation, PredictCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PredictCodeMutation, PredictCodeMutationVariables>(PredictCodeDocument, options);
      }
export type PredictCodeMutationHookResult = ReturnType<typeof usePredictCodeMutation>;
export type PredictCodeMutationResult = Apollo.MutationResult<PredictCodeMutation>;
export type PredictCodeMutationOptions = Apollo.BaseMutationOptions<PredictCodeMutation, PredictCodeMutationVariables>;
export const PredictChatDocument = gql`
    mutation PredictChat($question: String!) {
  predictChat(question: $question)
}
    `;
export type PredictChatMutationFn = Apollo.MutationFunction<PredictChatMutation, PredictChatMutationVariables>;

/**
 * __usePredictChatMutation__
 *
 * To run a mutation, you first call `usePredictChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePredictChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [predictChatMutation, { data, loading, error }] = usePredictChatMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function usePredictChatMutation(baseOptions?: Apollo.MutationHookOptions<PredictChatMutation, PredictChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PredictChatMutation, PredictChatMutationVariables>(PredictChatDocument, options);
      }
export type PredictChatMutationHookResult = ReturnType<typeof usePredictChatMutation>;
export type PredictChatMutationResult = Apollo.MutationResult<PredictChatMutation>;
export type PredictChatMutationOptions = Apollo.BaseMutationOptions<PredictChatMutation, PredictChatMutationVariables>;
export const UpdateOnePromptPresetDocument = gql`
    mutation UpdateOnePromptPreset($data: PromptPresetUpdateInput!, $id: String!) {
  updateOnePromptPreset(data: $data, where: {id: $id}) {
    ...PromptPresetInfo
  }
}
    ${PromptPresetInfoFragmentDoc}`;
export type UpdateOnePromptPresetMutationFn = Apollo.MutationFunction<UpdateOnePromptPresetMutation, UpdateOnePromptPresetMutationVariables>;

/**
 * __useUpdateOnePromptPresetMutation__
 *
 * To run a mutation, you first call `useUpdateOnePromptPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePromptPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnePromptPresetMutation, { data, loading, error }] = useUpdateOnePromptPresetMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOnePromptPresetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnePromptPresetMutation, UpdateOnePromptPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnePromptPresetMutation, UpdateOnePromptPresetMutationVariables>(UpdateOnePromptPresetDocument, options);
      }
export type UpdateOnePromptPresetMutationHookResult = ReturnType<typeof useUpdateOnePromptPresetMutation>;
export type UpdateOnePromptPresetMutationResult = Apollo.MutationResult<UpdateOnePromptPresetMutation>;
export type UpdateOnePromptPresetMutationOptions = Apollo.BaseMutationOptions<UpdateOnePromptPresetMutation, UpdateOnePromptPresetMutationVariables>;
export const UserDocument = gql`
    query user($where: UserWhereUniqueInput!) {
  user(where: $where) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateOneUserDocument = gql`
    mutation createOneUser($data: UserCreateInput!) {
  createOneUser(data: $data) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type CreateOneUserMutationFn = Apollo.MutationFunction<CreateOneUserMutation, CreateOneUserMutationVariables>;

/**
 * __useCreateOneUserMutation__
 *
 * To run a mutation, you first call `useCreateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserMutation, { data, loading, error }] = useCreateOneUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneUserMutation, CreateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneUserMutation, CreateOneUserMutationVariables>(CreateOneUserDocument, options);
      }
export type CreateOneUserMutationHookResult = ReturnType<typeof useCreateOneUserMutation>;
export type CreateOneUserMutationResult = Apollo.MutationResult<CreateOneUserMutation>;
export type CreateOneUserMutationOptions = Apollo.BaseMutationOptions<CreateOneUserMutation, CreateOneUserMutationVariables>;
export const UpdateOneUserDocument = gql`
    mutation updateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateOneUser(data: $data, where: $where) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<UpdateOneUserMutation, UpdateOneUserMutationVariables>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument, options);
      }
export type UpdateOneUserMutationHookResult = ReturnType<typeof useUpdateOneUserMutation>;
export type UpdateOneUserMutationResult = Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>;
export const DeleteOneUserDocument = gql`
    mutation deleteOneUser($where: UserWhereUniqueInput!) {
  deleteOneUser(where: $where) {
    id
  }
}
    `;
export type DeleteOneUserMutationFn = Apollo.MutationFunction<DeleteOneUserMutation, DeleteOneUserMutationVariables>;

/**
 * __useDeleteOneUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneUserMutation, { data, loading, error }] = useDeleteOneUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneUserMutation, DeleteOneUserMutationVariables>(DeleteOneUserDocument, options);
      }
export type DeleteOneUserMutationHookResult = ReturnType<typeof useDeleteOneUserMutation>;
export type DeleteOneUserMutationResult = Apollo.MutationResult<DeleteOneUserMutation>;
export type DeleteOneUserMutationOptions = Apollo.BaseMutationOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>;