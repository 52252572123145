import React from 'react';
import { Box, Button, Divider, Grid, LinearProgress, MenuItem, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';

import { Markdown } from '../../look';

type Data = {
  model: string;
  text: string;
};

const init: Data = {
  model: '',
  text: '',
};

const models = ['1st model', 'toy shop', 'fozzy', 'toys'];

export function Search() {
  const [data, setData] = React.useState<Data>(init);
  const [loading, setLoading] = React.useState(false);
  const [answers, setAnswers] = React.useState('');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post('http://localhost:5005/query/predict', data)
      .then((response) => {
        setAnswers(JSON.stringify(response.data, null, 2));
        return response;
      })
      .catch((error) => {
        if (error.response.data.detail) {
          alert(error.response.data.detail);
        } else {
          alert(error.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (field: keyof Data) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => ({ ...prevState, [field]: event.target.value }));
  };

  return (
    <Paper elevation={3} sx={{ p: 3, m: 2 }}>
      <Grid
        container
        component="form"
        spacing={3}
        sx={{ width: '100%', p: 2, position: 'relative' }}
        onSubmit={handleSubmit}
      >
        <Grid item xs={12}>
          <Typography id="input-slider" variant="h4">
            {' '}
            Search{' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            multiline
            value={data.text}
            onChange={handleChange('text')}
            placeholder="Enter some text..."
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            label="Choose model"
            value={data.model}
            onChange={handleChange('model')}
            type="number"
          >
            {models.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <Button sx={{ height: '100%', width: '100%' }} variant="outlined" type="submit" disabled={loading}>
            SEARCH
          </Button>
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Markdown>{'```json\n{text}\n```'.replace('{text}', answers)}</Markdown>
        </Grid>
      </Grid>
    </Paper>
  );
}
