/* eslint-disable no-param-reassign */
import { Action, bindActionCreators, createSlice, dispatch, ReducerCase, ReducerCases } from '@wpa/redux-utils';

import { ViewState /* , Fallback */ } from '../../common';

// function setFallback(key: string, fallback: Fallback) {
//   sessionStorage.setItem(key, JSON.stringify(fallback));
// }
//
// function getSavedValueFromLSAndSetFallback(key: string, fallback: Fallback) {
//   try {
//     const storageItem = sessionStorage.getItem(key);
//     let result = fallback;
//
//     if (storageItem !== null) {
//       result = JSON.parse(storageItem);
//     } else {
//       setFallback(key, fallback);
//     }
//     return result;
//   } catch (e) {
//     setFallback(key, fallback);
//     return fallback;
//   }
// }

export const initialState = {
  miniSidenav: true,
  transparentSidenav: false,
  sidenavColor: 'info',
  transparentNavbar: true,
  fixedNavbar: true,
  direction: 'ltr',
};

type Cases<S> = {
  setMiniSidenav: ReducerCase<S, Action<boolean>>;
  setTransparentSidenav: ReducerCase<S, Action<boolean>>;
  setSidenavColor: ReducerCase<S, Action<ViewState['sidenavColor']>>;
  setTransparentNavbar: ReducerCase<S, Action<boolean>>;
  setFixedNavbar: ReducerCase<S, Action<boolean>>;
  setDirection: ReducerCase<S, Action<string>>;
};

const { actions, reducer } = createSlice<ViewState, ReducerCases<ViewState, Action<any>, Cases<ViewState>>>({
  name: '@app',
  withActionPrefix: false,
  reducerCases: {
    setMiniSidenav: (draft, { payload }) => {
      draft.miniSidenav = payload;
    },
    setTransparentSidenav: (draft, { payload }) => {
      draft.transparentSidenav = payload;
    },
    setSidenavColor: (draft, { payload }) => {
      draft.sidenavColor = payload;
    },
    setTransparentNavbar: (draft, { payload }) => {
      draft.transparentNavbar = payload;
    },
    setFixedNavbar: (draft, { payload }) => {
      draft.fixedNavbar = payload;
    },
    setDirection: (draft, { payload }) => {
      draft.direction = payload;
    },
  },
});

const { setMiniSidenav, setTransparentSidenav, setSidenavColor, setTransparentNavbar, setFixedNavbar, setDirection } =
  bindActionCreators<typeof actions>(actions, dispatch);

export {
  reducer as rootReducer,
  setDirection,
  setFixedNavbar,
  setMiniSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setTransparentSidenav,
};
