import { useAuth0 } from '@auth0/auth0-react';

import { Maybe, UserInfoFragment, useUserQuery } from '@app/graphql/generated';

export function useCurrentUser(): Maybe<UserInfoFragment> {
  const { user } = useAuth0();
  const { loading, data, error } = useUserQuery({ variables: { where: { uid: user?.sub } } });

  if (loading || error) {
    return null;
  }

  return data?.user;
}
