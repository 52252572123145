import './modules/i18n';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Navigate, useRoutes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { withSuspense } from '@mdca/loadable';

import { AuthorizedApolloProvider } from './modules/core/components/AuthorizedApolloProvider';
import { AuthzErrorBoundary } from './modules/core/components/AuthzErrorBoundary';
import { AuthzProviderWithConfig } from './modules/core/components/AuthzProviderWithConfig';
import { ErrorBoundary } from './modules/core/components/ErrorBoundary';
import { SnackMessage } from './modules/core/components/SnackMessage/SnackMessage';
import { SseProvider } from './modules/core/components/SseProvider';
import { loadResourcesI18n } from './modules/i18n/loadResourcesI18n';
import { PageNotFound } from './modules/page-not-found';
import { ProtectedRoute } from './modules/router';
import { ThemeRootComponent } from './modules/theme';
import { routeSettings } from './routeSettings';

loadResourcesI18n();

const RootLayout = withSuspense(import(/* webpackChunkName: "RootLayout" */ './modules/layout'));
const Profile = withSuspense(import(/* webpackChunkName: "Profile" */ './modules/user/containers/Profile'));
const Users = withSuspense(import(/* webpackChunkName: "Users" */ './modules/user/containers/Users'));
// const Security = withSuspense(import(/* webpackChunkName: "Security" */ './modules/user/containers/Security'));
// const Dashboard = withSuspense(import(/* webpackChunkName: "Dashboard" */ './modules/user/containers/Dashboard'));
const Prompt = withSuspense(import(/* webpackChunkName: "Prompt" */ './modules/prompt/containers/Prompt'));
const Presets = withSuspense(import(/* webpackChunkName: "Presets" */ './modules/prompt/containers/Presets'));
const PromptHistory = withSuspense(
  import(/* webpackChunkName: "PromptHistory" */ './modules/prompt/containers/PromptHistory'),
);

const mainRoutes = {
  path: '/',
  element: <ProtectedRoute component={RootLayout} />,
  children: [
    {
      path: routeSettings.prompt.path,
      element: <ProtectedRoute component={Prompt} allowedRoles={routeSettings.prompt.allowedRoles} />,
    },
    {
      path: '/',
      element: <Navigate to="/prompt" />,
    },
    {
      path: routeSettings.history.path,
      element: <ProtectedRoute component={PromptHistory} allowedRoles={routeSettings.history.allowedRoles} />,
    },
    {
      path: routeSettings.presets.path,
      element: <ProtectedRoute component={Presets} allowedRoles={routeSettings.presets.allowedRoles} />,
    },
    {
      path: routeSettings.users.path,
      element: <ProtectedRoute component={Users} allowedRoles={routeSettings.users.allowedRoles} />,
    },
    {
      path: routeSettings.profile.path,
      element: <ProtectedRoute component={Profile} />,
    },
    // {
    //   path: '/security',
    //   element: <Security />,
    // },
    // {
    //   path: '/',
    //   element: <Dashboard />,
    // },
    {
      path: '*',
      element: <ProtectedRoute component={PageNotFound} />,
    },
  ],
};

function Routes() {
  return useRoutes([mainRoutes]);
}

const snackbarContent = (snackbarProviderRef: React.RefObject<SnackbarProvider>) =>
  function callback(key: string | number, message: string | React.ReactNode) {
    return <SnackMessage id={key} message={message} snackbarProviderRef={snackbarProviderRef} />;
  };

export function App() {
  const notistackRef = React.useRef<SnackbarProvider>(null);

  return (
    <ErrorBoundary>
      <CookiesProvider>
        <ThemeRootComponent>
          <SnackbarProvider ref={notistackRef} autoHideDuration={10_000} content={snackbarContent(notistackRef)}>
            <BrowserRouter>
              <AuthzProviderWithConfig>
                <AuthzErrorBoundary>
                  <SseProvider>
                    <AuthorizedApolloProvider>
                      <Routes />
                    </AuthorizedApolloProvider>
                  </SseProvider>
                </AuthzErrorBoundary>
              </AuthzProviderWithConfig>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeRootComponent>
      </CookiesProvider>
    </ErrorBoundary>
  );
}
