import React from 'react';
import TableCell from '@mui/material/TableCell';

import { MdTypography } from '../../look';

type RenderProps = {
  value?: string | number | Date;
};

export function RenderPromptField({ value = '' }: RenderProps) {
  return (
    <TableCell>
      <MdTypography>{value}</MdTypography>
    </TableCell>
  );
}
