/* eslint-disable no-param-reassign */
import React from 'react';
import delay from 'delay';

export const pMinDelay = async (
  promise: Promise<{ default: React.ComponentType<any> }>,
  minimumDelay: number,
  options?: { delayRejection: boolean },
) => {
  options = { delayRejection: true, ...options };

  let promiseError;

  if (options.delayRejection) {
    // @ts-ignore
    promise = promise.catch((error) => {
      promiseError = error;
    });
  }

  const value = await Promise.all([promise, delay(minimumDelay)]);

  return promiseError ? Promise.reject(promiseError) : value[0];
};
