import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { app } from '@mdca/config';

export function Logo({ withText }: { withText?: boolean }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box component="img" src={app.logo} alt={withText ? '' : app.name} sx={{ m: 1, width: '60px' }} />
      {withText && (
        <Typography variant="h3" noWrap component="div" sx={{ fontFamily: 'Poppins', lineHeight: 1.3 }}>
          {app.name}
        </Typography>
      )}
    </Box>
  );
}
