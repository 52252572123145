import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { PromptPresetInfoFragment } from '@app/graphql/generated';
import { Label } from '@app/modules/prompt/components/Label';
import { SettingItem } from '@app/modules/prompt/components/SettingItem';
import { FormPrompt } from '@app/modules/prompt/types';

import { Dialog, MdTypography } from '../../look';

const filter = createFilterOptions<string>();

type Props = {
  open: boolean;
  handleClose: () => void;
  preset: PromptPresetInfoFragment;
  handlePresetUpdate: (presetUpdate: PromptPresetInfoFragment) => void;
};

const prepareStopSequences = (stop: string) => (stop === '' ? [] : stop.split(','));

export function PresetEditDialog(props: Props) {
  const { open, handleClose, preset, handlePresetUpdate } = props;
  const { t } = useTranslation('prompt');
  const [editPreset, setEditPreset] = React.useState<PromptPresetInfoFragment>(preset);
  const [editPromptStop, setEditPromptStop] = React.useState<Array<string>>(prepareStopSequences(preset.prompt.stop));

  const handleChange = React.useCallback(
    (field: keyof PromptPresetInfoFragment) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditPreset((prev) => ({ ...prev, [field]: event.target.value }));
    },
    [],
  );

  const handlePromptTextChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEditPreset((prev) => ({ ...prev, prompt: { ...prev.prompt, prompt: event.target.value } }));
  }, []);

  const handlePromptChange = React.useCallback(
    (field: keyof FormPrompt) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditPreset((prev) => ({ ...prev, prompt: { ...prev.prompt, [field]: Number(event.target.value) } }));
    },
    [],
  );

  const handlePromptSliderChange = React.useCallback(
    (field: keyof FormPrompt) => (_event: Event, value: number | Array<number>) => {
      setEditPreset((prev) => ({ ...prev, prompt: { ...prev.prompt, [field]: value } }));
    },
    [],
  );

  const handleSubmit = React.useCallback(() => {
    handlePresetUpdate({ ...editPreset, prompt: { ...editPreset.prompt, stop: editPromptStop.join(',') } });
  }, [editPreset, editPromptStop, handlePresetUpdate]);

  return (
    <Dialog
      title={t('preset.editPreset')}
      actions={<Button onClick={handleSubmit}>{t('playground.save')}</Button>}
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Stack spacing={3}>
        <TextField
          id="name"
          name="name"
          fullWidth
          required
          label={t('playground.saveDialog.name')}
          value={editPreset.name}
          onChange={handleChange('name')}
          inputProps={{ maxlength: '50' }}
        />
        <TextField
          id="description"
          name="description"
          fullWidth
          label={t('playground.saveDialog.description')}
          value={editPreset.description || ''}
          onChange={handleChange('description')}
          multiline
          inputProps={{ maxlength: '500', sx: { minHeight: '70px' } }}
        />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <MdTypography>{t('preset.editPrompt')}</MdTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  id="prompt"
                  name="prompt"
                  fullWidth
                  label={t('playground.title')}
                  value={editPreset.prompt.prompt}
                  onChange={handlePromptTextChange}
                  multiline
                  inputProps={{ maxlength: '500', sx: { minHeight: '70px' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <Label label={t('playground.stopSequences')} description={t('playground.stopSequencesInfo')} />
                <Autocomplete
                  id="stopSequences"
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  multiple
                  fullWidth
                  value={editPromptStop}
                  options={editPromptStop}
                  onChange={(_, values) => {
                    setEditPromptStop(values);
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.includes(inputValue);
                    if (inputValue !== '' && !isExisting) {
                      options.push(inputValue);
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(renderProps, option) => <li {...renderProps}> {option} </li>}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              </Grid>
              <SettingItem
                id="temperature"
                name="temperature"
                value={editPreset.prompt.temperature}
                label={t('playground.temperature')}
                description={t('playground.temperatureInfo')}
                min={0}
                max={1}
                step={0.01}
                handleSliderChange={handlePromptSliderChange('temperature')}
                onChange={handlePromptChange('temperature')}
              />
              <SettingItem
                id="maxTokens"
                name="maxTokens"
                value={editPreset.prompt.maxTokens}
                label={t('playground.maxLength')}
                description={t('playground.maxLengthInfo')}
                min={1}
                max={4000}
                step={1}
                handleSliderChange={handlePromptSliderChange('maxTokens')}
                onChange={handlePromptChange('maxTokens')}
              />
              <SettingItem
                id="topP"
                name="topP"
                value={editPreset.prompt.topP}
                label={t('playground.topP')}
                description={t('playground.topPInfo')}
                min={0}
                max={1}
                step={0.01}
                handleSliderChange={handlePromptSliderChange('topP')}
                onChange={handlePromptChange('topP')}
              />
              <SettingItem
                id="frequencyPenalty"
                name="frequencyPenalty"
                value={editPreset.prompt.frequencyPenalty}
                label={t('playground.frequencyPenalty')}
                description={t('playground.frequencyPenaltyInfo')}
                min={0}
                max={2}
                step={0.01}
                handleSliderChange={handlePromptSliderChange('frequencyPenalty')}
                onChange={handlePromptChange('frequencyPenalty')}
              />
              <SettingItem
                id="presencePenalty"
                name="presencePenalty"
                value={editPreset.prompt.presencePenalty}
                label={t('playground.presencePenalty')}
                description={t('playground.presencePenaltyInfo')}
                min={0}
                max={2}
                step={0.01}
                handleSliderChange={handlePromptSliderChange('presencePenalty')}
                onChange={handlePromptChange('presencePenalty')}
              />
              <SettingItem
                id="bestOf"
                name="bestOf"
                value={editPreset.prompt.bestOf}
                label={t('playground.bestOf')}
                description={t('playground.bestOfInfo')}
                min={1}
                max={20}
                step={1}
                handleSliderChange={handlePromptSliderChange('bestOf')}
                onChange={handlePromptChange('bestOf')}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Dialog>
  );
}
