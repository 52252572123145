import React from 'react';
import { timeout } from 'promise-timeout';

import { LinearProgress } from './LinearProgress';
import { pMinDelay } from './pMinDelay';
import { Spinner } from './Spinner';

type DynamicImport = Promise<{ default: React.ComponentType<any> }>;
type FallbackPosition = 'fixed' | 'absolute';
type FallbackProps = { fallbackSize: number; fallbackPosition: FallbackPosition };

function Fallback({ fallbackSize = 40, fallbackPosition = 'absolute' }: FallbackProps) {
  return <Spinner size={fallbackSize} {...(fallbackPosition ? { [fallbackPosition]: true } : {})} />;
}

const wrapTimers = (dynamicImport: DynamicImport, viewDelay: number, errorTimeout: number) =>
  timeout(pMinDelay(dynamicImport, viewDelay), viewDelay ? errorTimeout + viewDelay : errorTimeout);

type LoadableProps = {
  viewDelay?: number;
  errorTimeout?: number;
  fallback?: React.ReactElement;
};

const withSuspense = (dynamicImport: DynamicImport, options: LoadableProps = {}) => {
  const { fallback, viewDelay = 0, errorTimeout = 10_000 } = options;

  const Component = React.lazy(() => wrapTimers(dynamicImport, viewDelay, errorTimeout));

  return function ComponentWrapper(innerProps: Record<string, any>) {
    return (
      <React.Suspense fallback={fallback || <LinearProgress />}>
        <Component {...innerProps} />
      </React.Suspense>
    );
  };
};

export { Fallback, withSuspense };

export { LinearProgress } from './LinearProgress';
export { Spinner } from './Spinner';
