import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { app } from '@mdca/config';

import { Layout } from '../../look';
import { AccountProfile } from '../components/Account/AccountProfile';

export default function Profile() {
  const { t } = useTranslation('user');

  return (
    <Layout>
      <Helmet
        title={`${app.name} - ${t('profile.title')}`}
        meta={[{ name: 'description', content: `${app.name} - ${t('profile.meta')}` }]}
      />
      <AccountProfile />
    </Layout>
  );
}
