import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PromptInfoExtendsFragment } from '@app/graphql/generated';

import { MdScrollbar } from '../../look';

import { PromptRow } from './PromptRow';

type Props = { prompts: Array<PromptInfoExtendsFragment> };

export function PromptsTable(props: Props) {
  const { prompts } = props;
  const { t } = useTranslation('prompt');

  return (
    <Stack spacing={3} justifyContent="space-between" height="100%">
      <TableContainer sx={{ height: '100%' }}>
        <MdScrollbar width={6} height="calc(100% - 80px)" top={80} right={1}>
          <Table aria-label="prompt table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('playground.title')}</TableCell>
                <TableCell>{t('playground.promptResponse')}</TableCell>
                <TableCell>{t('playground.stopSequences')}</TableCell>
                <TableCell>{t('playground.temperature')}</TableCell>
                <TableCell>{t('playground.maxLength')}</TableCell>
                <TableCell>{t('playground.topP')}</TableCell>
                <TableCell>{t('playground.frequencyPenalty')}</TableCell>
                <TableCell>{t('playground.presencePenalty')}</TableCell>
                <TableCell>{t('playground.bestOf')}</TableCell>
                <TableCell>{t('playground.tokens.title')}</TableCell>
                <TableCell>{t('playground.created')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prompts.map((prompt) => (
                <PromptRow key={prompt.id} prompt={prompt} />
              ))}
              {prompts.length === 0 && (
                <TableRow>
                  <TableCell>{t('history.noData')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MdScrollbar>
      </TableContainer>
    </Stack>
  );
}
