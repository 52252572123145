import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { NODE_VARS } from '@mdca/config';

import { AppRouteTypeEnum } from '@app/enums';
import { AppRoute } from '@app/types';

import { ViewState } from '../../../common';
import { NavLink } from '../../material/NavLink';
import { MdBox } from '../../override-components/MdBox';
import { MdTypography } from '../../override-components/MdTypography';
import { mergeSx } from '../../utils';
import { Logo } from '../Logo/Logo';
import { MdScrollbar } from '../MdScrollBar/MdScrollBar';

import { sidenavLogoLabel } from './styles/sidenav';
import { SidenavCollapse } from './SidenavCollapse';
import { SidenavRoot } from './SidenavRoot';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  orderBy: Key,
  order: Order = 'asc',
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type Props = {
  // eslint-disable-next-line react/require-default-props
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  brandName: string;
  routes: Array<AppRoute>;
  miniSidenav: ViewState['miniSidenav'];
  setMiniSidenav: (newState: boolean) => void;
};

export function Sidenav(props: Props) {
  const { color = 'info', brandName, routes, miniSidenav, setMiniSidenav } = props;

  const { user } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();

  const collapseName = location.pathname.split('/').slice(1)[0] || '/';

  const closeSidenav = () => setMiniSidenav(true);

  return (
    <SidenavRoot variant="permanent" ownerState={{ miniSidenav }}>
      <MdBox pt={2} px={1} textAlign="center">
        <MdBox
          onClick={closeSidenav}
          display={{ xs: 'block', lg: 'none' }}
          sx={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0, padding: 1.625 }}
        >
          <MdTypography variant="h6" color="text">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MdTypography>
        </MdBox>
        <MdBox component={NavLink} to="/" display="flex" alignItems="center">
          <MdBox sx={mergeSx(sidenavLogoLabel, { display: 'flex', alignItems: 'center', margin: '0 auto' })}>
            <MdBox display="flex" sx={mergeSx(sidenavLogoLabel, { mr: miniSidenav ? 0 : 1 })}>
              <Logo />
            </MdBox>
            <MdTypography
              sx={mergeSx(sidenavLogoLabel, {
                opacity: miniSidenav ? 0 : 1,
                maxWidth: miniSidenav ? 0 : '100%',
                margin: '0 auto',
              })}
            >
              {brandName}
            </MdTypography>
          </MdBox>
        </MdBox>
      </MdBox>
      <Divider />
      <MdScrollbar width={3} height="calc(100% - 30px)" top={1} noScrollX>
        <List>
          {routes.sort(getComparator('index')).map((appRoute) => {
            const { type } = appRoute;
            const roles = user ? user[`${NODE_VARS.AUTH0_AUDIENCE}/roles`] : [];

            switch (type) {
              case AppRouteTypeEnum.NAVIGATION: {
                const { key, path, nameTKey, iconElement, allowedRoles, handleHasRole } = appRoute;

                const isAccessAllowed = handleHasRole(allowedRoles, roles);

                return isAccessAllowed ? (
                  <NavLink key={key} to={path}>
                    <SidenavCollapse
                      color={color}
                      name={<Typography>{t(nameTKey)}</Typography>}
                      icon={iconElement}
                      active={key === collapseName}
                      miniSidenav={miniSidenav}
                    />
                  </NavLink>
                ) : null;
              }
              case AppRouteTypeEnum.COLLAPSE: {
                const { key, href, nameTKey, iconElement, allowedRoles, handleHasRole } = appRoute;

                const isAccessAllowed = handleHasRole(allowedRoles, roles);

                return isAccessAllowed ? (
                  <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                    <SidenavCollapse
                      color={color}
                      name={<Typography>{t(nameTKey)}</Typography>}
                      icon={iconElement}
                      active={key === collapseName}
                      miniSidenav={miniSidenav}
                    />
                  </Link>
                ) : null;
              }
              // case AppRouteTypeEnum.TITLE: {
              //   const { key, titleTKey } = appRoute;
              //
              //   return (
              //     <MdTypography
              //       key={key}
              //       color="white"
              //       display="block"
              //       variant="caption"
              //       fontWeight="bold"
              //       textTransform="uppercase"
              //       pl={3}
              //       mt={2}
              //       mb={1}
              //       ml={1}
              //     >
              //       {titleTKey}
              //     </MdTypography>
              //   );
              // }
              // case AppRouteTypeEnum.DIVIDER: {
              //   const { key } = appRoute;
              //
              //   return <Divider key={key} />;
              // }
              default:
                return null;
            }
          })}
        </List>
      </MdScrollbar>
    </SidenavRoot>
  );
}
