import React from 'react';

import { createUseReducer } from '@wpa/redux-utils';

import { ViewState } from '../../common';

import { initialState, rootReducer } from './themeSlice';

type Props = {
  children: (state: ViewState) => React.ReactElement;
};

const useReducer = createUseReducer<ViewState>('view', rootReducer, { cache: false });

export function Store({ children }: Props) {
  const [state] = useReducer(initialState as ViewState);

  // if (process.env.NODE_ENV === 'development') {
  //   console.log('state', state);
  // }

  return children(state);
}
