import React, { memo, useEffect } from 'react';
import { Accept } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { LoadingBackdrop } from '../LoadingBackdrop';

import { useFileReader } from './hooks/useFileReader';
import { BaseUploader } from './BaseUploader';
import { FileReaderMethods } from './file-reader-method.enum';

type Props = {
  noDrag?: boolean;
  disabled?: boolean;
  minSize?: number;
  maxSize?: number;
  accept: Accept;
  uploadFiles: (files: File[]) => void;
};

const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024;

export const Uploader = memo((props: React.PropsWithChildren<Props>) => {
  const { uploadFiles, children, accept, minSize = 1, maxSize = DEFAULT_MAX_FILE_SIZE, ...other } = props;
  const { t } = useTranslation('dataImport');
  const { enqueueSnackbar } = useSnackbar();

  const [{ result, error, loading, file }, fileSetter, fileReject, reset] = useFileReader({
    method: FileReaderMethods.readAsArrayBuffer,
    minSize,
    maxSize,
    accept,
    errors: {
      defaultError: t('document_Error_File_Format'),
      fileFormat: t('document_Error_File_Format'),
      minSize: t('document_Error_File_Size_Zero'),
      maxSize: t('document_Error_File_Size_Exceeded'),
    },
  });

  useEffect(() => {
    if (!error) {
      if (file && result) {
        uploadFiles([file]);
        reset();
      }
    } else {
      enqueueSnackbar(error.message, { variant: 'error' });
      reset();
    }
  }, [file, result, error, reset, uploadFiles, enqueueSnackbar]);

  return (
    <BaseUploader onDrop={fileSetter} onDropRejected={fileReject} accept={accept} {...other}>
      <>
        {children}
        <LoadingBackdrop loading={loading} />
      </>
    </BaseUploader>
  );
});
