import { Prompt, PromptInfoFragment } from '@app/graphql/generated';

import { FormPrompt } from '../types';

export function promptToFormPromptMapper(prompt: Prompt | PromptInfoFragment): FormPrompt {
  const stop = prompt.stop.split(',')[0] === '' ? [] : prompt.stop.split(',');
  const variables = prompt?.variables || {};

  return {
    // TODO temp fix
    stop,
    prompt: prompt.prompt,
    bestOf: prompt.bestOf,
    frequencyPenalty: prompt.frequencyPenalty,
    maxTokens: prompt.maxTokens,
    presencePenalty: prompt.presencePenalty,
    topP: prompt.topP,
    temperature: prompt.temperature,
    source: prompt.source,
    variables,
  };
}
