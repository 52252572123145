import React from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';

import { NODE_VARS } from '@mdca/config';

export function AuthorizedApolloProvider({ children }: React.PropsWithChildren<any>) {
  const { getAccessTokenSilently } = useAuth0();

  const client = React.useMemo(() => {
    const port = process.env.IS_DEV ? `:${NODE_VARS.SERVER_PORT}` : '';
    const graphqlGateway = `${window.location.protocol}//${window.location.hostname}${port}${process.env.API_URL}`;

    const httpLink = createHttpLink({ uri: graphqlGateway });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      }
      if (networkError) {
        console.error(`[Network error]:`, networkError);
      }
    });

    const authLink = setContext(async (_, { headers /* , ...context */ }) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        // ...context, // TODO check this
      };
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
    // TODO check this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
