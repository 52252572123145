import React, { memo } from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';

type Props = {
  noDrag?: boolean;
  disabled?: boolean;
  minSize?: number;
  maxSize?: number;
  accept?: Accept;
  onDrop?: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
  children?: React.ReactChild;
};

export const BaseUploader = memo((props: Props) => {
  const { children, accept, noDrag = false, disabled = false, minSize, maxSize, onDrop, onDropRejected } = props;

  const { getRootProps, getInputProps } = useDropzone({
    minSize,
    maxSize,
    noDrag,
    disabled,
    accept,
    onDrop,
    onDropRejected,
  });

  return (
    <Box sx={{ p: 2, border: '1px dashed grey' }} {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps({ multiple: false })} />
      {children}
    </Box>
  );
});
