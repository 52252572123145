import React from 'react';
import { useTranslation } from 'react-i18next';
import GPT3Tokenizer from 'gpt3-tokenizer';

import { useDebounce } from '@app/hooks/useDebounce';

import { TokenModel } from '../types';

import { Label } from './Label';

type Props = {
  prompt: string;
  promptResponse: string;
  tokenModel?: TokenModel;
};

export function TokensCounter(props: Props) {
  const { prompt, promptResponse, tokenModel = TokenModel.gpt3 } = props;
  const { t } = useTranslation('prompt');
  const [isCalculating, setIsCalculating] = React.useState<boolean>(false);
  const [tokensCount, setTokensCount] = React.useState<number>(0);
  const [response, setResponse] = React.useState<string>(promptResponse);

  const debouncedPromptTerm = useDebounce(prompt, 500);

  React.useEffect(() => {
    setResponse(promptResponse);
  }, [promptResponse]);

  React.useEffect(() => {
    setResponse('');
  }, [prompt]);

  React.useEffect(() => {
    if (debouncedPromptTerm) {
      let textToCount = prompt.slice(0, -1);

      if (response) {
        textToCount += response;
      }

      setIsCalculating(true);
      try {
        const tokenizer = new GPT3Tokenizer({ type: tokenModel === TokenModel.gpt3 ? 'gpt3' : 'codex' });
        const promptEncoded = tokenizer.encode(textToCount);

        setTokensCount(promptEncoded.bpe.length);
      } catch {
        setTokensCount(0);
      }
      setIsCalculating(false);
    } else {
      setTokensCount(0);
    }
  }, [debouncedPromptTerm, response]);

  const tokensViewLabel = () => `${t('playground.tokens.title')}: ${isCalculating ? 'Calculating...' : tokensCount}`;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '5px',
        marginRight: '10px',
      }}
    >
      <Label label={tokensViewLabel()} description={t('playground.tokens.description')} />
    </div>
  );
}
