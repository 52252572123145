import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { Maybe } from '@mdca/shared-common';

import { CreateOnePromptPresetMutation, PromptPreset } from '@app/graphql/generated';

import { Dialog, MdTypography } from '../../look';
import { presetShareLink } from '../helper';
import { FormPreset } from '../types';

type Props = {
  presets: Array<PromptPreset>;
  currentPreset: PromptPreset | null;
  handlePresetSave: (preset: FormPreset) => Promise<Maybe<CreateOnePromptPresetMutation>>;
  loading: boolean;
};

export function SharePresetDialog(props: Props) {
  const { presets, currentPreset, loading, handlePresetSave } = props;
  const { t } = useTranslation('prompt');
  const [open, setOpen] = React.useState(false);
  const [newPreset, setNewPreset] = React.useState<FormPreset>({
    name: currentPreset?.name ?? '',
    description: currentPreset?.description ?? '',
  });
  const [nameExist, setNameExist] = React.useState<string | null>(null);
  const clipboard = useClipboard();

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleShare = React.useCallback(() => {
    if (currentPreset) {
      clipboard.copy(presetShareLink(currentPreset.id));
    }
    handleClose();
    enqueueSnackbar(t('playground.shareDialog.copied'), { variant: 'success' });
  }, [clipboard, enqueueSnackbar, handleClose, currentPreset, t]);

  const handleCopyId = React.useCallback(() => {
    if (currentPreset) {
      clipboard.copy(currentPreset.id);
    }
    handleClose();
    enqueueSnackbar(t('playground.shareDialog.copiedId'), { variant: 'success' });
  }, [clipboard, enqueueSnackbar, handleClose, currentPreset, t]);

  const handleSubmit = () => {
    if (newPreset.name !== '') {
      const presetExist = presets.find((preset) => preset.name === newPreset.name);
      if (presetExist) {
        setNameExist(t('playground.saveDialog.nameExist'));
      } else {
        handlePresetSave({ name: newPreset.name, description: newPreset.description })
          .then(() => handleClose())
          .catch(() => handleClose());
      }
    } else {
      setNameExist(t('playground.saveDialog.nameEmpty'));
    }
  };

  const handleChange = React.useCallback(
    (field: keyof FormPreset) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPreset((prev) => ({ ...prev, [field]: event.target.value }));
    },
    [],
  );

  return (
    <Box>
      <Button onClick={handleClickOpen}>{t('playground.share')}</Button>
      {currentPreset ? (
        <Dialog
          title={t('playground.shareDialog.title')}
          open={open}
          loading={loading}
          actions={
            <>
              <Button disabled={!currentPreset} onClick={handleCopyId}>
                {t('playground.shareDialog.copyId')}
              </Button>
              <Button disabled={!currentPreset} onClick={handleShare}>
                {t('playground.shareDialog.copy')}
              </Button>
            </>
          }
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Stack spacing={3}>
            <MdTypography>{t('playground.shareDialog.helperText')}</MdTypography>
            <Paper elevation={3} sx={{ p: 3 }}>
              <MdTypography>{presetShareLink(currentPreset.id)}</MdTypography>
            </Paper>
          </Stack>
        </Dialog>
      ) : (
        <Dialog
          loading={loading}
          title={t('playground.saveDialog.saveBeforeShare')}
          actions={
            <Button disabled={loading || newPreset.name === ''} onClick={handleSubmit}>
              {t('playground.save')}
            </Button>
          }
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Stack spacing={3}>
            <TextField
              id="name"
              name="name"
              fullWidth
              required
              label={t('playground.saveDialog.name')}
              value={newPreset.name}
              onChange={handleChange('name')}
              helperText={nameExist}
              error={!!nameExist}
              inputProps={{ maxLength: '50' }}
              onMouseEnter={() => setNameExist(null)}
            />
            <TextField
              id="description"
              name="description"
              fullWidth
              label={t('playground.saveDialog.description')}
              value={newPreset.description}
              onChange={handleChange('description')}
              multiline
              inputProps={{ maxLength: '500', sx: { minHeight: '70px' } }}
            />
          </Stack>
        </Dialog>
      )}
    </Box>
  );
}
