import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

type Props = {
  rotate: number;
  animationDelay: number;
  bgColor?: string;
  propsBg: string;
  bg: string;
};

const circular = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const Circle = styled.div<Props>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(${(p) => p.rotate}deg);

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    border-radius: 100%;
    background-color: ${(p) => p.propsBg || p.bg};
    animation: ${circular} 1.2s infinite ease-in-out both;
    animation-delay: ${(p) => p.animationDelay}s;
  }
`;
