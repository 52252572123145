import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// import { AccountProfileDetails } from './AccountProfileDetails';
// import { AvatarUpload } from './AvatarUpload';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { NODE_VARS } from '@mdca/config';

import { UserRole } from '@app/graphql/generated';
import { useCurrentUser } from '@app/hooks/useCurrentUser';
import { MdTypography, SecureInput } from '@app/modules/look';

function Field(props: { label: string; value?: string; children?: ReactElement<any> }) {
  const { label, value, children } = props;

  return (
    <Stack direction="row" spacing={1}>
      <MdTypography color="textSecondary" variant="body2">
        {label}:
      </MdTypography>
      {value ? (
        <MdTypography color="textSecondary" variant="body2">
          {value}
        </MdTypography>
      ) : (
        children
      )}
    </Stack>
  );
}

export function AccountProfile() {
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const clipboard = useClipboard();
  const currentUser = useCurrentUser();
  const { user } = useAuth0();

  const permittedRoles = React.useMemo(() => [UserRole.Admin, UserRole.SuperAdmin], []);

  const copyToClipboard = (text: string) => {
    clipboard.copy(text);
    enqueueSnackbar('Copying to clipboard was successful!', { variant: 'success' });
  };

  const handleSendChangePasswordEmail = () => {
    axios
      .post(
        `https://${NODE_VARS.AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          client_id: NODE_VARS.AUTH0_CLIENT_ID,
          email: user?.email,
          connection: 'Username-Password-Authentication',
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then((response) => {
        enqueueSnackbar(response.data, { variant: 'success' });

        return null;
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Request failed.', { variant: 'error' });
      });
  };

  const apiKey = currentUser?.project[0]?.apiKey;
  const showName = !!currentUser?.profile?.firstName || !!currentUser?.profile?.lastName;

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xs={12}>
        <Card>
          <CardContent>
            <Stack direction="column" spacing={2} justifyContent="flex-start" alignItems="center">
              {/* <AvatarUpload src={user?.picture} alt={currentUser?.username.charAt(0).toUpperCase() || 'avatar'} /> */}
              <Avatar
                sx={{ height: 64, width: 64 }}
                alt={currentUser?.username.charAt(0).toUpperCase() || 'avatar'}
                src={user?.picture}
              />
              {showName && (
                <MdTypography color="textPrimary" gutterBottom variant="h5">
                  {`${currentUser.profile?.firstName} ${currentUser.profile?.lastName}`}
                </MdTypography>
              )}
            </Stack>
            <Stack direction="column" divider={<Divider orientation="horizontal" flexItem />} spacing={2} mt={4}>
              <Field label={t('profile.card.group.email')} value={currentUser?.email} />
              {/* <Field label={t('profile.card.group.company')} value={currentUser?.profile?.companyName as string} /> */}
              {permittedRoles.includes(currentUser?.roles[0] as UserRole) && (
                <Field label={t('profile.card.group.role')} value={currentUser?.roles[0]} />
              )}
              {apiKey ? (
                <SecureInput
                  readOnly
                  fullWidth
                  id="apiKey"
                  name="apiKey"
                  // TODO translation
                  label={t('Api key')}
                  value={apiKey}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title="Copy apiKey">
                        <IconButton
                          aria-label="copy apiKey"
                          onClick={() => copyToClipboard(apiKey as unknown as string)}
                          edge="end"
                        >
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  autoComplete="apikey"
                  margin="normal"
                />
              ) : (
                <Field label={t('profile.card.group.apiKey')} value={t('profile.card.group.noApiKey')} />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Card>
          <CardContent>
            <Button onClick={handleSendChangePasswordEmail}>Change password</Button>
            <MdTypography>An email will be sent to you with instructions to change your password.</MdTypography>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item lg={8} md={6} xs={12}> */}
      {/*   <AccountProfileDetails withPassword={false} currentUser={currentUser} /> */}
      {/* </Grid> */}
    </Grid>
  );
}
