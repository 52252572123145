import React from 'react';
// import { useTranslation } from 'react-i18next';
// import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import {
  DataGrid,
  // GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  // GridRowId,
  // GridRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';

// import toDate from 'date-fns/toDate';
// import { keys, set } from 'lodash';
// import { useSnackbar } from 'notistack';
import { /* Maybe, */ User /* , UserRole */ } from '@app/graphql/generated';

import { /* Button,  */ DataGridToolbar as Toolbar } from '../../../look';

type Props = {
  loading: boolean;
  users: Array<User>;
};

// type MutationReason = {
//   reasonField: string;
//   alertMessage: string;
// };

// type PromiseArguments = {
//   resolve: (value: any) => void;
//   reject: (reason?: any) => void;
//   newRow: GridRowModel<User>;
//   oldRow: GridRowModel<User>;
//   mutations: Array<MutationReason>;
// };

// function computeMutations(newRow: GridRowModel<User>, oldRow: GridRowModel<User>): Maybe<Array<MutationReason>> {
//   const mutationReasons: Array<MutationReason> = [];
//
//   if (newRow.username !== oldRow.username) {
//     mutationReasons.push({
//       reasonField: 'username',
//       alertMessage: `Username from '${oldRow.username}' to '${newRow.username}'`,
//     });
//   }
//
//   if (newRow.email !== oldRow.email) {
//     mutationReasons.push({
//       reasonField: 'email',
//       alertMessage: `Email from '${oldRow.email}' to '${newRow.email}'`,
//     });
//   }
//
//   // if (newRow.role !== oldRow.role) {
//   //   mutationReasons.push({
//   //     reasonField: 'role',
//   //     alertMessage: `Role from '${oldRow.role}' to '${newRow.role}'`,
//   //   });
//   // }
//
//   if (newRow.isActive !== oldRow.isActive) {
//     mutationReasons.push({
//       reasonField: 'isActive',
//       alertMessage: `Active from '${oldRow.isActive}' to '${newRow.isActive}'`,
//     });
//   }
//
//   // if (
//   //   Number.isInteger(oldRow.subscriptions?.[0]?.meteredUsage) &&
//   //   newRow.requestsUsed !== oldRow.subscriptions?.[0]?.meteredUsage
//   // ) {
//   //   mutationReasons.push({
//   //     reasonField: 'requestsUsed',
//   //     alertMessage: `Requests Usage from '${oldRow.subscriptions?.[0]?.meteredUsage}' to '${newRow.requestsUsed}'`,
//   //   });
//   // }
//
//   if (mutationReasons.length > 0) {
//     return mutationReasons;
//   }
//
//   return null;
// }

// const mutableUserFields = new Set(['username', 'email', 'role', 'isActive']);
//
// function dateFormatter(date: number) {
//   const fullDate = date * 1000;
//
//   return toDate(fullDate);
// }

export function UsersListView(props: Props) {
  const { loading, users = [] } = props;
  // const { t } = useTranslation('user');
  // const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState<Array<User>>(users);
  // const [promiseArguments, setPromiseArguments] = React.useState<Maybe<PromiseArguments>>(null);
  // const noButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (Array.isArray(users) && users.length > 0) {
      setRows(users);
    }
  }, [users]);

  // const handleDeleteUser = React.useCallback(
  //   (id: GridRowId) => () => {
  //     setTimeout(() => {
  //       setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  //     });
  //   },
  //   [],
  // );

  const columns = React.useMemo<Array<GridColDef | GridActionsColDef>>(
    () => [
      {
        field: 'username',
        headerName: 'Username',
        type: 'string',
        width: 150,
        editable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        width: 150,
        editable: false,
      },
      {
        field: 'companyName',
        headerName: 'Company',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        editable: false,
        width: 160,
        valueGetter: (params: GridValueGetterParams<any, User>) => params.row.profile?.companyName || '',
      },
      {
        field: 'roles',
        headerName: 'Roles',
        type: 'string',
        width: 160,
        editable: false,
        sortable: true,
        valueGetter: (params: GridValueGetterParams<any, User>) => `[${params.row.roles.join(', ')}]` || '[]',
      },
      // {
      //   field: 'isActive',
      //   headerName: 'Email confirmed',
      //   type: 'boolean',
      //   sortable: true,
      //   editable: false,
      //   width: 120,
      // },
      // {
      //   field: 'plan',
      //   headerName: 'Current plan',
      //   type: 'string',
      //   sortable: true,
      //   editable: false,
      //   width: 150,
      //   valueGetter: (params: GridValueGetterParams<any, User>) =>
      //     params.row.subscriptions?.[0]?.plan?.name || 'no current plan',
      // },
      // {
      //   field: 'subscriptionStatus',
      //   headerName: 'Subscription status',
      //   type: 'string',
      //   sortable: true,
      //   editable: false,
      //   width: 150,
      //   valueGetter: (params: GridValueGetterParams<any, User>) =>
      //     params.row?.subscriptions?.[0]?.status || 'no subscription',
      // },
      // {
      //   field: 'requestsUsed',
      //   headerName: 'Requests used',
      //   type: 'number',
      //   sortable: true,
      //   editable: true,
      //   width: 150,
      //   valueGetter: (params: GridValueGetterParams<any, UserWithSubscriptions>) =>
      //     params.row?.subscriptions?.[0]?.meteredUsage || 0,
      // },
      // {
      //   field: 'planIncluded',
      //   headerName: 'Plan included',
      //   type: 'number',
      //   sortable: true,
      //   editable: false,
      //   width: 150,
      //   valueGetter: (params: GridValueGetterParams<any, UserWithSubscriptions>) =>
      //     params.row.subscriptions?.[0]?.plan?.included || 0,
      // },
      // {
      //   field: 'currentPeriodStart',
      //   headerName: 'Start date',
      //   type: 'dateTime',
      //   sortable: true,
      //   editable: false,
      //   width: 170,
      //   valueGetter: (params: GridValueGetterParams<any, UserWithSubscriptions>) => {
      //     const date = params.row.subscriptions?.[0]?.currentPeriodStart;
      //
      //     return date ? dateFormatter(date) : '-';
      //   },
      // },
      // {
      //   field: 'currentPeriodEnd',
      //   headerName: 'End date',
      //   type: 'dateTime',
      //   sortable: true,
      //   editable: false,
      //   width: 170,
      //   valueGetter: (params: GridValueGetterParams<any, UserWithSubscriptions>) => {
      //     const date = params.row.subscriptions?.[0]?.currentPeriodEnd;
      //
      //     return date ? dateFormatter(date) : '-';
      //   },
      // },
      // {
      //   field: 'actions',
      //   type: 'actions',
      //   headerName: 'Actions',
      //   width: 80,
      //   getActions: (params) => [
      //     <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteUser(params.id)} />,
      //   ],
      // },
    ],
    [],
  );

  // const processRowUpdate = React.useCallback(
  //   (newRow: GridRowModel<User>, oldRow: GridRowModel<User>) =>
  //     new Promise<GridRowModel>((resolve, reject) => {
  //       const mutations = computeMutations(newRow, oldRow);
  //
  //       if (mutations) {
  //         setPromiseArguments({ resolve, reject, newRow, oldRow, mutations });
  //       } else {
  //         resolve(oldRow);
  //       }
  //     }).catch((error) => error),
  //   [],
  // );

  // const handleNo = () => {
  //   const { oldRow, resolve } = promiseArguments;
  //   resolve(oldRow);
  //   setPromiseArguments(null);
  // };
  //
  // const handleYes = async () => {
  //   const { newRow, oldRow, reject, resolve, mutations } = promiseArguments;
  //
  //   const requestsUsed = mutations.find(({ reasonField }) => reasonField === 'requestsUsed');
  //   const userFieldsToMutate = mutations.reduce<Record<string, string | boolean>>((acc, { reasonField }) => {
  //     if (mutableUserFields.has(reasonField)) {
  //       return { ...acc, [reasonField]: newRow[reasonField] };
  //     }
  //
  //     return acc;
  //   }, {});
  //
  //   try {
  //     let updateUsageResponse;
  //     let editUserResponse = {};
  //     let row = newRow;
  //
  //     if (requestsUsed) {
  //       const { data } = await updateUsageSubscriptionMutation({
  //         variables: { data: { subscriptionId: newRow.subscriptions[0].id, numRequests: newRow.requestsUsed } },
  //       });
  //
  //       updateUsageResponse = data.updateUsageSubscription;
  //     }
  //
  //     if (keys(userFieldsToMutate).length > 0) {
  //       const { data } = await editUserMutation({
  //         variables: {
  //           input: {
  //             id: newRow.id,
  //             username: newRow.username,
  //             role: newRow.role,
  //             email: newRow.email,
  //             ...userFieldsToMutate,
  //           },
  //         },
  //       });
  //
  //       editUserResponse = data.editUser;
  //     }
  //
  //     if (updateUsageResponse) {
  //       row = set(newRow, 'subscriptions.[0].meteredUsage', updateUsageResponse.numRequests);
  //     }
  //
  //     enqueueSnackbar('User successfully saved', { variant: 'success' });
  //     resolve({ ...row, ...editUserResponse });
  //     setPromiseArguments(null);
  //   } catch (error) {
  //     enqueueSnackbar(error.message, { variant: 'error' });
  //     reject(oldRow);
  //     setPromiseArguments(null);
  //   }
  // };

  // const handleEntered = () => {
  //   // The `autoFocus` is not used because, if used, the same Enter that saves
  //   // the cell triggers "No". Instead, we manually focus the "No" button once
  //   // the dialog is fully open.
  //   noButtonRef.current?.focus();
  // };

  // const handleProcessRowUpdateError = React.useCallback(
  //   (error: Error) => enqueueSnackbar(error.message, { variant: 'error' }),
  //   [enqueueSnackbar],
  // );

  // const renderConfirmDialog = () => {
  //   if (!promiseArguments) {
  //     return null;
  //   }
  //
  //   const { mutations } = promiseArguments;
  //
  //   return (
  //     <Dialog maxWidth="lg" TransitionProps={{ onEntered: handleEntered }} open={!!promiseArguments}>
  //       <DialogTitle>Are you sure?</DialogTitle>
  //       <DialogContent dividers>
  //         {mutations.map(({ reasonField, alertMessage }) => (
  //           <DialogContentText key={reasonField}>{`Pressing 'Yes' will change ${alertMessage}.`}</DialogContentText>
  //         ))}
  //       </DialogContent>
  //       <DialogActions>
  //         <Button ref={noButtonRef} onClick={handleNo}>
  //           No
  //         </Button>
  //         <Button onClick={handleYes}>Yes</Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  return (
    <Box sx={{ height: 'calc(100vh - 180px)', width: '100%' }}>
      {/* {renderConfirmDialog()} */}
      <DataGrid
        loading={loading}
        disableSelectionOnClick
        rows={rows}
        columns={columns}
        editMode="row"
        // processRowUpdate={processRowUpdate}
        // onProcessRowUpdateError={handleProcessRowUpdateError}
        components={{ Toolbar }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
