import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { app } from '@mdca/config';

import { useUsersQuery } from '@app/graphql/generated';

import { Layout } from '../../look';
import { UsersListView } from '../components/Users/UsersListView';

export default function Users() {
  const { t } = useTranslation('user');

  const { loading, data } = useUsersQuery();

  return (
    <Layout>
      <Helmet
        title={`${app.name} - ${t('users.title')}`}
        meta={[{ name: 'description', content: `${app.name} - ${t('users.meta')}` }]}
      />
      {/* @ts-expect-error: noop */}
      <UsersListView loading={loading} users={data?.users} />
    </Layout>
  );
}
