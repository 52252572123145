import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ShareIcon from '@mui/icons-material/Share';
import { Switch, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { PromptPresetInfoFragment, useUpdateOnePromptPresetMutation } from '@app/graphql/generated';
import { useDeleteOnePromptPresetMutation } from '@app/graphql/hooks/prompsAndPresets';

import { Dialog, MdBox, MdTypography } from '../../look';
import { presetShareLink } from '../helper';

import { PresetCollapse } from './PresetCollapse';
import { PresetEditDialog } from './PresetEditDialog';

type Props = { preset: PromptPresetInfoFragment };

export function PresetRow({ preset }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('prompt');
  const clipboard = useClipboard();

  const [openPrompt, setOpenPrompt] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const [deletePresetMutation] = useDeleteOnePromptPresetMutation({
    onCompleted: () => enqueueSnackbar('Deleted', { variant: 'success' }),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  });

  const [editPresetMutation] = useUpdateOnePromptPresetMutation({
    onCompleted: () => enqueueSnackbar('Updated', { variant: 'success' }),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  });

  const togglePromptOpen = React.useCallback(() => setOpenPrompt((prevState) => !prevState), []);

  const handleDeleteDialogOpen = React.useCallback(() => setOpenDeleteDialog(true), []);
  const handleDeleteDialogClose = React.useCallback(() => setOpenDeleteDialog(false), []);

  const handleEditDialogOpen = React.useCallback(() => setOpenEditDialog(true), []);
  const handleEditDialogClose = React.useCallback(() => setOpenEditDialog(false), []);

  const handleDelete = React.useCallback(() => {
    deletePresetMutation({ variables: { id: preset.id } }).catch(console.error);
  }, [deletePresetMutation, preset.id]);

  const handleEdit = React.useCallback(
    (presetUpdate: PromptPresetInfoFragment) => {
      editPresetMutation({
        variables: {
          id: presetUpdate.id,
          data: {
            updatedAt: { set: Date.now() },
            name: { set: presetUpdate.name },
            description: { set: presetUpdate.description },
            isPublic: { set: presetUpdate.isPublic },
            prompt: {
              update: {
                updatedAt: { set: Date.now() },
                prompt: { set: presetUpdate.prompt.prompt },
                bestOf: { set: presetUpdate.prompt.bestOf },
                frequencyPenalty: { set: presetUpdate.prompt.frequencyPenalty },
                presencePenalty: { set: presetUpdate.prompt.presencePenalty },
                maxTokens: { set: presetUpdate.prompt.maxTokens },
                temperature: { set: presetUpdate.prompt.temperature },
                topP: { set: presetUpdate.prompt.topP },
                stop: { set: presetUpdate.prompt.stop },
              },
            },
          },
        },
      }).catch(console.error);
    },
    [editPresetMutation],
  );

  const handleShare = React.useCallback(() => {
    clipboard.copy(presetShareLink(preset.id));
    enqueueSnackbar(t('playground.shareDialog.copied'), { variant: 'success' });
  }, [clipboard, enqueueSnackbar, preset.id, t]);

  const handleCopyId = React.useCallback(() => {
    clipboard.copy(preset.id);
    enqueueSnackbar(t('playground.shareDialog.copiedId'), { variant: 'success' });
  }, [clipboard, enqueueSnackbar, preset.id, t]);

  const handlePrivacy = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleEdit({ ...preset, isPublic: event.target.checked });
    },
    [handleEdit, preset],
  );

  return (
    <>
      <Dialog
        title={t('playground.confirmDialog.delete')}
        open={openDeleteDialog}
        actions={<Button onClick={handleDelete}>{t('playground.confirmDialog.delete')}</Button>}
        onClose={handleDeleteDialogClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
      >
        <MdBox sx={{ textAlign: 'center' }}>
          <MdTypography>{t('playground.confirmDialog.confirmMessage')}</MdTypography>
        </MdBox>
      </Dialog>
      <PresetEditDialog
        open={openEditDialog}
        handleClose={handleEditDialogClose}
        preset={preset}
        handlePresetUpdate={handleEdit}
      />
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={togglePromptOpen}>
            {openPrompt ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ minWidth: '300px', wordBreak: 'break-word' }}>{preset.name}</TableCell>
        <TableCell sx={{ wordBreak: 'break-word' }}>{preset.description}</TableCell>
        <TableCell sx={{ wordBreak: 'break-word', fontFamily: 'monospace' }}>
          <span style={{ backgroundColor: 'rgb(3,12,29, 0.5)', padding: '5px', borderRadius: '5px' }}>{preset.id}</span>
          <Tooltip title={t('playground.shareDialog.copyId')}>
            <IconButton sx={{ ml: 1 }} aria-label="copy" size="small" onClick={handleCopyId}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Switch checked={preset.isPublic} onChange={handlePrivacy} inputProps={{ 'aria-label': 'controlled' }} />
            }
            label={preset.isPublic ? 'Public' : 'Private'}
          />
        </TableCell>
        <TableCell>
          <Tooltip title={t('playground.shareDialog.copy')}>
            <IconButton aria-label="share" size="small" onClick={handleShare}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={t('preset.editPreset')}>
            <IconButton aria-label="edit" size="small" onClick={handleEditDialogOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={t('preset.deletePreset')}>
            <IconButton aria-label="delete" size="small" onClick={handleDeleteDialogOpen}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <PresetCollapse open={openPrompt} prompt={preset.prompt} />
        </TableCell>
      </TableRow>
    </>
  );
}
