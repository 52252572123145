import React from 'react';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { RequestSource, UserRole } from '@app/graphql/generated';
import { useBlocker } from '@app/hooks/useBlocker';
import { useCurrentUser } from '@app/hooks/useCurrentUser';

import { MdBox } from '../../look';
import { FormPrompt, PredictChatQuestion } from '../types';

import { Playground } from './Playground';
import { PlaygroundChat } from './PlaygroundChat';
import { PlaygroundCode } from './PlaygroundCode';
import { Search } from './Search';

const blocker = (tx: any) => {
  // eslint-disable-next-line no-alert
  if (window.confirm('You have edited playground. Are you sure you want to leave this page?')) tx.retry();
};

const initialPromptValues: FormPrompt = {
  prompt: '',
  temperature: 0.7,
  maxTokens: 100,
  topP: 1,
  frequencyPenalty: 0,
  presencePenalty: 0,
  stop: [],
  bestOf: 1,
  source: RequestSource.App,
  variables: {},
};

type Props = {
  children?: React.ReactNode;
  index: number;
  value: number;
};
function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;

  return (
    <MdBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MdBox pt={1}>{children}</MdBox>}
    </MdBox>
  );
}
const initialChatValues: PredictChatQuestion = {
  question: '',
};
export function PromptView() {
  const { t } = useTranslation('prompt');
  const [currentTab, setCurrentTab] = React.useState(0);
  const [playgroundPrompt, setPlaygroundPrompt] = React.useState<FormPrompt>(initialPromptValues);
  const [codePrompt, setCodePrompt] = React.useState<FormPrompt>(initialPromptValues);
  const [articlePrompt, setArticlePrompt] = React.useState<PredictChatQuestion>(initialChatValues);
  const currentUser = useCurrentUser();

  const isDirty =
    playgroundPrompt.prompt.length > 1 || codePrompt.prompt.length > 1 || articlePrompt.question.length > 1;
  useBlocker(blocker, isDirty);

  const handlePlaygroundPromptChange = React.useCallback((field: keyof FormPrompt, value: any) => {
    setPlaygroundPrompt((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleCodePromptChange = React.useCallback((field: keyof FormPrompt, value: any) => {
    setCodePrompt((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleArticlePromptChange = React.useCallback((value: string) => {
    setArticlePrompt((prev) => ({ ...prev, question: value }));
  }, []);

  const handleChange = React.useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <>
      <Tabs value={currentTab} onChange={handleChange}>
        <Tab sx={{ fontSize: '1.2rem' }} label={t('playground.title')} />
        <Tab
          disabled={!currentUser?.roles.includes(UserRole.Admin)}
          sx={{ fontSize: '1.2rem' }}
          label={t('playground.chatTitle')}
        />
        <Tab
          disabled={!currentUser?.roles.includes(UserRole.Admin)}
          sx={{ fontSize: '1.2rem' }}
          label={t('playground.codeTitle')}
        />
        <Tab disabled sx={{ fontSize: '1.2rem' }} label={t('search.title')} />
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <Playground playgroundPrompt={playgroundPrompt} handlePlaygroundPrompt={handlePlaygroundPromptChange} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <PlaygroundChat articlePrompt={articlePrompt} handleArticlePrompt={handleArticlePromptChange} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <PlaygroundCode codePrompt={codePrompt} handleCodePrompt={handleCodePromptChange} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <Search />
      </TabPanel>
    </>
  );
}
