import React from 'react';
import { Outlet } from 'react-router';
import { Scrollbar } from 'react-scrollbars-custom';
import Box from '@mui/system/Box';

import { app } from '@mdca/config';
import { useSelector } from '@wpa/redux-utils';

import { ScrollToTop } from '@app/modules/look/material/ScrollToTop';
import { routeSettings } from '@app/routeSettings';

import { UIState, ViewState } from '../common';
import { MdScrollbar, Navbar, PageLayout, Sidenav } from '../look';
import { setMiniSidenav as propsSetMiniSidenav, setTransparentNavbar } from '../theme';

export default function RootLayout() {
  const {
    miniSidenav: propsMiniSidenav,
    sidenavColor,
    direction,
    fixedNavbar,
    transparentNavbar,
  } = useSelector<UIState, ViewState>(
    // @ts-expect-error: noop
    (state) => state.view,
  );
  const scrollRef = React.useRef<Scrollbar | null>(null);
  const [miniSidenav, setMiniSidenav] = React.useState<ViewState['miniSidenav']>(propsMiniSidenav);

  React.useEffect(() => {
    const localstorageMiniSidenav = localStorage.getItem('miniSidenav');

    if (localstorageMiniSidenav) {
      setMiniSidenav(localstorageMiniSidenav === 'true');
      propsSetMiniSidenav(localstorageMiniSidenav === 'true');
    }
  }, []);

  React.useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  const handleMiniSidenav = (newState: boolean) => {
    localStorage.setItem('miniSidenav', String(newState));
    setMiniSidenav(newState);
    propsSetMiniSidenav(newState);
  };

  const handleTransparentNavbar = () => {
    if (scrollRef.current) {
      setTransparentNavbar((fixedNavbar && scrollRef.current?.scrollTop <= 20) || !fixedNavbar);
    }
  };

  const routes = Object.values(routeSettings);

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brandName={app.name}
        routes={routes}
        miniSidenav={miniSidenav}
        setMiniSidenav={handleMiniSidenav}
      />
      <MdScrollbar ref={scrollRef} onScroll={handleTransparentNavbar} right={5}>
        <>
          <Box sx={{ height: '1px' }} id="back-to-top-anchor" />
          <PageLayout miniSidenav={miniSidenav}>
            <Navbar
              navItemsRight={/* navItemsRight ||  */ []}
              fixedNavbar={fixedNavbar}
              miniSidenav={miniSidenav}
              transparentNavbar={transparentNavbar}
              setMiniSidenav={handleMiniSidenav}
            />
            <Outlet />
          </PageLayout>
          <ScrollToTop
            trigger={!((fixedNavbar && scrollRef.current && scrollRef.current?.scrollTop <= 20) || !fixedNavbar)}
          />
        </>
      </MdScrollbar>
    </>
  );
}
