import React, { useCallback, useMemo } from 'react';
import { Case, Default, Switch } from 'react-if';
import { Theme } from '@mui/material';
import fastDeepEqual from 'fast-deep-equal';
import scrollIntoView from 'scroll-into-view-if-needed';
import {
  BaseRange,
  createEditor,
  Descendant,
  Editor,
  NodeEntry,
  Range,
  Selection,
  Text as SlateText,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';

import { MdBox, MdTypography } from '../../../look';
import { PromptResponse } from '../../types';

import { CustomNode } from './types';

type Props = {
  text: string;
  textChunk: PromptResponse;
  onChange: (value: string) => void;
  height?: number;
};

function prepareState(prompt: string, isHighlight = false): Array<Descendant> {
  return [{ type: 'paragraph', children: [{ text: prompt, highlight: isHighlight }] }];
}

function Element({ attributes, children, element }: React.PropsWithChildren<any>) {
  const style = { textAlign: element.align, margin: 0 };

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

function Leaf({ attributes, children, leaf }: React.PropsWithChildren<any>) {
  return (
    <MdTypography
      component="span"
      sx={{ backgroundColor: leaf.highlight && '#42855B', lineHeight: 1.2 }}
      {...attributes}
      {...(leaf.highlight && { 'data-cy': 'search-highlighted' })}
    >
      <Switch>
        <Case condition={leaf.bold}>
          <strong>{children}</strong>
        </Case>
        <Case condition={leaf.code}>
          <code>{children}</code>
        </Case>
        <Case condition={leaf.italic}>
          <em>{children}</em>
        </Case>
        <Case condition={leaf.underline}>
          <u>{children}</u>
        </Case>
        <Default>{children}</Default>
      </Switch>
    </MdTypography>
  );
}

function SlateEditor(props: Props) {
  const {
    text,
    textChunk: { text: textChunk, isPreset = false },
    onChange,
    height,
  } = props;
  const renderElement = useCallback((p: any) => <Element {...p} />, []);
  const renderLeaf = useCallback((p: any) => <Leaf {...p} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [value, setValue] = React.useState<Array<Descendant>>(prepareState(text));
  const [editRange, setEditRange] = React.useState<BaseRange | null>(null);
  const boundary = React.useRef<HTMLDivElement>(null);
  const currentSelection = React.useRef<Selection | null>(editor.selection);

  React.useEffect(() => {
    currentSelection.current = null;
  }, [textChunk]);

  React.useEffect(() => {
    if (ReactEditor.isFocused(editor) && !fastDeepEqual(currentSelection.current, editor.selection)) {
      currentSelection.current = editor.selection;
    }
  }, [editor, editor.selection, textChunk]);

  React.useEffect(() => {
    if (isPreset) {
      setEditRange(null);
      setValue(prepareState(textChunk, false));
    } else if (textChunk.length > 0) {
      setValue((prevState) => [...prevState, ...prepareState(textChunk, true)]);
    }
  }, [isPreset, textChunk]);

  const handleScrollIntoView = React.useCallback(
    (_: ReactEditor, domRange: globalThis.Range) => {
      const leafEl = domRange.startContainer.parentElement;
      scrollIntoView(leafEl!, { behavior: 'smooth', scrollMode: 'if-needed', boundary: boundary.current });
    },
    [boundary],
  );

  const handleChange = React.useCallback(
    (newValue: Array<Descendant>) => {
      if (!fastDeepEqual(value, newValue)) {
        if (editor.selection && !fastDeepEqual(currentSelection.current, editor.selection)) {
          currentSelection.current = editor.selection;
          setEditRange(editor.selection);
        }

        setValue(newValue);
      }

      // eslint-disable-next-line unicorn/no-array-reduce
      const newPrompt = newValue.reduce<string>((acc, element) => {
        if ('type' in element && element.type === 'paragraph') {
          return `${acc + element.children[0].text}\n`;
        }

        return acc;
      }, '');

      onChange(newPrompt);
    },
    [editor, onChange, value],
  );

  const handleFocus = React.useCallback(() => {
    if (currentSelection.current) {
      Transforms.select(editor, currentSelection.current);
    }
  }, [editor]);

  const decorate = React.useCallback(
    ([node, path]: NodeEntry<CustomNode>) => {
      if (editRange && editor.selection != null && fastDeepEqual(editRange, editor.selection)) {
        Transforms.setNodes(editor, { highlight: false }, { at: editRange, match: SlateText.isText, split: false });
      }

      if (
        editor.selection != null &&
        !Editor.isEditor(node) &&
        Editor.string(editor, [path[0]]) === '' &&
        Range.includes(editor.selection, path) &&
        Range.isCollapsed(editor.selection)
      ) {
        Transforms.setNodes(
          editor,
          { highlight: false },
          { at: editor.selection, match: SlateText.isText, split: false },
        );

        return [{ ...editor.selection, highlight: false }];
      }

      return [];
    },
    [editRange, editor],
  );

  // For Slate versions > 0.66.5
  editor.children = value;

  return (
    <MdBox
      ref={boundary}
      sx={(theme: Theme) => ({
        minHeight: height,
        padding: 2,
        border: '1px solid',
        borderRadius: theme.borders.borderRadius,
        lineHeight: 1.2,
      })}
    >
      <Slate editor={editor} value={value} onChange={handleChange}>
        <Editable
          style={{ minHeight: height && height - 60 }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          // placeholder="Enter some prompt…"
          decorate={decorate}
          onFocus={handleFocus}
          scrollSelectionIntoView={handleScrollIntoView}
          autoFocus
        />
      </Slate>
    </MdBox>
  );
}

export const MemoSlateEditor = React.memo(SlateEditor);
