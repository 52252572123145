import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';

type Props = {
  isZebra: boolean;
};

export const StyledTableRow = styled(TableRow)<{ props: Props }>(({ theme, props }) => {
  const { isZebra } = props;

  return {
    '&:nth-of-type(odd)': isZebra && {
      backgroundColor: theme.palette.secondary.dark,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  };
});
