import { env } from '@mdca/wde';

export const NODE_VARS = {
  // COMMON
  SERVER_PORT: Number.parseInt(env('SERVER_PORT'), 10),
  NODE_ENV: env('NODE_ENV'),
  // SERVER
  GRAPHQL_PUBLIC_PLAYGROUND: env('GRAPHQL_PUBLIC_PLAYGROUND') !== 'false',
  WEBSITE_URL: env('WEBSITE_URL'),
  FRONTEND_BUILD_DIR: env('FRONTEND_BUILD_DIR'),
  ALLOWED_ORIGINS: env('ALLOWED_ORIGINS'),
  // POSTGRES
  DB_TYPE: env('DB_TYPE'),
  DB_HOST: env('DB_HOST'),
  DB_PORT: Number.parseInt(env('DB_PORT'), 10),
  DB_USER: env('DB_USER'),
  DB_PASSWORD: env('DB_PASSWORD'),
  DB_DATABASE: env('DB_DATABASE'),
  DB_SSL: !!env('DB_SSL'),
  // REDIS
  REDIS_USERNAME: env('REDIS_USERNAME'),
  REDIS_PASSWORD: env('REDIS_PASSWORD'),
  REDIS_HOST: env('REDIS_HOST'),
  REDIS_PORT: Number.parseInt(env('REDIS_PORT'), 10),
  REDIS_PREFIX: env('REDIS_PREFIX'),
  // AUTH
  AUTH_SECRET: env('AUTH_SECRET'),
  FACEBOOK_CLIENT_ID: env('FACEBOOK_CLIENT_ID'),
  FACEBOOK_CLIENT_SECRET: env('FACEBOOK_CLIENT_SECRET'),
  GITHUB_CLIENT_ID: env('GITHUB_CLIENT_ID'),
  GITHUB_CLIENT_SECRET: env('GITHUB_CLIENT_SECRET'),
  LINKEDIN_CLIENT_ID: env('LINKEDIN_CLIENT_ID'),
  LINKEDIN_CLIENT_SECRET: env('LINKEDIN_CLIENT_SECRET'),
  GOOGLE_CLIENT_ID: env('GOOGLE_CLIENT_ID'),
  GOOGLE_CLIENT_SECRET: env('GOOGLE_CLIENT_SECRET'),
  // MAILER
  EMAIL_SERVICE: env('EMAIL_SERVICE'),
  SENDGRID_API_KEY: env('SENDGRID_API_KEY'),
  EMAIL_SENDER: env('EMAIL_SENDER'),
  EMAIL_HOST: env('EMAIL_HOST'),
  EMAIL_PORT: env('EMAIL_PORT'),
  EMAIL_USER: env('EMAIL_USER'),
  EMAIL_PASSWORD: env('EMAIL_PASSWORD'),
  // STRIPE
  STRIPE_PUBLIC_KEY: env('STRIPE_PUBLIC_KEY'),
  STRIPE_SECRET_KEY: env('STRIPE_SECRET_KEY'),
  STRIPE_ENDPOINT_SECRET: env('STRIPE_ENDPOINT_SECRET'),
  // AWS
  AWS_ACCESS_KEY_ID: env('AWS_ACCESS_KEY_ID'),
  AWS_SECRET_ACCESS_KEY: env('AWS_SECRET_ACCESS_KEY'),
  AWS_REGION: env('AWS_REGION'),
  S3_BUCKET_NAME: env('S3_BUCKET_NAME'),
  // SENTRY
  SENTRY_DSN: env('SENTRY_DSN'),
  // Connectors
  CONNECTORS_BASE_URL: env('CONNECTORS_BASE_URL'),
  CONNECTORS_SEND_URL: env('CONNECTORS_SEND_URL'),
  CONNECTORS_USERNAME: env('CONNECTORS_USERNAME'),
  CONNECTORS_PASSWORD: env('CONNECTORS_PASSWORD'),
  // MD Platform
  MDPLATFORM_BASE_URL: env('MDPLATFORM_BASE_URL'),
  MDPLATFORM_USERNAME: env('MDPLATFORM_USERNAME'),
  MDPLATFORM_PASSWORD: env('MDPLATFORM_PASSWORD'),
  MDPLATFORM_MDCA_URL: env('MDPLATFORM_MDCA_URL'),
  MDPLATFORM_WORKSPACE: env('MDPLATFORM_WORKSPACE'),
  // QnAInstance
  QNAINSTANCE_BASE_URL: env('QNAINSTANCE_BASE_URL'),
  QNA_WEBHOOK: env('QNA_WEBHOOK'),
  // Widget
  WIDGET_URL: env('WIDGET_URL'),
  // Vast AI
  VAST_AI_BASE_URL: env('VAST_AI_BASE_URL'),
  VAST_AI_API_KEY: env('VAST_AI_API_KEY'),
  VAST_AI_MDCA_URL: env('VAST_AI_MDCA_URL'),
  VAST_AI_SSH: env('VAST_AI_SSH'),
  // Open ai
  PREDICT_API_KEY: env('PREDICT_API_KEY'),
  PREDICT_API_URL: env('PREDICT_API_URL'),
  PREDICT_CODE_API_URL: env('PREDICT_CODE_API_URL'),
  // AUTH0
  AUTH0_AUDIENCE: env('AUTH0_AUDIENCE'),
  AUTH0_DOMAIN: env('AUTH0_DOMAIN'),
  AUTH0_CLIENT_ID: env('AUTH0_CLIENT_ID'),
  AUTH0_CALLBACK_URL: env('AUTH0_CALLBACK_URL'),
  // Scraper
  SCRAPER_SINGLE_URL: env('SCRAPER_SINGLE_URL'),
};
