import * as Apollo from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import {
  CreateOnePromptPresetMutation,
  CreateOnePromptPresetMutationVariables,
  DeleteOnePromptPresetMutation,
  DeleteOnePromptPresetMutationVariables,
  PredictMutation,
  PredictMutationVariables,
  PromptPresetsByUserUidDocument,
  PromptPresetsByUserUidQuery,
  PromptPresetsByUserUidQueryVariables,
  PromptsByUserIdWithPaginationQuery,
  PromptsByUserIdWithPaginationQueryVariables,
  useCreateOnePromptPresetMutation as _useCreateOnePromptPresetMutation,
  useDeleteOnePromptPresetMutation as _useDeleteOnePromptPresetMutation,
  usePredictMutation as _usePredictMutation,
  usePromptPresetsByUserUidQuery as _usePromptPresetsByUserUidQuery,
  usePromptsByUserIdWithPaginationLazyQuery as _usePromptsByUserIdWithPaginationLazyQuery,
  UsersQuery,
  UsersQueryVariables,
  useUsersQuery as _useUsersQuery,
} from '@app/graphql/generated';


export function useCreateOnePromptPresetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOnePromptPresetMutation, CreateOnePromptPresetMutationVariables>,
) {
  const { user } = useAuth0();

  return _useCreateOnePromptPresetMutation({
    ...baseOptions,
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const { createOnePromptPreset } = data;

      const query = cache.readQuery<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>({
        query: PromptPresetsByUserUidDocument,
        variables: { uid: user?.sub as string },
      });

      const promptPresets = query?.promptPresets || [];

      cache.writeQuery<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>({
        query: PromptPresetsByUserUidDocument,
        data: { promptPresets: [...promptPresets, createOnePromptPreset] },
        variables: { uid: user?.sub as string },
      });
    },
  });
}

export function useDeleteOnePromptPresetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOnePromptPresetMutation, DeleteOnePromptPresetMutationVariables>,
) {
  return _useDeleteOnePromptPresetMutation({
    ...baseOptions,
    update: (cache, { data }) => {
      if (!data || !data.deleteOnePromptPreset) {
        return;
      }

      const { deleteOnePromptPreset } = data;
      cache.evict({ id: cache.identify(deleteOnePromptPreset) });
    },
  });
}

export function usePredictMutation(
  baseOptions?: Apollo.MutationHookOptions<PredictMutation, PredictMutationVariables>,
) {
  return _usePredictMutation({
    ...baseOptions,
  });
}

export function usePromptPresetsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<PromptPresetsByUserUidQuery, PromptPresetsByUserUidQueryVariables>,
) {
  return _usePromptPresetsByUserUidQuery({
    ...baseOptions,
  });
}

export function usePromptsByUserIdWithPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PromptsByUserIdWithPaginationQuery,
    PromptsByUserIdWithPaginationQueryVariables
  >,
) {
  return _usePromptsByUserIdWithPaginationLazyQuery({
    ...baseOptions,
  });
}

export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  return _useUsersQuery({
    ...baseOptions,
  });
}
