import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { Layout, MdBox, MdTypography } from '../../look';

export function AuthzErrorBoundary(props: React.PropsWithChildren<any>) {
  const { t } = useTranslation();
  const { error, logout } = useAuth0();

  if (error) {
    return (
      <Layout>
        <Container
          sx={{
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '90px',
          }}
        >
          <MdBox>
            <GppBadOutlinedIcon color="action" sx={{ width: '400px', height: '200px' }} />
          </MdBox>
          <MdTypography sx={{ my: 3 }} variant="h2">
            Auth error
          </MdTypography>
          <MdTypography sx={{ my: 3 }} variant="body1">
            {error.message}
          </MdTypography>
          <Button color="primary" onClick={() => window.location.replace('/prompt')}>
            {/* TODO translation */}
            {t('Go to home page')}
          </Button>
          <MdTypography>or</MdTypography>
          <Button color="primary" onClick={() => logout({ returnTo: window.location.origin })}>
            {/* TODO translation */}
            {t('Logout')}
          </Button>
        </Container>
      </Layout>
    );
  }

  return props.children;
}
