import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { NODE_VARS } from '@mdca/config';

import { UserRole } from '@app/graphql/generated';
import { hasRole } from '@app/helpers/hasRole';

import { Loading } from '../look';

type Props = {
  allowedRoles?: Set<UserRole>;
  component: React.ComponentType<any> | React.ReactElement<React.PropsWithChildren<any>>;
};

function getWrapper(component: React.ReactElement<any>) {
  return function Wrapper() {
    return <div style={{ height: 'inherit' }}>{component}</div>;
  };
}

export function ProtectedRoute({ allowedRoles, component }: Props) {
  const maybeWrappedComponent = typeof component === 'function' ? component : getWrapper(component);

  const isRoot = window.location.pathname === '/';
  const returnTo = isRoot ? '/prompt' : `${window.location.pathname}${window.location.search}`;

  const Component = withAuthenticationRequired(maybeWrappedComponent, {
    onRedirecting: Loading,
    returnTo,
    claimCheck: (claims?: Record<string, any>) => {
      let isAccessAllowed = false;

      if (claims) {
        const roles = claims[`${NODE_VARS.AUTH0_AUDIENCE}/roles`];
        isAccessAllowed = hasRole(allowedRoles, roles);
      }

      return isAccessAllowed;
    },
  });

  return <Component />;
}
