// import Fade from '@mui/material/Fade';
import { ThemeConfig, ThemeOptions } from '@mui/material/styles';
import { Property } from 'csstype';

import { boxShadow, hexToRgb, linearGradient, pxToRem, rgba, tripleLinearGradient } from './functions';

const sharedClasses = {
  paddingRight: `${pxToRem(24)}`,
  paddingLeft: `${pxToRem(24)}`,
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
  position: 'relative',
};

export const getThemeOptions = (themeConfig: ThemeConfig): ThemeOptions => {
  const { colors, getBoxShadows, getTypography, customization } = themeConfig;

  const typography = getTypography(colors);
  const boxShadows = getBoxShadows(colors);
  const breakpoints = {
    values: {
      xs: 375,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
      xxl: 1680,
    },
  };
  const borders = {
    borderWidth: {
      0: 0,
      1: pxToRem(1),
      2: pxToRem(2),
      3: pxToRem(3),
      4: pxToRem(4),
      5: pxToRem(5),
    },
    borderRadius: {
      xs: pxToRem(2),
      sm: pxToRem(4),
      md: pxToRem(8),
      lg: pxToRem(15),
      xl: pxToRem(20),
      xxl: pxToRem(24),
      regular: pxToRem(14),
      button: pxToRem(12),
      form: pxToRem(24),
      section: pxToRem(160),
    },
  };

  // const input = {
  //   styleOverrides: {
  //     root: {
  //       display: 'flex !important',
  //       padding: `${pxToRem(8)} ${pxToRem(28)} ${pxToRem(8)} ${pxToRem(12)} !important`,
  //       border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
  //       borderRadius: `${borderRadius.md} !important`,
  //       '& fieldset': {
  //         border: 'none',
  //       },
  //     },
  //     input: {
  //       height: pxToRem(22),
  //       width: 'max-content !important',
  //     },
  //     inputSizeSmall: {
  //       height: pxToRem(14),
  //     },
  //   },
  // };

  return {
    customization,
    direction: 'ltr',
    palette: { mode: customization.mode, ...colors },
    typography,
    breakpoints,
    borders,
    boxShadows,
    functions: { boxShadow, hexToRgb, linearGradient, tripleLinearGradient, pxToRem, rgba },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            scrollBehavior: 'smooth',
          },
          body: {
            // backgroundImage: `url('/root-background.jpg')`,
            // backgroundSize: 'cover',
            height: '100vh',
            '&.fontLoaded': {
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
          [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            '.MuiContainer-root': { ...sharedClasses, maxWidth: '540px' },
          },
          [`@media (min-width: ${breakpoints.values.md}px)`]: {
            '.MuiContainer-root': { ...sharedClasses, maxWidth: '720px' },
          },
          [`@media (min-width: ${breakpoints.values.lg}px)`]: {
            '.MuiContainer-root': { ...sharedClasses, maxWidth: '960px' },
          },
          [`@media (min-width: ${breakpoints.values.xl}px)`]: {
            '.MuiContainer-root': { ...sharedClasses, maxWidth: '1140px' },
          },
          [`@media (min-width: ${breakpoints.values.xxl}px)`]: {
            '.MuiContainer-root': { ...sharedClasses, maxWidth: '1320px' },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            width: pxToRem(300),
            whiteSpace: 'nowrap',
            border: 'none',
          },
          paper: {
            width: pxToRem(300),
            backgroundColor: rgba(colors.white.main, 0.8),
            backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
            height: `calc(100vh - ${pxToRem(32)})`,
            margin: pxToRem(16),
            borderRadius: borders.borderRadius.xl,
            border: 'none',
          },
          paperAnchorDockedLeft: {
            borderRight: 'none',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          variant: 'elevation',
          elevation: 0,
        },
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            // background: linearGradient(
            //   colors.gradients.card.main,
            //   colors.gradients.card.state,
            //   colors.gradients.card.deg,
            // ),
            // backdropFilter: 'blur(120px)',
            position: 'relative',
            minWidth: 0,
            padding: '22px',
            wordWrap: 'break-word',
            backgroundClip: 'border-box',
            // border: `${borders.borderWidth[0]} solid ${rgba(colors.black.main, 0.125)}`,
            borderRadius: borders.borderRadius.lg,
            // boxShadow: boxShadows.xxl,
          },
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h3',
          },
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            borderRadius: borders.borderRadius.xl,
            margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
          },
          media: {
            width: 'auto',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            marginTop: 0,
            marginBottom: 0,
            padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        // styleOverrides: {
        //   root: {
        //     display: 'inline-flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     fontSize: size.xs,
        //     fontWeight: fontWeightBold,
        //     borderRadius: borderRadius.button,
        //     padding: `${pxToRem(12)} ${pxToRem(24)}`,
        //     lineHeight: 1.4,
        //     textAlign: 'center',
        //     textTransform: 'unset',
        //     userSelect: 'none',
        //     backgroundSize: '150% !important',
        //     backgroundPositionX: '25% !important',
        //     transition: `all 150ms ease-in`,
        //     '&:hover': {
        //       transform: 'scale(1.02)',
        //     },
        //     '&:disabled': {
        //       pointerEvent: 'none',
        //       opacity: 0.65,
        //     },
        //     '& .material-icons': {
        //       fontSize: pxToRem(15),
        //       marginTop: pxToRem(-2),
        //     },
        //   },
        //   contained: {
        //     backgroundColor: white.main,
        //     minHeight: pxToRem(40),
        //     color: text.main,
        //     boxShadow: buttonBoxShadow.main,
        //     padding: `${pxToRem(12)} ${pxToRem(24)}`,
        //     '&:hover': {
        //       backgroundColor: white.main,
        //       boxShadow: buttonBoxShadow.stateOf,
        //     },
        //     '&:focus': {
        //       boxShadow: buttonBoxShadow.stateOf,
        //     },
        //     '&:active, &:active:focus, &:active:hover': {
        //       opacity: 0.85,
        //       boxShadow: buttonBoxShadow.stateOf,
        //     },
        //     '&:disabled': {
        //       boxShadow: buttonBoxShadow.main,
        //     },
        //     '& .material-icon, .material-icons-round, svg': {
        //       fontSize: `${pxToRem(16)} !important`,
        //     },
        //   },
        //   containedSizeSmall: {
        //     minHeight: pxToRem(32),
        //     padding: `${pxToRem(8)} ${pxToRem(32)}`,
        //     fontSize: size.xs,
        //     '& .material-icon, .material-icons-round, svg': {
        //       fontSize: `${pxToRem(12)} !important`,
        //     },
        //   },
        //   containedSizeLarge: {
        //     minHeight: pxToRem(47),
        //     padding: `${pxToRem(14)} ${pxToRem(64)}`,
        //     fontSize: size.sm,
        //     '& .material-icon, .material-icons-round, svg': {
        //       fontSize: `${pxToRem(22)} !important`,
        //     },
        //   },
        //   containedPrimary: {
        //     backgroundColor: info.main,
        //     '&:hover': {
        //       backgroundColor: info.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       backgroundColor: info.focus,
        //       boxShadow: buttonBoxShadow.stateOfNotHover,
        //     },
        //   },
        //   containedSecondary: {
        //     backgroundColor: secondary.main,
        //     '&:hover': {
        //       backgroundColor: secondary.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       backgroundColor: secondary.focus,
        //       boxShadow: buttonBoxShadow.stateOfNotHover,
        //     },
        //   },
        //   outlined: {
        //     minHeight: pxToRem(42),
        //     color: light.main,
        //     borderColor: light.main,
        //     padding: `${pxToRem(12)} ${pxToRem(24)}`,
        //     '&:hover': {
        //       opacity: 0.75,
        //       backgroundColor: transparent.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       boxShadow: buttonBoxShadow.stateOfNotHover,
        //     },
        //     '& .material-icon, .material-icons-round, svg': {
        //       fontSize: `${pxToRem(16)} !important`,
        //     },
        //   },
        //   outlinedSizeSmall: {
        //     minHeight: pxToRem(34),
        //     padding: `${pxToRem(8)} ${pxToRem(32)}`,
        //     fontSize: size.xs,
        //     '& .material-icon, .material-icons-round, svg': {
        //       fontSize: `${pxToRem(12)} !important`,
        //     },
        //   },
        //   outlinedSizeLarge: {
        //     minHeight: pxToRem(49),
        //     padding: `${pxToRem(14)} ${pxToRem(64)}`,
        //     fontSize: size.sm,
        //     '& .material-icon, .material-icons-round, svg': {
        //       fontSize: `${pxToRem(22)} !important`,
        //     },
        //   },
        //   outlinedPrimary: {
        //     backgroundColor: transparent.main,
        //     borderColor: info.main,
        //     '&:hover': {
        //       backgroundColor: transparent.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       boxShadow: buttonBoxShadow.stateOfNotHover,
        //     },
        //   },
        //   outlinedSecondary: {
        //     backgroundColor: transparent.main,
        //     borderColor: secondary.main,
        //     '&:hover': {
        //       backgroundColor: transparent.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       boxShadow: buttonBoxShadow.stateOfNotHover,
        //     },
        //   },
        //   text: {
        //     backgroundColor: transparent.main,
        //     height: 'max-content',
        //     color: text.main,
        //     boxShadow: 'none',
        //     padding: `${pxToRem(6)} ${pxToRem(12)}`,
        //     '&:hover': {
        //       backgroundColor: transparent.main,
        //       boxShadow: 'none',
        //       color: text.focus,
        //     },
        //     '&:focus': {
        //       boxShadow: 'none',
        //       color: text.focus,
        //     },
        //     '&:active, &:active:focus, &:active:hover': {
        //       opacity: 0.85,
        //       boxShadow: 'none',
        //     },
        //     '&:disabled': {
        //       color: grey[600],
        //       boxShadow: 'none',
        //     },
        //     '& .material-icons, .material-icons-round, svg, span': {
        //       fontSize: `${pxToRem(16)} !important`,
        //     },
        //   },
        //   textSizeSmall: {
        //     fontSize: size.xs,
        //     '& .material-icons, .material-icons-round, svg, span': {
        //       fontSize: `${pxToRem(12)} !important`,
        //     },
        //   },
        //   textSizeLarge: {
        //     fontSize: size.sm,
        //     '& .material-icons, .material-icons-round, svg, span': {
        //       fontSize: `${pxToRem(22)} !important`,
        //     },
        //   },
        //   textPrimary: {
        //     color: primary.main,
        //     backgroundColor: transparent.main,
        //     '&:hover': {
        //       color: primary.focus,
        //       backgroundColor: transparent.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       color: primary.focus,
        //       backgroundColor: transparent.focus,
        //       boxShadow: 'none',
        //     },
        //   },
        //   textSecondary: {
        //     color: text.secondary,
        //     backgroundColor: transparent.main,
        //     '&:hover': {
        //       color: text.secondary,
        //       backgroundColor: transparent.main,
        //     },
        //     '&:focus:not(:hover)': {
        //       color: text.secondary,
        //       backgroundColor: transparent.focus,
        //       boxShadow: 'none',
        //     },
        //   },
        // },
      },
      // MuiIconButton: {
      //   styleOverrides: {
      //     root: {
      //       '&:hover': {
      //         backgroundColor: colors.transparent.main,
      //       },
      //     },
      //   },
      // },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       display: 'grid !important',
      //       placeItems: 'center !important',
      //       width: '100% !important',
      //       height: 'auto !important',
      //       padding: `${pxToRem(8)} ${pxToRem(12)}`,
      //       fontSize: `${size.sm} !important`,
      //       fontWeight: `${fontWeightRegular} !important`,
      //       lineHeight: '1.4 !important',
      //       color: `${grey[700]} !important`,
      //       backgroundColor: `${white.main} !important`,
      //       backgroundClip: 'padding-box !important',
      //       border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
      //       appearance: 'none !important' as Property.Appearance,
      //       borderRadius: borderRadius.md,
      //       transition: 'box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important',
      //     },
      //     input: {
      //       width: '100% !important',
      //       height: `${pxToRem(22)}`,
      //       padding: '0 !important',
      //       '&::-webkit-input-placeholder': {
      //         color: `${dark.main} !important`,
      //       },
      //     },
      //   },
      // },
      // MuiMenu: {
      //   defaultProps: {
      //     disableAutoFocusItem: true,
      //   },
      //   styleOverrides: {
      //     root: {
      //       '& .MuiIcon-root': {
      //         stroke: white.main,
      //       },
      //     },
      //     paper: {
      //       minWidth: pxToRem(160),
      //       boxShadow: lg,
      //       padding: `0 !important`,
      //       fontSize: size.sm,
      //       color: white.main,
      //       textAlign: 'left',
      //       border: `${borderWidth[1]} solid ${borderCol.navbar}`,
      //       borderRadius: borderRadius.md,
      //     },
      //     list: {
      //       background: linearGradient(gradients.menu.main, gradients.menu.state, gradients.menu.deg),
      //       '& .MuiMenuItem-root': {
      //         '& .MuiBox-root .MuiTypography-root': {
      //           color: white.main,
      //         },
      //         '&:hover': {
      //           background: transparent.main,
      //         },
      //       },
      //     },
      //   },
      // },
      // MuiMenuItem: {
      //   styleOverrides: {
      //     root: {
      //       minWidth: pxToRem(160),
      //       minHeight: 'unset',
      //       padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      //       borderRadius: borderRadius.md,
      //       fontSize: size.sm,
      //       color: text.main,
      //       transition: 'background-color 300ms ease, color 300ms ease',
      //       '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
      //         backgroundColor: light.main,
      //         color: dark.main,
      //       },
      //     },
      //   },
      // },
      // MuiSwitch: {
      //   defaultProps: {
      //     disableRipple: true,
      //   },
      //   styleOverrides: {
      //     root: {
      //       width: pxToRem(40),
      //       height: pxToRem(20),
      //       margin: `${pxToRem(4)} 0`,
      //       padding: 0,
      //       borderRadius: pxToRem(160),
      //       transition: 'transform 250ms ease-in',
      //     },
      //     switchBase: {
      //       padding: 0,
      //       top: '50%',
      //       transform: `translate(${pxToRem(2)}, -50%)`,
      //       transition: `transform 250ms ease-in-out`,
      //       '&.Mui-checked': {
      //         transform: `translate(${pxToRem(22)}, -50%)`,
      //         '& + .MuiSwitch-track': {
      //           backgroundColor: `${rgba(gradients.dark.state, 0.95)} !important`,
      //           borderColor: `${rgba(gradients.dark.state, 0.95)} !important`,
      //           opacity: 1,
      //         },
      //       },
      //       '&.Mui-disabled + .MuiSwitch-track': {
      //         opacity: '0.3 !important',
      //       },
      //       '&.Mui-focusVisible .MuiSwitch-thumb': {
      //         backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      //       },
      //     },
      //     thumb: {
      //       width: pxToRem(16),
      //       height: pxToRem(16),
      //       backgroundColor: white.main,
      //       boxShadow: md,
      //       top: '50%',
      //     },
      //     track: {
      //       backgroundColor: rgba(gradients.dark.state, 0.1),
      //       border: `${borderWidth[1]} solid ${light.main}`,
      //       borderRadius: pxToRem(160),
      //       opacity: 1,
      //       transition: `background-color 250ms ease, border-color 200ms ease`,
      //     },
      //     checked: {},
      //   },
      // },
      MuiDivider: {
        defaultProps: {
          light: true,
        },
        styleOverrides: {
          root: {
            backgroundColor: colors.transparent.main,
            backgroundImage: `linear-gradient(to right, ${rgba(colors.dark.main, 0)}, ${rgba(
              colors.dark.main,
              0.5,
            )}, ${rgba(colors.dark.main, 0)})`,
            height: pxToRem(1),
            margin: `${pxToRem(16)} 0`,
            borderBottom: 'none',
            opacity: 0.25,
          },
          vertical: {
            backgroundColor: colors.transparent.main,
            backgroundImage: `linear-gradient(to bottom, ${rgba(colors.dark.main, 0)}, ${rgba(
              colors.dark.main,
              0.5,
            )}, ${rgba(colors.dark.main, 0)})`,
            width: pxToRem(1),
            height: '100%',
            margin: `0 ${pxToRem(16)}`,
            borderRight: 'none',
          },
          light: {
            backgroundColor: colors.transparent.main,
            backgroundImage: `linear-gradient(to right, ${rgba(colors.info.main, 0)}, ${rgba(
              colors.white.main,
              1,
            )}, ${rgba(colors.white.main, 0)})`,
            '&.MuiDivider-vertical': {
              backgroundImage: `linear-gradient(to bottom, ${rgba(colors.white.main, 0)}, ${rgba(
                colors.white.main,
                0.5,
              )}, ${rgba(colors.white.main, 0)})`,
            },
          },
        },
      },
      // MuiTableContainer: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: transparent.main,
      //       boxShadow: xxl,
      //       borderRadius: borderRadius.xl,
      //       '& thead': {
      //         '& tr': {
      //           '& th': {
      //             '&:first-of-type': {
      //               paddingLeft: '0px !important',
      //             },
      //           },
      //         },
      //       },
      //       '& .MuiTableBody-root': {
      //         '& tr': {
      //           '& td': {
      //             '&:first-of-type': {
      //               paddingLeft: '0px !important',
      //               '& .MuiBox-root': {
      //                 paddingLeft: '0px !important',
      //               },
      //             },
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      // MuiTableHead: {
      //   styleOverrides: {
      //     root: {
      //       display: 'block',
      //       padding: `${pxToRem(16)} ${pxToRem(16)} 0  0`,
      //       borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
      //     },
      //   },
      // },
      // MuiTableCell: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: `${light.main} !important`,
      //       padding: `${pxToRem(12)} ${pxToRem(16)}`,
      //       '& .MuiBox-root': {
      //         pl: '0px !important',
      //       },
      //       borderBottom: `${borderWidth[1]} solid ${light.main}`,
      //     },
      //   },
      // },
      // MuiLinearProgress: {
      //   styleOverrides: {
      //     root: {
      //       height: pxToRem(3),
      //       borderRadius: borderRadius.md,
      //       overflow: 'visible',
      //       position: 'relative',
      //     },
      //     colorPrimary: {
      //       backgroundColor: light.main,
      //     },
      //     colorSecondary: {
      //       backgroundColor: light.main,
      //     },
      //     bar: {
      //       height: pxToRem(6),
      //       borderRadius: borderRadius.sm,
      //       position: 'absolute',
      //       transform: `translate(0, ${pxToRem(-1.5)}) !important`,
      //       transition: 'width 0.6s ease !important',
      //     },
      //   },
      // },
      MuiBreadcrumbs: {
        styleOverrides: {
          li: {
            lineHeight: 0,
          },
          separator: {
            fontSize: typography.size.sm,
          },
        },
      },
      // MuiSlider: {
      //   styleOverrides: {
      //     root: {
      //       width: '100%',
      //       '& .MuiSlider-active, & .Mui-focusVisible': {
      //         boxShadow: 'none !important',
      //       },
      //       '& .MuiSlider-valueLabel': {
      //         color: black.main,
      //       },
      //     },
      //     rail: {
      //       height: pxToRem(3),
      //       backgroundColor: light.main,
      //       borderRadius: borderRadius.sm,
      //     },
      //     track: {
      //       backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      //       height: pxToRem(6),
      //       position: 'relative',
      //       top: pxToRem(2),
      //       border: 'none',
      //       borderRadius: borderRadius.lg,
      //       zIndex: 1,
      //     },
      //     thumb: {
      //       width: pxToRem(15),
      //       height: pxToRem(15),
      //       backgroundColor: white.main,
      //       zIndex: 10,
      //       boxShadow: sliderBoxShadow.thumb,
      //       border: `${borderWidth[1]} solid ${sliderColors.thumb.borderColor}`,
      //       '&:hover': {
      //         boxShadow: 'none',
      //       },
      //     },
      //   },
      // },
      // MuiAvatar: {
      //   styleOverrides: {
      //     root: {
      //       transition: 'all 200ms ease-in-out',
      //     },
      //     rounded: {
      //       borderRadius: borderRadius.lg,
      //     },
      //     img: {
      //       height: 'auto',
      //     },
      //   },
      // },
      // MuiTooltip: {
      //   defaultProps: {
      //     arrow: true,
      //     TransitionComponent: Fade,
      //   },
      //   styleOverrides: {
      //     tooltip: {
      //       maxWidth: pxToRem(200),
      //       backgroundColor: black.main,
      //       color: light.main,
      //       fontSize: size.sm,
      //       fontWeight: fontWeightRegular,
      //       textAlign: 'center',
      //       borderRadius: borderRadius.md,
      //       opacity: 0.7,
      //       padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
      //     },
      //     arrow: {
      //       color: black.main,
      //     },
      //   },
      // },
      // MuiAppBar: {
      //   defaultProps: {
      //     color: 'transparent',
      //   },
      //   styleOverrides: {
      //     root: {
      //       boxShadow: 'none',
      //     },
      //   },
      // },
      MuiTabs: {
        styleOverrides: {
          root: {
            position: 'relative',
            backgroundColor: colors.background.paper,
            borderRadius: borders.borderRadius.lg,
            minHeight: 'unset',
            padding: pxToRem(4),
          },
          flexContainer: {
            height: '100%',
            position: 'relative',
            zIndex: 10,
          },
          fixed: {
            overflow: 'unset' as Property.Overflow,
            overflowX: 'unset' as Property.OverflowX,
          },
          vertical: {
            '& .MuiTabs-indicator': {
              width: '100%',
            },
          },
          indicator: {
            height: '100%',
            borderRadius: borders.borderRadius.lg,
            backgroundColor: colors.info.main,
            transition: 'all 500ms ease',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            textAlign: 'center',
            maxWidth: 'unset',
            minWidth: '150px',
            minHeight: '35px',
            fontSize: typography.size.xs,
            fontWeight: typography.fontWeightRegular,
            textTransform: 'none',
            lineHeight: 'inherit',
            padding: `${pxToRem(10)}`,
            borderRadius: borders.borderRadius.lg,
            color: colors.white.main,
            opacity: '1',
            '& .material-icons, .material-icons-round': {
              marginBottom: '0',
              marginRight: pxToRem(4),
            },
            '& svg': {
              marginBottom: '0',
              color: `#ffffff`,
              marginRight: pxToRem(6),
            },
            // '&.Mui-disabled': {
            //   pointerEvent: 'initial',
            //   cursor: 'not-allowed',
            // },
            '&.Mui-selected': {
              color: colors.white.main,
            },
          },
          labelIcon: {
            paddingTop: pxToRem(4),
          },
        },
      },
      // MuiStepper: {
      //   styleOverrides: {
      //     root: {
      //       margin: `${pxToRem(48)} 0`,
      //       padding: `0 ${pxToRem(12)}`,
      //
      //       '&.MuiPaper-root': {
      //         backgroundColor: transparent.main,
      //       },
      //     },
      //   },
      // },
      // MuiStep: {
      //   styleOverrides: {
      //     root: {
      //       padding: `0 ${pxToRem(6)}`,
      //     },
      //   },
      // },
      // MuiStepConnector: {
      //   styleOverrides: {
      //     root: {
      //       color: borderColor.white,
      //       transition: 'all 200ms linear',
      //       '&.Mui-active': {
      //         color: dark.main,
      //       },
      //       '&.Mui-completed': {
      //         color: dark.main,
      //       },
      //     },
      //     alternativeLabel: {
      //       top: '14%',
      //       left: '-50%',
      //       right: '50%',
      //     },
      //     line: {
      //       borderWidth: `${borderWidth[2]} !important`,
      //       borderColor: 'currentColor',
      //     },
      //   },
      // },
      // MuiStepLabel: {
      //   styleOverrides: {
      //     label: {
      //       marginTop: `${pxToRem(8)} !important`,
      //       fontWeight: fontWeightRegular,
      //       fontSize: size.md,
      //       color: grey[300],
      //
      //       '&.Mui-active': {
      //         fontWeight: `${fontWeightRegular} !important`,
      //         color: `${dark.main} !important`,
      //       },
      //
      //       '&.Mui-completed': {
      //         fontWeight: `${fontWeightRegular} !important`,
      //         color: `${text.main} !important`,
      //       },
      //     },
      //   },
      // },
      // MuiStepIcon: {
      //   styleOverrides: {
      //     root: {
      //       background: white.main,
      //       fill: white.main,
      //       stroke: white.main,
      //       strokeWidth: pxToRem(10),
      //       width: pxToRem(13),
      //       height: pxToRem(13),
      //       border: `${borderWidth[2]} solid ${borderColor}`,
      //       borderRadius: '50%',
      //       zIndex: 99,
      //       transition: 'all 200ms linear',
      //
      //       '&.Mui-active': {
      //         background: dark.main,
      //         fill: dark.main,
      //         stroke: dark.main,
      //         borderColor: dark.main,
      //         boxShadow: boxShadow([0, 0], [0, 2], dark.main, 1),
      //       },
      //
      //       '&.Mui-completed': {
      //         background: dark.main,
      //         fill: dark.main,
      //         stroke: dark.main,
      //         borderColor: dark.main,
      //         boxShadow: boxShadow([0, 0], [0, 2], dark.main, 1),
      //       },
      //     },
      //   },
      // },
      // MuiSelect: {
      //   styleOverrides: {
      //     select: {
      //       display: 'grid',
      //       alignItems: 'center',
      //       padding: `0 ${pxToRem(12)} !important`,
      //
      //       '& .Mui-selected': {
      //         backgroundColor: transparent.main,
      //       },
      //     },
      //     // TODO CHECK THIS
      //     // selectMenu: {
      //     //   background: 'none',
      //     //   height: 'none',
      //     //   minHeight: 'none',
      //     //   overflow: 'unset',
      //     // },
      //     icon: {
      //       display: 'none',
      //     },
      //   },
      // },
      // MuiFormControlLabel: {
      //   styleOverrides: {
      //     root: {
      //       display: 'block',
      //       minHeight: pxToRem(24),
      //       marginBottom: pxToRem(2),
      //     },
      //     label: {
      //       display: 'inline-block',
      //       fontSize: size.sm,
      //       fontWeight: fontWeightBold,
      //       color: dark.main,
      //       lineHeight: 1,
      //       transform: `translateY(${pxToRem(1)})`,
      //       marginLeft: pxToRem(4),
      //       '&.Mui-disabled': {
      //         color: dark.main,
      //       },
      //     },
      //   },
      // },
      // MuiFormLabel: {
      //   styleOverrides: {
      //     root: {
      //       color: dark.main,
      //     },
      //   },
      // },
      // MuiCheckbox: {
      //   styleOverrides: {
      //     root: {
      //       backgroundPosition: 'center',
      //       backgroundSize: 'contain',
      //       backgroundRepeat: 'no-repeat',
      //       width: pxToRem(20),
      //       height: pxToRem(20),
      //       marginRight: pxToRem(6),
      //       padding: 0,
      //       color: transparent.main,
      //       border: `${borderWidth[1]} solid ${borderColor}`,
      //       borderRadius: pxToRem(5.6),
      //       transition: 'all 250ms ease',
      //       '&:hover': {
      //         backgroundColor: transparent.main,
      //       },
      //       '& .MuiSvgIcon-root': {
      //         fill: transparent.main,
      //       },
      //       '&.Mui-focusVisible': {
      //         border: `${borderWidth[2]} solid ${info.main} !important`,
      //       },
      //     },
      //     colorPrimary: {
      //       backgroundColor: transparent.main,
      //       '&.Mui-checked': {
      //         backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
      //           gradients.dark.main,
      //           gradients.dark.state,
      //         )}`,
      //         borderColor: gradients.dark.main,
      //       },
      //       '&:hover': {
      //         backgroundColor: transparent.main,
      //       },
      //     },
      //     colorSecondary: {
      //       backgroundColor: transparent.main,
      //       '&.Mui-checked': {
      //         backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
      //           gradients.dark.main,
      //           gradients.dark.state,
      //         )}`,
      //         borderColor: gradients.dark.main,
      //       },
      //       '&:hover': {
      //         backgroundColor: transparent.main,
      //       },
      //     },
      //   },
      // },
      // MuiRadio: {
      //   styleOverrides: {
      //     root: {
      //       backgroundPosition: 'center',
      //       backgroundSize: 'contain',
      //       backgroundRepeat: 'no-repeat',
      //       width: pxToRem(20),
      //       height: pxToRem(20),
      //       marginRight: pxToRem(6),
      //       padding: 0,
      //       color: transparent.main,
      //       border: `${borderWidth[1]} solid ${borderColor}`,
      //       borderRadius: '50%',
      //       transition: 'all 250ms ease',
      //
      //       '&:hover': {
      //         backgroundColor: transparent.main,
      //       },
      //
      //       '& .MuiSvgIcon-root': {
      //         fill: transparent.main,
      //       },
      //
      //       '&.Mui-focusVisible': {
      //         border: `${borderWidth[2]} solid ${info.main} !important`,
      //       },
      //     },
      //     colorPrimary: {
      //       backgroundColor: transparent.main,
      //       '&.Mui-checked': {
      //         backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
      //           gradients.dark.main,
      //           gradients.dark.state,
      //         )}`,
      //         borderColor: gradients.dark.main,
      //       },
      //       '&:hover': {
      //         backgroundColor: transparent.main,
      //       },
      //     },
      //     colorSecondary: {
      //       backgroundColor: transparent.main,
      //       '&.Mui-checked': {
      //         backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
      //           gradients.dark.main,
      //           gradients.dark.state,
      //         )}`,
      //         borderColor: gradients.dark.main,
      //       },
      //       '&:hover': {
      //         backgroundColor: transparent.main,
      //       },
      //     },
      //   },
      // },
      // MuiAutocomplete: {
      //   styleOverrides: {
      //     popper: {
      //       boxShadow: lg,
      //       padding: pxToRem(8),
      //       fontSize: size.sm,
      //       color: text.main,
      //       textAlign: 'left',
      //       backgroundColor: `${white.main} !important`,
      //       borderRadius: borderRadius.md,
      //     },
      //     paper: {
      //       boxShadow: 'none',
      //       backgroundColor: transparent.main,
      //     },
      //     option: {
      //       padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      //       borderRadius: borderRadius.md,
      //       fontSize: size.sm,
      //       color: text.main,
      //       transition: 'background-color 300ms ease, color 300ms ease',
      //       '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
      //         backgroundColor: light.main,
      //         color: dark.main,
      //       },
      //       '&[aria-selected="true"]': {
      //         backgroundColor: `${light.main} !important`,
      //         color: `${dark.main} !important`,
      //       },
      //     },
      //     noOptions: {
      //       fontSize: size.sm,
      //       color: text.main,
      //     },
      //     groupLabel: {
      //       color: dark.main,
      //     },
      //     loading: {
      //       fontSize: size.sm,
      //       color: text.main,
      //     },
      //     tag: {
      //       display: 'flex',
      //       alignItems: 'center',
      //       height: 'auto',
      //       padding: pxToRem(4),
      //       backgroundColor: gradients.dark.state,
      //       color: white.main,
      //       '& .MuiChip-label': {
      //         lineHeight: 1.2,
      //         padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
      //       },
      //       '& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus': {
      //         color: white.main,
      //         marginRight: 0,
      //       },
      //     },
      //   },
      // },
      // MuiInput: { ...input },
      // MuiOutlinedInput: { ...input },
      // MuiFilledInput: { ...input },
      // MuiPopover: {
      //   styleOverrides: {
      //     paper: {
      //       backgroundColor: transparent.main,
      //       boxShadow: lg,
      //       padding: pxToRem(8),
      //       borderRadius: borderRadius.lg,
      //     },
      //   },
      // },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      // MuiIcon: {
      //   defaultProps: {
      //     baseClassName: 'material-icons-round',
      //     fontSize: 'inherit',
      //   },
      //   styleOverrides: {
      //     fontSizeInherit: {
      //       fontSize: 'inherit !important',
      //     },
      //     fontSizeSmall: {
      //       fontSize: `${pxToRem(20)} !important`,
      //     },
      //     fontSizeLarge: {
      //       fontSize: `${pxToRem(36)} !important`,
      //     },
      //   },
      // },
      // MuiSvgIcon: {
      //   defaultProps: {
      //     fontSize: 'inherit',
      //   },
      //   styleOverrides: {
      //     fontSizeInherit: {
      //       fontSize: 'inherit !important',
      //     },
      //     fontSizeSmall: {
      //       fontSize: `${pxToRem(20)} !important`,
      //     },
      //     fontSizeLarge: {
      //       fontSize: `${pxToRem(36)} !important`,
      //     },
      //   },
      // },
      // MuiLink: {
      //   defaultProps: {
      //     underline: 'none',
      //     color: 'inherit',
      //   },
      // },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: borders.borderRadius.xl,
            boxShadow: boxShadows.xxl,
            backgroundImage: linearGradient(
              colors.gradients.card.main,
              colors.gradients.card.state,
              colors.gradients.card.deg,
            ),
          },
          paperFullScreen: {
            borderRadius: 0,
          },
        },
        defaultProps: {
          maxWidth: 'lg',
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: pxToRem(16),
            fontSize: typography.size.xl,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: pxToRem(16),
            fontSize: typography.size.md,
            color: colors.text.primary,
          },
          dividers: {
            borderTop: `${borders.borderWidth[1]} solid ${colors.divider}`,
            borderBottom: `${borders.borderWidth[1]} solid ${colors.divider}`,
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontSize: typography.size.md,
            color: colors.text.primary,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: pxToRem(16),
          },
        },
      },
    },
  };
};
