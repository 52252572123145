import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOptionsState } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  PromptInfoExtendsFragment,
  SortOrder,
  usePromptsByUserIdWithPaginationLazyQuery,
  UserInfoFragment,
  useUsersQuery,
} from '@app/graphql/generated';

import { MdBox } from '../../look';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';

import { PromptsTable } from './PromptsTable';

type DateRange = {
  from: Date;
  to: Date;
};

const today = new Date();
const weekAgo = sub(today, { days: 7 });

const filter = createFilterOptions<UserInfoFragment>();

export function PromptHistoryView() {
  const { data } = useUsersQuery();
  const { t } = useTranslation('prompt');
  const { enqueueSnackbar } = useSnackbar();
  const [dateRange, setDateRange] = React.useState<DateRange>({ from: startOfDay(weekAgo), to: endOfDay(today) });
  const [currentUser, setCurrentUser] = React.useState<UserInfoFragment | null>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [prompts, setPrompts] = React.useState<Array<PromptInfoExtendsFragment>>([]);
  const [promptsTotalCount, setPromptsTotalCount] = React.useState(0);

  const [getPromptsQuery] = usePromptsByUserIdWithPaginationLazyQuery({
    onCompleted: (response) => {
      if (response.prompts) {
        setPrompts(response.prompts);
      }

      if (response.aggregatePrompt._count?.id) {
        setPromptsTotalCount(response.aggregatePrompt._count.id);
      }
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (prompts.length === 0) {
      setPromptsTotalCount(0);
    }
  }, [prompts]);

  React.useEffect(() => {
    if (currentUser && !Number.isNaN(dateRange.from.getTime()) && !Number.isNaN(dateRange.to.getTime())) {
      const skip = page * rowsPerPage;

      getPromptsQuery({
        variables: {
          userId: currentUser.id,
          from: dateRange.from,
          to: dateRange.to,
          take: rowsPerPage,
          skip,
          orderBy: [{ createdAt: SortOrder.Desc }],
        },
      }).catch(console.error);
    } else {
      setPrompts([]);
    }
  }, [currentUser, dateRange.from, dateRange.to, getPromptsQuery, page, rowsPerPage]);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(Number(event.target.value));
  };

  const handleDateFrom = React.useCallback((value: Date | null) => {
    if (value) {
      setDateRange((prev) => ({ ...prev, from: startOfDay(value) }));
    }
  }, []);

  const handleDateTo = React.useCallback((value: Date | null) => {
    if (value) {
      setDateRange((prev) => ({ ...prev, to: endOfDay(value) }));
    }
  }, []);

  const handleFilter = React.useCallback(
    (options: Array<UserInfoFragment>, params: FilterOptionsState<UserInfoFragment>) => filter(options, params),
    [],
  );

  return (
    <>
      <Paper
        elevation={2}
        sx={(theme) => ({
          padding: 3,
          borderRadius: theme.customization.borderRadius,
        })}
      >
        <Grid container spacing={1}>
          <Grid item xl={7} xs={12}>
            <Stack spacing={3} direction="row" justifyContent="flex-start" sx={{ width: '100%' }}>
              <Autocomplete
                disablePortal
                id="users"
                options={data?.users || []}
                value={currentUser}
                onChange={(_, value: UserInfoFragment | null) => setCurrentUser(value)}
                filterOptions={handleFilter}
                getOptionLabel={(value) => value.email}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={t('history.users')} />}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={dateRange.from}
                  onChange={handleDateFrom}
                  getOpenDialogAriaText={(date) => date.toString()}
                  getViewSwitchingButtonText={(currentView) => currentView.toString()}
                  renderInput={(params) => <TextField {...params} id="from" label="From" type="date" />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={dateRange.to}
                  onChange={handleDateTo}
                  getOpenDialogAriaText={(date) => date?.toString() || ''}
                  getViewSwitchingButtonText={(currentView) => currentView.toString()}
                  renderInput={(params) => <TextField {...params} id="to" label="To" type="date" />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xl={5} xs={12}>
            <TablePagination
              sx={{ overflow: 'hidden' }}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component={MdBox}
              count={promptsTotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={(theme) => ({
          padding: 3,
          marginTop: 3,
          height: 'calc(100vh - 272px)',
          borderRadius: theme.customization.borderRadius,
        })}
      >
        <PromptsTable prompts={prompts} />
      </Paper>
    </>
  );
}
