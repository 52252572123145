import styled from '@emotion/styled';

type Props = {
  width: number | string;
  height: number | string;
};

export const CircleWrapper = styled.div<Props>`
  position: relative;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
`;
