import React from 'react';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { Button, Dialog, IconButton } from '@app/modules/look';

import { Label } from './Label';

type Props = {
  promptVariables: Record<string, string>;
  handlePromptVariables: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

export function VariablesPanel(props: Props) {
  const { promptVariables, handlePromptVariables } = props;
  const { t } = useTranslation('prompt');
  const { enqueueSnackbar } = useSnackbar();
  const clipboard = useClipboard();
  const [open, setOpen] = React.useState<boolean>(false);
  const [newVariableKey, setNewVariableKey] = React.useState<string>('');

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setNewVariableKey('');
  }, []);

  const copyVariable = (value: string) => {
    clipboard.copy(`{{${value}}}`);
    enqueueSnackbar('copied!', { variant: 'success' });
  };

  const addVariable = React.useCallback(
    (key: string) => {
      const newKey = key.replace(/ /g, '');
      handlePromptVariables((prev) => ({ ...prev, [newKey]: '' }));
      handleClose();
    },
    [handleClose, handlePromptVariables],
  );

  const removeVariable = React.useCallback(
    (key: string) => {
      handlePromptVariables((prev) => {
        const copy = { ...prev };

        delete copy[key];

        return copy;
      });
    },
    [handlePromptVariables],
  );

  const handleVariableValueChange = React.useCallback(
    (key: string, value: string) => {
      handlePromptVariables((prev) => {
        const copy = { ...prev };
        copy[key] = value;

        return copy;
      });
    },
    [handlePromptVariables],
  );

  return (
    <>
      <Label label={t('playground.variables.title')} description={t('playground.variables.description')} />
      {Object.entries(promptVariables).map(([key, value]) => (
        <Stack direction="row" spacing={3} mb={2} p={1} alignItems="center">
          <IconButton onClick={() => copyVariable(key)}>
            <ContentCopyIcon />
          </IconButton>
          <TextField disabled size="small" sx={{ maxWidth: '25%' }} label={t('playground.variables.key')} value={key} />
          <TextField
            multiline
            maxRows={5}
            size="small"
            fullWidth
            label={t('playground.variables.value')}
            value={value}
            onChange={(event) => handleVariableValueChange(key, event.target.value)}
          />
          <IconButton onClick={() => removeVariable(key)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}
      <Button onClick={handleOpen}>
        <AddCircleIcon sx={{ mr: '5px' }} />
        <span>Add</span>
      </Button>
      <Dialog
        title={t('playground.variables.addDialog')}
        actions={
          <Button disabled={newVariableKey === ''} onClick={() => addVariable(newVariableKey)}>
            {t('playground.variables.addVariable')}
          </Button>
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <TextField
          id="Key"
          name="key"
          fullWidth
          required
          label={t('playground.variables.key')}
          value={newVariableKey}
          onChange={(event) => setNewVariableKey(event.target.value)}
          inputProps={{ maxLength: '50' }}
        />
      </Dialog>
    </>
  );
}
