import React from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useGetPromptPresetByIdLazyQuery, usePredictMutation } from '@app/graphql/generated';
import { useCurrentUser } from '@app/hooks/useCurrentUser';
import { useForm } from '@app/hooks/useForm';

import { promptToCreateMapper, promptToFormPromptMapper } from '../mappers';
import { FormPrompt, PromptResponse } from '../types';

import { MemoSlateEditor } from './Editor/SlateEditor';
import { Label } from './Label';
import { PresetPanel } from './PresetPanel';
import { SettingItem } from './SettingItem';
import { TokensCounter } from './TokensCounter';
import { VariablesPanel } from './VariablesPanel';

const filter = createFilterOptions<string>();
const getPromptPresetId = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get('id');
};

const validationSchema = yup.object().shape({
  prompt: yup.string().required('Prompt is required.'),
  stop: yup.array().max(4, 'Stop sequences shouldn`t have more than 4 elements.'),
});

type Props = {
  playgroundPrompt: FormPrompt;
  handlePlaygroundPrompt: (field: keyof FormPrompt, value: any) => void;
};

export function Playground(props: Props) {
  const { playgroundPrompt, handlePlaygroundPrompt } = props;
  const { t } = useTranslation('prompt');
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const [promptPresetId] = React.useState(getPromptPresetId());
  const [promptVariables, setPromptVariables] = React.useState<Record<string, string>>({});
  const [isPromptTouched, setIsPromptTouched] = React.useState<boolean>(false);
  const promptResponse = React.useRef<PromptResponse>({ text: '' });

  const [predictMutation] = usePredictMutation();

  const formik = useForm<FormPrompt, typeof validationSchema>({
    initialValues: playgroundPrompt,
    validationSchema,
    async onSubmit(values, formikHelpers) {
      promptResponse.current = { text: '' };

      return predictMutation({
        variables: {
          data: {
            ...promptToCreateMapper(values),
            variables: promptVariables,
            project: {
              connect: {
                id: currentUser?.project[0].id,
              },
            },
          },
        },
        onCompleted: (data) => {
          if (data) {
            const response = data.predict.join('');
            promptResponse.current = { text: response };
          }

          enqueueSnackbar(t('success'), { variant: 'success' });
          formikHelpers.setSubmitting(false);
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
          formikHelpers.setSubmitting(false);
        },
      });
    },
  });

  const [getPromptPresetByIdQuery, { loading }] = useGetPromptPresetByIdLazyQuery({
    onCompleted: ({ getPromptPresetById }) => {
      if (getPromptPresetById) {
        formik.setValues(promptToFormPromptMapper(getPromptPresetById.prompt));
        promptResponse.current = { text: getPromptPresetById.prompt.prompt, isPreset: true };
      }
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  });

  React.useEffect(() => {
    if (promptPresetId) {
      getPromptPresetByIdQuery({ variables: { id: promptPresetId } }).catch(console.error);
    }
  }, [getPromptPresetByIdQuery, promptPresetId]);

  const [inputHeight, setInputHeight] = React.useState(400);
  const paperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (paperRef.current) {
      setInputHeight(paperRef.current.offsetHeight - 185);
    }
  }, []);

  const handleSliderChange = (field: keyof FormPrompt) => (_event: Event, value: number | Array<number>) => {
    formik.setFieldValue(field, value);
    handlePlaygroundPrompt(field, value);
  };

  const handleChange = React.useCallback((value: string) => {
    setIsPromptTouched(true);
    formik.setFieldValue('prompt', value);
    handlePlaygroundPrompt('prompt', value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container component="form" id="promptForm" name="promptForm" spacing={3} onSubmit={formik.handleSubmit}>
      <Grid item xs={7}>
        <Paper
          elevation={3}
          sx={(theme) => ({
            padding: 3,
            position: 'relative',
            borderRadius: theme.customization.borderRadius,
          })}
        >
          <PresetPanel
            setValues={formik.setValues}
            setVariables={setPromptVariables}
            prompt={{ ...formik.values, variables: promptVariables }}
            newPromptTextRef={promptResponse}
            isPromptTouched={isPromptTouched}
            handleIsPromptTouched={setIsPromptTouched}
          />
          <Stack spacing={3}>
            <MemoSlateEditor
              text={formik.values.prompt}
              textChunk={promptResponse.current}
              onChange={handleChange}
              height={inputHeight}
            />
            <TokensCounter prompt={formik.values.prompt} promptResponse={promptResponse.current.text} />
            <When condition={formik.isSubmitting || loading}>
              <LinearProgress />
            </When>
            <Button type="submit" disabled={formik.isSubmitting || loading}>
              {t('playground.submit')}
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper
          ref={paperRef}
          elevation={3}
          sx={(theme) => ({
            mb: 1,
            p: 3,
            borderRadius: theme.customization.borderRadius,
          })}
        >
          <VariablesPanel promptVariables={promptVariables} handlePromptVariables={setPromptVariables} />
        </Paper>
        <Paper
          ref={paperRef}
          elevation={3}
          sx={(theme) => ({
            p: 3,
            borderRadius: theme.customization.borderRadius,
          })}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Label label={t('playground.stopSequences')} description={t('playground.stopSequencesInfo')} />
              <Autocomplete
                id="stopSequences"
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                multiple
                fullWidth
                value={formik.values.stop}
                options={formik.values.stop}
                onChange={(_, values) => {
                  formik.setFieldValue('stop', values);
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.includes(inputValue);
                  if (inputValue !== '' && !isExisting) {
                    options.push(inputValue);
                  }
                  return filtered;
                }}
                getOptionLabel={(option) => option}
                renderOption={(renderProps, option) => <li {...renderProps}> {option} </li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    error={formik.touched.stop && Boolean(formik.errors.stop)}
                    helperText={formik.touched.stop && formik.errors.stop}
                  />
                )}
              />
            </Grid>
            <SettingItem
              id="temperature"
              name="temperature"
              value={formik.values.temperature}
              label={t('playground.temperature')}
              description={t('playground.temperatureInfo')}
              min={0}
              max={1}
              step={0.01}
              handleSliderChange={handleSliderChange('temperature')}
              onChange={formik.handleChange}
            />
            <SettingItem
              id="maxTokens"
              name="maxTokens"
              value={formik.values.maxTokens}
              label={t('playground.maxLength')}
              description={t('playground.maxLengthInfo')}
              min={1}
              max={4000}
              step={1}
              handleSliderChange={handleSliderChange('maxTokens')}
              onChange={formik.handleChange}
            />
            <SettingItem
              id="topP"
              name="topP"
              value={formik.values.topP}
              label={t('playground.topP')}
              description={t('playground.topPInfo')}
              min={0}
              max={1}
              step={0.01}
              handleSliderChange={handleSliderChange('topP')}
              onChange={formik.handleChange}
            />
            <SettingItem
              id="frequencyPenalty"
              name="frequencyPenalty"
              value={formik.values.frequencyPenalty}
              label={t('playground.frequencyPenalty')}
              description={t('playground.frequencyPenaltyInfo')}
              min={0}
              max={2}
              step={0.01}
              handleSliderChange={handleSliderChange('frequencyPenalty')}
              onChange={formik.handleChange}
            />
            <SettingItem
              id="presencePenalty"
              name="presencePenalty"
              value={formik.values.presencePenalty}
              label={t('playground.presencePenalty')}
              description={t('playground.presencePenaltyInfo')}
              min={0}
              max={2}
              step={0.01}
              handleSliderChange={handleSliderChange('presencePenalty')}
              onChange={formik.handleChange}
            />
            <SettingItem
              id="bestOf"
              name="bestOf"
              value={formik.values.bestOf}
              label={t('playground.bestOf')}
              description={t('playground.bestOfInfo')}
              min={1}
              max={20}
              step={1}
              handleSliderChange={handleSliderChange('bestOf')}
              onChange={formik.handleChange}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
