export const app = {
  name: 'Cerebrate',
  favicon: '/logo.svg',
  logo: '/logo.svg',
  basename: 'https://cerebrate.ai',
  redirectPathOnLoggedIn: '/prompt',
  logging: {
    debugSQL: false,
    level: process.env.IS_DEV ? 'debug' : 'info',
    apolloLogging: process.env.IS_DEV,
    // apolloLogging: false,
  },
  // Check here for Windows and Mac OS X: https://code.visualstudio.com/docs/editor/command-line#_opening-vs-code-with-urls
  // Use this protocol handler for Linux: https://github.com/sysgears/vscode-handler
  stackFragmentFormat: 'vscode://file/{0}:{1}:{2}',
};
