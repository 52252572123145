import { Prompt } from '@app/graphql/generated';

export type PromptResponse = { text: string; isPreset?: boolean };
export type FormPrompt = Omit<
  Prompt,
  | '__typename'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
  | 'id'
  | 'stop'
  | 'project'
  | 'projectId'
  | 'promptPreset'
  | 'promptResponse'
  | 'hasError'
> & { stop: Array<string> };
export type FormPreset = { name: string; description?: string };
export type PredictChatQuestion = { question: string };
export enum TokenModel {
  gpt3,
  codex,
}
