import { NODE_VARS } from './variables';

export const mailer = {
  host: NODE_VARS.EMAIL_HOST,
  port: NODE_VARS.EMAIL_PORT,
  auth: {
    user: NODE_VARS.EMAIL_USER,
    pass: NODE_VARS.EMAIL_PASSWORD,
  },
};
