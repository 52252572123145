import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { app } from '@mdca/config';

import { Layout } from '../../look';
import { PromptView } from '../components/PromptView';

export default function Prompt() {
  const { t } = useTranslation('prompt');

  return (
    <Layout>
      <Helmet
        title={`${app.name} - ${t('title')}`}
        meta={[{ name: 'description', content: `${app.name} - ${t('meta')}` }]}
      />
      <PromptView />
    </Layout>
  );
}
