import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab, Fade } from '@mui/material';
import Box from '@mui/material/Box';

type Props = {
  trigger: boolean;
};

const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

  if (anchor) {
    anchor.scrollIntoView({
      block: 'center',
    });
  }
};

export function ScrollToTop(props: Props) {
  const { trigger } = props;

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 25, right: 25 }}>
        <Fab size="small" color="primary" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Fade>
  );
}
