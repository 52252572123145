import React from 'react';
import { TableRowProps } from '@mui/material';

import { StyledTableRow } from './MdTableRowRoot';

type Props = {
  isZebra?: boolean;
};

export const MdTableRow = React.forwardRef<HTMLTableRowElement, React.PropsWithChildren<TableRowProps<any, Props>>>(
  ({ isZebra = false, ...other }, ref) => <StyledTableRow props={{ isZebra }} {...other} ref={ref} />,
);
