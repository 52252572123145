import React from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PromptInfoFragment } from '@app/graphql/generated';

import { RenderPromptField } from './RenderPromptField';

type Props = {
  open: boolean;
  prompt: PromptInfoFragment;
};

export function PresetCollapse({ open, prompt }: Props) {
  const { t } = useTranslation('prompt');

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Table aria-label="prompt table">
        <TableHead>
          <TableRow>
            <TableCell>{t('playground.title')}</TableCell>
            <TableCell>{t('playground.stopSequences')}</TableCell>
            <TableCell>{t('playground.temperature')}</TableCell>
            <TableCell>{t('playground.maxLength')}</TableCell>
            <TableCell>{t('playground.topP')}</TableCell>
            <TableCell>{t('playground.frequencyPenalty')}</TableCell>
            <TableCell>{t('playground.presencePenalty')}</TableCell>
            <TableCell>{t('playground.bestOf')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderPromptField value={prompt.prompt} />
          <RenderPromptField value={prompt.stop.split(',').join(', ')} />
          <RenderPromptField value={prompt.temperature} />
          <RenderPromptField value={prompt.maxTokens} />
          <RenderPromptField value={prompt.topP} />
          <RenderPromptField value={prompt.frequencyPenalty} />
          <RenderPromptField value={prompt.presencePenalty} />
          <RenderPromptField value={prompt.bestOf} />
        </TableBody>
      </Table>
    </Collapse>
  );
}
