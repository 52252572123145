import i18next, { i18n as I18N, InitOptions, Resource } from 'i18next';

import layout from '../layout/locales';
import notFound from '../page-not-found/locales';
import prompt from '../prompt/locales';
import user from '../user/locales';

/**
 * Adds resources into the i18next bundle
 *
 * @param i18param - i18next
 * @param resources - The resources to add
 */
const addResources = (i18param: I18N, resources: Array<{ ns: string; resources: Resource }> | undefined) => {
  if (resources) {
    for (const localization of resources) {
      for (const lang of Object.keys(localization.resources)) {
        const resource = (i18param.options as InitOptions & { whitelist: string[] }).whitelist.filter((lng: string) =>
          lng.includes(lang),
        );
        if (resource.length > 0) {
          i18param.addResourceBundle(resource[0], localization.ns, localization.resources[lang]);
        }
      }
    }
  }
};

export function loadResourcesI18n() {
  addResources(i18next, [
    { ns: 'layout', resources: layout },
    { ns: 'notFound', resources: notFound },
    { ns: 'user', resources: user },
    { ns: 'prompt', resources: prompt },
  ]);
}
