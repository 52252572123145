// if (NODE_VARS.NODE_ENV === 'test') {
//   db.client = 'sqlite3';
// }
//
// if (db.client === 'sqlite3' && NODE_VARS.IS_SERVER) {
//   db.connection = {
//     test: {
//       filename: ':memory:',
//     },
//   }[NODE_VARS.NODE_ENV || 'development'];
//   db.pool = {
//     afterCreate: (conn, cb) => {
//       conn.run('PRAGMA foreign_keys = ON', cb);
//     },
//   };
// }
import { NODE_VARS } from './variables';

export const db = {
  type: NODE_VARS.DB_TYPE,
  host: NODE_VARS.DB_HOST,
  port: NODE_VARS.DB_PORT,
  user: NODE_VARS.DB_USER,
  password: NODE_VARS.DB_PASSWORD,
  database: NODE_VARS.DB_DATABASE,
  ssl: NODE_VARS.DB_SSL,
  charset: 'utf8',
  synchronize: false,
  logging: false,
};
