import React from 'react';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { Label } from './Label';

type SettingItemProps = {
  id: string;
  name: string;
  value: any;
  label: string;
  description: string;
  min: number;
  max: number;
  step: number;
  handleSliderChange: (_event: Event, value: number | Array<number>) => void;
  onChange: (_event: React.ChangeEvent<any>) => void;
};

export function SettingItem(props: SettingItemProps) {
  const { id, name, value, label, description, min, max, step, handleSliderChange, onChange } = props;

  return (
    <Grid item xs={12}>
      <Label label={label} description={description} />
      <Stack direction="row" alignItems="center" spacing={2} pl={1}>
        <Slider
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
        />
        <TextField
          id={id}
          name={name}
          value={value}
          size="small"
          onChange={onChange}
          type="number"
          inputProps={{ min, max, step }}
          sx={{ minWidth: '90px' }}
        />
      </Stack>
    </Grid>
  );
}
