import React from 'react';
import { useTranslation } from 'react-i18next';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { NavLink } from '../../look';

export function PageNotFoundView() {
  const { t } = useTranslation('notFound');

  return (
    <Container
      sx={{
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '90px',
      }}
    >
      <Box>
        <DesktopAccessDisabledIcon color="action" sx={{ width: '400px', height: '200px' }} />
      </Box>
      <Typography sx={{ my: 3 }} variant="h2">
        {t('content')} - 404
      </Typography>
      <NavLink to="/">
        <Button color="primary">{t('btnHome')}</Button>
      </NavLink>
    </Container>
  );
}
