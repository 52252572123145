import getOr from 'lodash/fp/getOr';

type InputValue = object | null | undefined;
type MaybeValue<T> = T | null;
export type Selector<T> = (obj: InputValue) => MaybeValue<T>;

export const createSelector = (path: [never]): ((obj: InputValue) => any) => getOr(null)(path);
export const createSelectorWithFallbackPath =
  <T>(fallbackPath: [never], path: [never]): Selector<T> =>
  (obj: InputValue) => {
    const firstTry = getOr<MaybeValue<T>>(null)(path)(obj);
    let secondTry = null;

    if (firstTry) {
      secondTry = getOr<MaybeValue<T>>(null)(fallbackPath)(obj);
    }
    return firstTry || secondTry;
  };
