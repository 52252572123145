import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { Theme } from '@mui/material';
import useTheme from '@mui/styles/useTheme';

type Props = {
  children: React.ReactElement;
  onScroll?: () => void;
  width?: number;
  height?: string;
  top?: number;
  right?: number;
  margin?: string;
  noScrollX?: boolean;
};

export const MdScrollbar = React.forwardRef((props: Props, ref) => {
  const { children, width = 9, height = '100%', right = 1, top = 0, margin, ...otherProps } = props;
  const theme = useTheme<Theme>();

  return (
    <Scrollbar
      trackYProps={{ style: { width: width + 1, height, top, right } }}
      thumbYProps={{
        style: { width, margin, background: theme.palette.divider },
      }}
      {...otherProps}
      // @ts-expect-error
      ref={ref}
    >
      {children}
    </Scrollbar>
  );
});
