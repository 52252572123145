import React from 'react';
import BaseLinearProgress from '@mui/material/LinearProgress';
import styled from '@mui/material/styles/styled';

const LoaderWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1301,
  width: '100%',
});

export function LinearProgress() {
  return (
    <LoaderWrapper>
      <BaseLinearProgress color="primary" />
    </LoaderWrapper>
  );
}
