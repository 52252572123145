import React from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { usePredictChatMutation } from '@app/graphql/generated';
import { useForm } from '@app/hooks/useForm';
import { TokensCounter } from '@app/modules/prompt/components/TokensCounter';

import { PredictChatQuestion, PromptResponse } from '../types';

import { MemoSlateEditor } from './Editor/SlateEditor';

const validationSchema = yup.object().shape({
  question: yup.string().required('Prompt is required.'),
});

type Props = {
  articlePrompt: PredictChatQuestion;
  handleArticlePrompt: (value: string) => void;
};
export function PlaygroundChat(props: Props) {
  const { articlePrompt, handleArticlePrompt } = props;
  const { t } = useTranslation('prompt');
  const { enqueueSnackbar } = useSnackbar();
  // const currentUser = useCurrentUser();
  const promptResponse = React.useRef<PromptResponse>({ text: '' });

  const [predictChatMutation] = usePredictChatMutation();

  const formik = useForm<PredictChatQuestion, typeof validationSchema>({
    initialValues: articlePrompt,
    validationSchema,
    async onSubmit(values, formikHelpers) {
      promptResponse.current = { text: '' };

      return predictChatMutation({
        variables: { question: values.question },
        onCompleted: (data) => {
          if (data) {
            const response = data.predictChat;
            promptResponse.current = { text: response };
          }

          enqueueSnackbar(t('success'), { variant: 'success' });
          formikHelpers.setSubmitting(false);
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
          formikHelpers.setSubmitting(false);
        },
      });
    },
  });

  const [inputHeight, setInputHeight] = React.useState(400);
  const paperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (paperRef.current) {
      setInputHeight(paperRef.current.offsetHeight - 185);
    }
  }, []);

  const handleChange = React.useCallback((value: string) => {
    formik.setFieldValue('question', value);
    handleArticlePrompt(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container component="form" id="promptForm" name="promptForm" spacing={3} onSubmit={formik.handleSubmit}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={(theme) => ({
            padding: 3,
            position: 'relative',
            borderRadius: theme.customization.borderRadius,
          })}
        >
          <Stack spacing={3}>
            <MemoSlateEditor
              text={formik.values.question}
              textChunk={promptResponse.current}
              onChange={handleChange}
              height={inputHeight}
            />
            <TokensCounter prompt={formik.values.question} promptResponse={promptResponse.current.text} />
            <When condition={formik.isSubmitting}>
              <LinearProgress />
            </When>
            <Button type="submit" disabled={formik.isSubmitting}>
              {t('playground.submit')}
            </Button>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}
