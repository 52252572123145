import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';

import { PromptPresetInfoFragment, usePromptPresetsByUserUidQuery } from '@app/graphql/generated';

import { LoadingBackdrop, MdBox, MdScrollbar } from '../../look';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';

import { PresetRow } from './PresetRow';

export function PresetsView() {
  const { t } = useTranslation('prompt');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const [presets, setPresets] = React.useState<Array<PromptPresetInfoFragment>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { loading } = usePromptPresetsByUserUidQuery({
    variables: { uid: user?.sub as string },
    onCompleted: ({ promptPresets }) => setPresets(promptPresets),
    onError: (error) => {
      enqueueSnackbar(error.graphQLErrors[0].message, { variant: 'error' });
    },
  });

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <Paper
      elevation={3}
      sx={(theme) => ({
        minHeight: '400px',
        height: 'calc(100vh - 140px)',
        padding: 3,
        position: 'relative',
        borderRadius: theme.customization.borderRadius,
      })}
    >
      <Stack spacing={3} justifyContent="space-between" height="100%">
        <TableContainer sx={{ height: '100%' }}>
          <MdScrollbar width={6} height="calc(100% - 60px)" top={60}>
            <Table stickyHeader aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '50px' }} />
                  <TableCell>{t('preset.name')}</TableCell>
                  <TableCell>{t('preset.description')}</TableCell>
                  <TableCell>{t('preset.id')}</TableCell>
                  <TableCell sx={{ minWidth: '170px' }}>{t('preset.visibility')}</TableCell>
                  <TableCell sx={{ width: '50px' }} />
                  <TableCell sx={{ width: '50px' }} />
                  <TableCell sx={{ width: '50px' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {presets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((preset) => (
                  <PresetRow key={preset.name} preset={preset} />
                ))}
              </TableBody>
            </Table>
          </MdScrollbar>
        </TableContainer>
        <TablePagination
          sx={{ overflow: 'hidden' }}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component={MdBox}
          count={presets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
      <LoadingBackdrop loading={loading} />
    </Paper>
  );
}
