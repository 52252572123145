import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';

import {
  CreateOnePromptPresetMutation,
  Maybe,
  PromptPreset,
  usePromptPresetsByUserUidQuery,
  useUpdateOnePromptPresetMutation,
} from '@app/graphql/generated';
import { useCreateOnePromptPresetMutation } from '@app/graphql/hooks/prompsAndPresets';
import { useCurrentUser } from '@app/hooks/useCurrentUser';

import { promptToCreateMapper, promptToFormPromptMapper } from '../mappers';
import { FormPreset, FormPrompt, PromptResponse } from '../types';

import { SavePresetDialog } from './SavePresetDialog';
import { SharePresetDialog } from './SharePresetDialog';

const filter = createFilterOptions<PromptPreset>();

type Props = {
  setValues: (
    values: React.SetStateAction<FormPrompt>,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<FormPrompt>> | Promise<void>;
  setVariables: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  prompt: FormPrompt;
  newPromptTextRef: React.MutableRefObject<PromptResponse>;
  isPromptTouched: boolean;
  handleIsPromptTouched: React.Dispatch<React.SetStateAction<boolean>>;
};

export function PresetPanel(props: Props) {
  const { setValues, prompt, newPromptTextRef, setVariables, isPromptTouched, handleIsPromptTouched } = props;
  const { t } = useTranslation('prompt');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const currentUser = useCurrentUser();
  const [presets, setPresets] = React.useState<Array<PromptPreset>>([]);
  const [newPresetId, setNewPresetId] = React.useState<string>('');
  const [currentPreset, setCurrentPreset] = React.useState<PromptPreset | null>(null);

  const [savePromptPreset, { loading }] = useCreateOnePromptPresetMutation({
    onCompleted: () => enqueueSnackbar(t('playground.saveDialog.success'), { variant: 'success' }),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  });

  usePromptPresetsByUserUidQuery({
    variables: { uid: user?.sub as string },
    onCompleted: ({ promptPresets }) => setPresets(promptPresets as Array<PromptPreset>),
  });

  const [editPresetMutation] = useUpdateOnePromptPresetMutation({
    onCompleted: () => enqueueSnackbar('Updated', { variant: 'success' }),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  });

  const handleFilter = React.useCallback((options: Array<PromptPreset>, params: any) => filter(options, params), []);

  const handleCurrentPresetChange = React.useCallback(
    (_: React.SyntheticEvent, value: PromptPreset | null) => {
      setCurrentPreset(value);

      if (value) {
        setValues(promptToFormPromptMapper(value.prompt));
        setVariables(value.prompt?.variables || {});
        newPromptTextRef.current = { text: value.prompt.prompt, isPreset: true };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValues],
  );

  React.useEffect(() => {
    if (newPresetId !== '') {
      const newPreset = presets.find((item) => item.id === newPresetId);

      if (newPreset) {
        setCurrentPreset(newPreset);

        setValues(promptToFormPromptMapper(newPreset.prompt));
        newPromptTextRef.current = { text: newPreset.prompt.prompt, isPreset: true };

        setNewPresetId('');
      }
    }
  }, [newPresetId, newPromptTextRef, presets, setValues]);

  const handlePresetSave = async (preset: FormPreset): Promise<Maybe<CreateOnePromptPresetMutation>> => {
    const { data } = await savePromptPreset({
      variables: {
        data: {
          name: preset.name,
          description: preset.description,
          prompt: {
            create: {
              ...promptToCreateMapper(prompt),
              project: {
                connect: {
                  id: currentUser?.project[0].id,
                },
              },
            },
          },
        },
      },
    });

    if (data?.createOnePromptPreset) {
      setNewPresetId(data?.createOnePromptPreset.id);
    }

    return data;
  };

  const handlePresetUpdate = async (name: string, description: string): Promise<boolean> => {
    if (currentPreset) {
      const { data } = await editPresetMutation({
        variables: {
          id: currentPreset.id,
          data: {
            name: { set: name },
            description: { set: description },
            updatedAt: { set: Date.now() },
            prompt: {
              update: {
                updatedAt: { set: Date.now() },
                prompt: { set: isPromptTouched ? prompt.prompt.slice(0, -1) : prompt.prompt },
                bestOf: { set: prompt.bestOf },
                frequencyPenalty: { set: prompt.frequencyPenalty },
                presencePenalty: { set: prompt.presencePenalty },
                maxTokens: { set: prompt.maxTokens },
                temperature: { set: prompt.temperature },
                topP: { set: prompt.topP },
                stop: { set: prompt.stop.join(',') },
                variables: prompt.variables,
              },
            },
          },
        },
      });

      if (data?.updateOnePromptPreset) {
        setNewPresetId(data?.updateOnePromptPreset.id);
      }
    }
    handleIsPromptTouched(false);

    return true;
  };

  return (
    <Stack direction="row" spacing={3} mb={2} p={1} alignItems="center" justifyContent="flex-end">
      <Autocomplete
        fullWidth
        id="presets"
        options={presets}
        value={currentPreset}
        onChange={handleCurrentPresetChange}
        filterOptions={handleFilter}
        getOptionLabel={(value) => value.name}
        renderInput={(params) => <TextField {...params} size="small" label={t('playground.presets')} />}
      />
      <SavePresetDialog
        presets={presets}
        handlePresetSave={handlePresetSave}
        loading={loading}
        currentPreset={currentPreset}
        handlePresetUpdate={handlePresetUpdate}
      />
      <SharePresetDialog
        currentPreset={currentPreset}
        handlePresetSave={handlePresetSave}
        loading={loading}
        presets={presets}
      />
    </Stack>
  );
}
