import { isEmpty } from 'lodash';

import { UserRole } from '../graphql/generated';

export function hasRole(allowedRoles: Set<UserRole> | undefined, roles: Array<string>) {
  if (allowedRoles && !isEmpty(allowedRoles)) {
    for (const role of roles) {
      if (allowedRoles.has(role as UserRole)) {
        return true;
      }
    }
  } else {
    return true;
  }

  return false;
}
