import 'reflect-metadata';

import React from 'react';
import { createRoot } from 'react-dom/client';
import FontFaceObserver from 'fontfaceobserver';

import { App } from './App';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const pulseObserver = new FontFaceObserver('Pulse', { weight: 400 });
const promptObserver = new FontFaceObserver('Prompt', { weight: 500 });

// When Open Sans is loaded, add a font-family using Open Sans to the body
Promise.allSettled([pulseObserver.load(), promptObserver.load()])
  .then((r) => {
    document.body.classList.add('fontLoaded');

    return r;
  })
  .catch(() => console.error('Font is not available'));

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(<App />);
