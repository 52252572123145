import { StorageEnum } from './storage.enum';
import { NODE_VARS } from './variables';

const CERTIFICATE_DEV_SERIAL = '00';

export const auth = {
  secret: process.env.NODE_ENV === 'test' ? 'secret for tests' : NODE_VARS.AUTH_SECRET,
  serial: {
    enabled: false,
  },
  session: {
    enabled: false,
  },
  jwt: {
    enabled: true,
    storage: StorageEnum.localStorage,
    tokenExpiresIn: '1m',
    refreshTokenExpiresIn: '7d',
  },
  password: {
    requireEmailConfirmation: true,
    sendPasswordChangesEmail: true,
    minLength: 8,
    enabled: true,
  },
  certificate: {
    devSerial: CERTIFICATE_DEV_SERIAL,
    enabled: false,
  },
  social: {
    facebook: {
      enabled: false,
      clientID: NODE_VARS.FACEBOOK_CLIENT_ID,
      clientSecret: NODE_VARS.FACEBOOK_CLIENT_SECRET,
      callbackURL: '/auth/facebook/callback',
      scope: ['email'],
      profileFields: ['id', 'emails', 'displayName'],
    },
    github: {
      enabled: false,
      clientID: NODE_VARS.GITHUB_CLIENT_ID,
      clientSecret: NODE_VARS.GITHUB_CLIENT_SECRET,
      callbackURL: '/auth/github/callback',
      scope: ['user:email'],
    },
    linkedin: {
      enabled: false,
      clientID: NODE_VARS.LINKEDIN_CLIENT_ID,
      clientSecret: NODE_VARS.LINKEDIN_CLIENT_SECRET,
      callbackURL: '/auth/linkedin/callback',
      scope: ['r_emailaddress', 'r_basicprofile'],
    },
    google: {
      enabled: false,
      clientID: NODE_VARS.GOOGLE_CLIENT_ID,
      clientSecret: NODE_VARS.GOOGLE_CLIENT_SECRET,
      callbackURL: '/auth/google/callback',
      scope: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
    },
  },
};
