import React, { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

import { ViewState } from '../../../common';
import { NavLink } from '../../material/NavLink';
import { MdBox } from '../../override-components/MdBox';
import { mergeSx } from '../../utils';
import { Breadcrumbs } from '../Breadcrumbs';

import { navbar, navbarMobileMenu, navbarRow } from './styles';

type NavItemRight = {
  key: string;
  to: string;
  icon: string;
  tooltipTKey: string;
  NavComponent: ElementType<any>;
};

const LogoutLink = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<{ to: string }>>(
  ({ to, children, ...other }, ref) => {
    const { logout } = useAuth0();

    return (
      <NavLink
        {...other}
        ref={ref}
        to={to}
        onClick={(e: { preventDefault: () => void }) => {
          e.preventDefault();
          logout({ returnTo: window.location.origin });
        }}
      >
        {children}
      </NavLink>
    );
  },
);

type Props = {
  navItemsRight: Array<NavItemRight>;
  miniSidenav: ViewState['miniSidenav'];
  transparentNavbar: ViewState['transparentNavbar'];
  fixedNavbar: ViewState['fixedNavbar'];
  setMiniSidenav: (newState: boolean) => void;
};

export function Navbar(props: Props) {
  const { navItemsRight, miniSidenav, transparentNavbar, fixedNavbar, setMiniSidenav } = props;

  const { t } = useTranslation();
  const [navbarType, setNavbarType] = React.useState<'sticky' | 'static' | undefined>();
  const { pathname } = useLocation();

  const route = pathname.split('/').slice(1);

  React.useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }
  }, [fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(!miniSidenav);

  return (
    <AppBar position={navbarType} color="inherit" sx={(theme) => navbar(theme, { transparentNavbar })}>
      <Toolbar
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <MdBox
          sx={mergeSx(navbarRow, {
            flexDirection: 'column',
            alignItems: 'flex-start',
          })}
        >
          <MdBox color="inherit" pl="5px">
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} />
          </MdBox>
          <MdBox color="inherit" sx={navbarRow}>
            <Tooltip title={t('Toggle menu')}>
              <IconButton size="small" color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                <Icon className="text-white">{miniSidenav ? 'menu' : 'menu_open'}</Icon>
              </IconButton>
            </Tooltip>
          </MdBox>
        </MdBox>
        <MdBox sx={navbarRow}>
          {navItemsRight.map(({ key, to, icon, tooltipTKey, NavComponent }) => (
            <Tooltip key={key} title={t(tooltipTKey)}>
              <IconButton key={key} to={to} size="small" color="inherit" sx={navbarMobileMenu} component={NavComponent}>
                <Icon className="text-white">{icon}</Icon>
              </IconButton>
            </Tooltip>
          ))}
          <Tooltip key="logout" title={t('layout:navLink.logout')}>
            <IconButton key="logout" to="#" size="small" color="inherit" sx={navbarMobileMenu} component={LogoutLink}>
              <Icon className="text-white">logout</Icon>
            </IconButton>
          </Tooltip>
        </MdBox>
      </Toolbar>
    </AppBar>
  );
}
