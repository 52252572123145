import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { app } from '@mdca/config';

import { Layout } from '../../look';
import { PresetsView } from '../components/PresetsView';

export default function Presets() {
  const { t } = useTranslation('prompt');

  return (
    <Layout>
      <Helmet
        title={`${app.name} - ${t('presets.title')}`}
        meta={[{ name: 'description', content: `${app.name} - ${t('presets.meta')}` }]}
      />
      <PresetsView />
    </Layout>
  );
}
