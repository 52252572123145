import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { PaletteMode, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Customization } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import MuiThemeProvider from '@mui/styles/ThemeProvider';
import useTheme from '@mui/styles/useTheme';

import { getBoxShadows, getThemeOptions, getTypography, palette } from './custom-theme';

import './fonts/font.scss';

type Action = { type: 'changeTheme'; payload: PaletteMode };
type Reducer = (prevState: Customization, action: Action) => Customization;
type Props = { defaultTheme: PaletteMode; children: React.ReactChild };

const ThemeDispatchContext = React.createContext<React.Dispatch<Action> | null>(null);

export function ThemeProvider(props: Props): JSX.Element {
  const { defaultTheme, children } = props;

  const [customization, dispatch] = React.useReducer<Reducer, Customization>(
    (state: Customization, action: Action): Customization => {
      if (action.type === 'changeTheme') {
        return { ...state, mode: action.payload };
      }

      return state;
    },
    { mode: defaultTheme, borderRadius: '14px' },
    (state) => state,
  );

  const memoizedTheme = React.useMemo(() => {
    const themeConfig = {
      colors: palette,
      getBoxShadows,
      getTypography,
      customization,
    };

    const theme = createTheme(getThemeOptions(themeConfig));
    return responsiveFontSizes(theme);
  }, [customization]);

  return (
    <MuiThemeProvider theme={memoizedTheme}>
      <EmotionThemeProvider theme={memoizedTheme}>
        <ThemeDispatchContext.Provider value={dispatch}>
          <CssBaseline />
          {children}
        </ThemeDispatchContext.Provider>
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
}

export const useChangeTheme = (): (() => void) => {
  const dispatch = React.useContext(ThemeDispatchContext);
  const theme = useTheme<Theme>();

  return React.useCallback(() => {
    if (dispatch) {
      dispatch({ type: 'changeTheme', payload: theme.palette.mode === 'light' ? 'dark' : 'light' });
    }
  }, [dispatch, theme.palette.mode]);
};
