import React, { memo } from 'react';

import { Circle } from './Circle';
import { CircleWrapper } from './CircleWrapper';
import { Wrapper } from './Wrapper';

type Props = {
  bg?: string;
  size?: number;
  fixed?: boolean;
  absolute?: boolean;
};

const blue = '#2D94F3';
// const orange = '#FF9900';

export const Spinner = memo<Props>((props) => {
  const { fixed = false, absolute = false, size = 40, bg, ...rest } = props;
  const theme = { bg: blue };

  const height = !fixed && !absolute ? size * 2 : undefined;

  return (
    <Wrapper fixed={fixed} absolute={absolute} height={height} {...rest}>
      <CircleWrapper width={size} height={size}>
        <Circle bg={theme.bg} rotate={0} animationDelay={0} propsBg={bg} />
        <Circle bg={theme.bg} rotate={30} animationDelay={0.1} propsBg={bg} />
        <Circle bg={theme.bg} rotate={60} animationDelay={0.2} propsBg={bg} />
        <Circle bg={theme.bg} rotate={90} animationDelay={0.3} propsBg={bg} />
        <Circle bg={theme.bg} rotate={120} animationDelay={0.4} propsBg={bg} />
        <Circle bg={theme.bg} rotate={150} animationDelay={0.5} propsBg={bg} />
        <Circle bg={theme.bg} rotate={180} animationDelay={0.6} propsBg={bg} />
        <Circle bg={theme.bg} rotate={210} animationDelay={0.7} propsBg={bg} />
        <Circle bg={theme.bg} rotate={240} animationDelay={0.8} propsBg={bg} />
        <Circle bg={theme.bg} rotate={270} animationDelay={0.9} propsBg={bg} />
        <Circle bg={theme.bg} rotate={300} animationDelay={1} propsBg={bg} />
        <Circle bg={theme.bg} rotate={330} animationDelay={1.1} propsBg={bg} />
      </CircleWrapper>
    </Wrapper>
  );
});
