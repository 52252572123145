import React from 'react';
import BatchPredictionSharpIcon from '@mui/icons-material/BatchPredictionSharp';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import IconAccount from '@mui/icons-material/PersonRounded';

// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import { UserRole } from './graphql/generated';
import { hasRole } from './helpers/hasRole';
import { AppRouteTypeEnum } from './enums';
import { AppRoute } from './types';

export const routeSettings: Record<string, AppRoute> = {
  prompt: {
    index: 1,
    type: AppRouteTypeEnum.NAVIGATION,
    key: 'prompt',
    nameTKey: 'prompt:navLink',
    iconElement: <BatchPredictionSharpIcon />,
    path: '/prompt',
    allowedRoles: new Set([UserRole.User]),
    handleHasRole: hasRole,
  },
  presets: {
    index: 2,
    type: AppRouteTypeEnum.NAVIGATION,
    key: 'presets',
    nameTKey: 'prompt:presets.title',
    iconElement: <ListAltIcon />,
    path: '/presets',
    allowedRoles: new Set([UserRole.User]),
    handleHasRole: hasRole,
  },
  history: {
    index: 2,
    type: AppRouteTypeEnum.NAVIGATION,
    key: 'history',
    nameTKey: 'prompt:history.navLink',
    iconElement: <HistoryIcon />,
    path: '/history',
    allowedRoles: new Set([UserRole.Admin, UserRole.SuperAdmin]),
    handleHasRole: hasRole,
  },
  users: {
    index: 3,
    type: AppRouteTypeEnum.NAVIGATION,
    key: 'users',
    nameTKey: 'user:navLink.users',
    iconElement: <PeopleAltRoundedIcon />,
    path: '/users',
    allowedRoles: new Set([UserRole.Admin, UserRole.SuperAdmin]),
    handleHasRole: hasRole,
  },
  profile: {
    index: 4,
    type: AppRouteTypeEnum.NAVIGATION,
    key: 'profile',
    nameTKey: 'user:navLink.profile',
    iconElement: <IconAccount />,
    path: '/profile',
    handleHasRole: hasRole,
  },
  // security: {
  //   index: 5,
  //   type: AppRouteTypeEnum.NAVIGATION,
  //   key: 'security',
  //   nameTKey: 'user:navLink.security',
  //   iconElement: <SecurityRoundedIcon />,
  //   path: '/security',
  // },
  // {
  //   // index: 1,
  //   // type: AppRouteTypeEnum.NAVIGATION,
  //   // key: '/',
  //   // nameTKey: 'user:navLink.dashboard',
  //   // iconElement: <HomeRoundedIcon />,
  //   // role: [RoleEnum.ADMIN, RoleEnum.SUPER_ADMIN],
  //   path: '/',
  //   element: <Dashboard />,
  // },
};
