import React from 'react';
import { useCookies } from 'react-cookie';
import { useAuth0 } from '@auth0/auth0-react';

import { NODE_VARS } from '@mdca/config';

export function SseProvider({ children }: React.PropsWithChildren<any>) {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [, setCookie] = useCookies();

  React.useEffect(() => {
    let sse: EventSource;

    (async () => {
      const token = await getAccessTokenSilently();

      setCookie('token', `Bearer ${token}`, { path: '/' });
      const port = process.env.IS_DEV ? `:${NODE_VARS.SERVER_PORT}` : '';
      const sseGateway = `${window.location.protocol}//${window.location.hostname}${port}/api/user/events`;

      sse = new EventSource(sseGateway, { withCredentials: true });

      // sse.addEventListener('open', (event) => {
      //   console.log('sse open', event);
      // });
      //
      // sse.addEventListener('error', (event) => {
      //   console.log('sse error', event);
      //   sse.close();
      // });

      /*
       * The event "message" is a special case, as it
       * will capture events without an event field
       * as well as events that have the specific type
       * `event: message` It will not trigger on any
       * other event type.
       */
      sse.addEventListener('message', (event) => {
        let data;

        try {
          data = JSON.parse(event.data);
        } catch (error) {
          console.error(error);
        }

        if (data.type === 'LOGOUT') {
          logout({ returnTo: window.location.origin });
        }
      });
    })();

    return () => {
      if (sse && typeof sse.close === 'function') {
        sse.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}
